import { Avatar, Grid, ImageList, ImageListItem, Typography, useMediaQuery } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getElement } from "../../../API/API";
import { Header } from "../../../components/layouts/Header";
import Page from "../../../components/layouts/Page";
import Loading from "../../../components/layouts/Loading";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function GaleriaSingle() {
	const matches = useMediaQuery("(min-width:960px)");
	const [galeria, setGaleria] = useState("");
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElement("galeries", id);
			setGaleria(result);
			setLoading(false);
		};
		id && obtenir();
	}, [id]);

	return (
		<Page title={"Club Esquí Berguedà - " + galeria?.nom}>
			<Header title={galeria?.nom} loading={loading} img={process.env.REACT_APP_STORAGE + galeria?.imatges?.[0]?.foto} />

			<Box style={{ backgroundColor: "white" }} py={10}>
				<Loading loading={loading} height="50vh">
					<Container maxWidth={"lg"}>
						{galeria?.descripcio && (
							<Box style={{ backgroundColor: "white" }} textAlign={"center"} py={8}>
								<Typography
									variant="body1"
									mb={8}
									dangerouslySetInnerHTML={{
										__html: galeria?.descripcio,
									}}
								/>
							</Box>
						)}
						<ImageList variant="masonry" sx={{ width: "100%" }} cols={4} gap={8}>
							{galeria?.imatges?.map((item) => (
								<ImageListItem key={item}>
									<Zoom>
										<Avatar
											variant="rounded"
											src={process.env.REACT_APP_STORAGE + item.foto}
											alt={item.title}
											style={{ width: "100%", height: "100%" }}
											loading="lazy"
										/>
									</Zoom>
								</ImageListItem>
							))}
						</ImageList>
					</Container>
				</Loading>
			</Box>
		</Page>
	);
}
