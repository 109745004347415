import React, { useEffect, useState } from "react";
import PageAdmin from "../../../components/layouts/PageAdmin";
import { Add, CalendarMonth, Circle, Download } from "@mui/icons-material";
import { Alert, Chip, Grid, IconButton, Switch, ThemeProvider, Tooltip, Typography } from "@mui/material";
import Loading from "../../../components/layouts/Loading";
import { deleteElement, download, getElement, getElements, publishActivitat } from "../../../API/API";
import { useNavigate, useParams } from "react-router";
import CustomCard from "../../../components/layouts/CustomCard";
import Thumb from "../../../components/elements/Thumb";
import { useSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../../components/elements/CustomButton";
import getMuiTheme from "../../../theme/TableStyle";
import TableOptions from "../../../components/columns/TableOptions";
import ActivitatDades from "./components/ActivitatDades";
import InscritsMinColumns from "../../../components/columns/InscritsMinColumns";
import Dada from "../../../components/elements/Dada";
import { DialogEliminar } from "../../../components/layouts/DialogEliminar";
import { Stack } from "@mui/system";
import TableOptionsMin from "../../../components/columns/TableOptionsMin";
import { GaleriaItem } from "../../public/galeries/GaleriaItem";
import { GaleriaItemAdmin } from "./components/GaleriaItemAdmin";
import { DialogAddInscrit } from "./components/DialogAddInscrit";
import { DialogConfirmar } from "../../../components/layouts/DialogConfirmar";

export default function ActivitatView() {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { slug } = useParams();
	const [loading, setLoading] = useState(false);
	const [activitat, setActivitat] = useState([]);
	const [inscrits, setInscrits] = useState([]);
	const [oberta, setOberta] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [update, setUpdate] = useState(0);
	const [openAdd, setOpenAdd] = useState(false);
	const columns = InscritsMinColumns(inscrits);
	const [openCorreus, setOpenCorreus] = useState(false);
	const [cols, setCols] = useState(columns);
	const [csv, setCsv] = useState();

	const downloadInscrits = async () => {
		const { result, message } = await download("downloadInscrits", activitat.id);
		const link = document.createElement("a");
		link.href = `data:text/csv;charset=utf-8,${escape(result)}`;
		link.setAttribute("download", "inscrits.csv");
		document.body.appendChild(link);
		link.click();
		link.remove();
		console.log(result);
		setCsv(result);
	};

	const CustomToolbar = () => {
		return (
			<Tooltip title="Descarregar CSV inscrits">
				<IconButton onClick={downloadInscrits} dark small>
					<Download size={15} />
				</IconButton>
			</Tooltip>
		);
	};
	const options = TableOptionsMin(CustomToolbar, "inscrits");

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElement("activitatsAdmin", slug);
			setActivitat(result.activitat);
			setOberta(result.inscripcio_oberta);
			setInscrits(result.activitat.inscrits);
			result.activitat?.camps.forEach((camp, index) => {
				columns.push({
					name: "camps",
					label: camp.nom,
					options: {
						filter: false,
						sort: true,
						display: false,
						customBodyRender: (value) => value[index]?.pivot.valor,
					},
				});
			});
			setCols(columns);
			setLoading(false);
		};
		obtenir();
	}, [slug, update]);

	const duplicar = async () => {
		const { result, message } = await getElement("activitats/duplicate", slug);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate("/admin/activitats/" + result?.slug);
	};

	const eliminar = async () => {
		const { message, type } = await deleteElement("activitats", slug);
		enqueueSnackbar(message, {
			variant: type,
		});
		setOpenDelete(false);
		type === "success" && navigate("/admin/activitats");
	};

	const publicar = async () => {
		const { message, type } = await publishActivitat(slug);
		enqueueSnackbar(message, {
			variant: type,
		});
		setUpdate((prev) => prev + 1);
	};

	const reenviarCorreus = async () => {
		const { message, type } = await getElement("reenviarCorreus", activitat?.id);
		enqueueSnackbar(message, {
			variant: type,
		});
		setOpenCorreus(false);
	};

	return (
		<PageAdmin
			title={activitat?.nom}
			Icon={CalendarMonth}
			button={
				<Stack direction={"row"} spacing={2}>
					<CustomButton
						dark
						onClick={() => {
							const newWindow = window.open("/activitats/" + activitat.slug, "_blank", "noopener,noreferrer");
							if (newWindow) newWindow.opener = null;
						}}
						title={"Veure pàgina pública"}
					/>
					<CustomButton success onClick={() => navigate("/admin/activitats/" + activitat.slug)} title={"Editar"} />
				</Stack>
			}
		>
			<Loading loading={loading}>
				<Grid spacing={3} container>
					<Grid item md={7} xl={8}>
						<CustomCard
							title={"Inscrits"}
							addOn={
								<IconButton onClick={() => setOpenAdd(true)}>
									<Add />
								</IconButton>
							}
						>
							{Number(activitat?.intern) === 1 ? (
								oberta ? (
									<Alert severity="success">Inscripcions obertes</Alert>
								) : (
									<Alert severity="warning">Inscripcions tancades</Alert>
								)
							) : (
								<Alert severity="warning">Les inscripcions no es gestionen des del web de CEB</Alert>
							)}
							{Number(activitat?.intern) === 1 && (
								<ThemeProvider theme={getMuiTheme()}>
									<MUIDataTable data={activitat?.inscrits} columns={columns} options={options} />
								</ThemeProvider>
							)}
						</CustomCard>
					</Grid>
					<Grid item md={5} xl={4}>
						<CustomCard title={"Informació"}>
							<Dada nom="Publicada" valor={<Switch checked={Number(activitat?.publicada)} onChange={publicar} />} />
							<ActivitatDades activitat={activitat} />
							{activitat?.camps?.length > 0 && (
								<Dada
									nom="Camps"
									valor={activitat?.camps?.map((c) => (
										<Chip label={c?.nom} />
									))}
								/>
							)}
							<Dada
								nom="Enviar correus a tots els inscrits"
								valor={<CustomButton dark title={"Reenviar correus"} onClick={() => setOpenCorreus(true)} />}
							/>
						</CustomCard>
						{activitat?.galeries?.length > 0 && (
							<CustomCard title={"Galeries"}>
								{activitat?.galeries?.map((galeria) => (
									<GaleriaItemAdmin galeria={galeria} />
								))}
							</CustomCard>
						)}

						<CustomCard title={"Imatge"}>
							<Thumb file={activitat.imatge} />
						</CustomCard>

						<CustomCard title="Accions">
							<CustomButton title={"Crear nova activitat duplicant dades"} fullWidth dark onClick={duplicar} />
							<CustomButton title={"Eliminar activitat"} fullWidth danger onClick={() => setOpenDelete(true)} />
						</CustomCard>
					</Grid>
				</Grid>
			</Loading>
			<DialogConfirmar
				element={"reenviar correus"}
				onClick={reenviarCorreus}
				open={openCorreus}
				setOpen={setOpenCorreus}
				text={"Això enviarà un correu electrònic a tots els inscrits d'aquesta activitat"}
			/>
			<DialogEliminar element={"activitat"} onClick={eliminar} open={openDelete} setOpen={setOpenDelete} />
			<DialogAddInscrit open={openAdd} setOpen={setOpenAdd} activitat={activitat} setUpdate={setUpdate} />
		</PageAdmin>
	);
}

const useStyles = makeStyles((theme) => ({
	calendari: {
		"& .rmdp-wrapper": {
			width: "100% !important",
		},
		"& span": {
			fontFamily: "Rubik",
		},
		"& div": {
			fontFamily: "Rubik",
		},
		"& .rmdp-panel": {
			width: "100% !important",
		},
	},
}));
