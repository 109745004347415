import { Cancel } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const Error = () => {
	const classes = Styles();
	return (
		<Box className={classes.root}>
			<Container maxWidth="lg" className={classes.main}>
				<Box style={{ color: "red" }} className={classes.titol}>
					<Box className={classes.titol}>
						<Cancel style={{ width: 50, height: 50 }} />
						<Typography
							variant="h2"
							style={{ color: "red" }}
							mt={2}
							mb={5}
						>
							Pagament fallat
						</Typography>
					</Box>
				</Box>
				<Typography className={classes.titol}>
					Alguna cosa no ha anat bé. Torna-ho a intentar més tard.
					Disculpa les molèsties.
				</Typography>
			</Container>
		</Box>
	);
};

const Styles = makeStyles((theme) => ({
	main: {
		paddingBottom: 50,
	},
	root: {
		paddingTop: 250,
		paddingBottom: 300,
		overflow: "auto",
		justifyContent: "center",
		backgroundColor: "white",
	},

	titol: {
		marginTop: 20,
		color: "red",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
}));

export default Error;
