import { Box, Typography } from "@mui/material";
import { useEditor, EditorContent, ReactNodeViewRenderer } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Toolbar } from "./Tiptap/Toolbar";
import Link from "@tiptap/extension-link";
import { useEffect } from "react";

const CustomRichTextTiny = ({ min, setValue, name, label, watch, errors }) => {
	const handleChange = (newContent) => {
		setValue(name, newContent);
	};

	const editor = useEditor({
		extensions: [
			StarterKit,
			Link.configure({
				openOnClick: false,
				autolink: true,
				defaultProtocol: "https",
			}),
		],
		content: watch(name),
		editorProps: {
			attributes: {
				className: "editor",
			},
		},
		onUpdate: ({ editor }) => {
			handleChange(editor.getHTML());
		},
	});

	useEffect(() => {
		if (!editor) {
			return undefined;
		}

		editor.commands.setContent(watch(name));
	}, [editor, watch(name)]);

	return (
		<Box mt={2}>
			{label && (
				<Typography fontSize={15} mb={1}>
					{label}
				</Typography>
			)}
			<Toolbar editor={editor} min={min} />
			<EditorContent className="editor" editor={editor} />
			<Typography variant="error">{errors?.[name]?.message}</Typography>
		</Box>
	);
};

export default CustomRichTextTiny;

// import * as React from "react";
// import { Box, Typography } from "@mui/material";
// import { Editor } from "@tinymce/tinymce-react";

// export default function CustomRichTextTiny({ register, name, setValue, label, getValues, height, errors }) {
// 	const editorRef = React.useRef(null);

// 	return (
// 		<Box mt={3}>
// 			<Typography mb={1}>{label}</Typography>
// 			<Editor
// 				onInit={(evt, editor) => (editorRef.current = editor)}
// 				initialValue={getValues(name)}
// 				onEditorChange={(value) => setValue(name, value)}
// 				apiKey="ske3174dk22ptduvdj84shldwvzu6i8a3fbdyj2zk36fn3x1"
// 				init={{
// 					height: height,
// 					menubar: false,
// 					plugins: [
// 						"advlist autolink lists link image charmap print preview anchor",
// 						"searchreplace visualblocks code fullscreen",
// 						"insertdatetime media table paste code help wordcount",
// 						"image",
// 						"link",
// 						"table",
// 						"lists",
// 						"code",
// 					],
// 					toolbar:
// 						"undo redo | formatselect | " +
// 						"bold italic backcolor | alignleft aligncenter " +
// 						"alignright alignjustify | bullist numlist outdent indent | " +
// 						"image link table code",
// 					content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
// 					extended_valid_elements: "script[src|async|defer|type|charset]",
// 				}}
// 			/>
// 			<input {...register(name)} hidden />
// 			{errors && <Typography variant="error">{errors.message}</Typography>}
// 		</Box>
// 	);
// }
