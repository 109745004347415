import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const Title = ({ children, button, Icon }) => {
	const classes = useStyles();

	return (
		<Box className={classes.main}>
			<Box display="flex" alignItems={"center"} mt={1}>
				{Icon && (
					<Box mr={2}>
						<Icon fontSize={"large"} />
					</Box>
				)}
				<Typography variant="h3">{children}</Typography>
			</Box>
			<Box>{button}</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	main: {
		paddingTop: 20,
		paddingBottom: 15,
		paddingLeft: 25,
		paddingRight: 25,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		background: theme.palette.secondary.main,
		position: "fixed",
		width: "calc(100% - 250px)",
		zIndex: 1000,
		boxShadow: "#00000020 1px 3px 20px 1px ",
	},
}));

export default Title;
