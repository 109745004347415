import {
	Apps,
	Cancel,
	Check,
	CheckCircle,
	Circle,
	Close,
	Edit,
	RemoveRedEye,
} from "@mui/icons-material";
import { Avatar, Chip, IconButton, Stack, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useNavigate } from "react-router";

const ActivitatsColumns = (activitats) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "slug",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
				sortOrder: "desc",
			},
		},
		{
			name: "publicada",
			label: "Publicada",
			options: {
				filter: false,
				sort: true,
				width: 100,
				sortOrder: "desc",
				customBodyRender: (value) =>
					Number(value) === 1 ? (
						<CheckCircle style={{ fill: "green" }} />
					) : (
						<Cancel style={{ fill: "#d32f2f" }} />
					),
			},
		},
		{
			name: "imatge",
			label: "Imatge",
			options: {
				width: 50,
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => (
					<Avatar
						src={process.env.REACT_APP_STORAGE + value}
						variant="rounded"
						children={<Apps />}
					/>
				),
			},
		},

		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},

		{
			name: "seccio",
			label: "Secció",
			options: {
				filter: false,
				sort: false,
				sortOrder: "desc",
				customBodyRender: (value) => (
					<Chip
						icon={<Circle style={{ fill: value.color }} />}
						label={value.nom}
						variant="outlined"
					/>
				),
			},
		},
		{
			name: "intern",
			label: "Inscripció CEB",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) =>
					Number(value) === 1 ? (
						<CheckCircle style={{ fill: "green" }} />
					) : (
						<Cancel style={{ fill: "#d32f2f" }} />
					),
			},
		},

		{
			name: "inscrits_count",
			label: "Inscrits",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "dates",
			label: "Data inici",
			options: {
				filter: false,
				sort: true,
				width: 100,
				sortOrder: "desc",
				customBodyRender: (value) =>
					moment(JSON.parse(value)[0], "DD/MM/YYYY").format(
						"DD/MM/YYYY"
					),
			},
		},

		{
			name: "created_at",
			label: "Data creació",
			options: {
				filter: false,
				sort: true,
				width: 100,
				sortOrder: "desc",
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY"),
			},
		},
		// {
		// 	name: "id",
		// 	label: "Accions",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		empty: true,
		// 		customBodyRenderLite: (dataIndex) => {
		// 			return (
		// 				<Stack direction={"row"}>
		// 					<Tooltip title="Veure informació">
		// 						<IconButton
		// 							onClick={() =>
		// 								navigate(
		// 									`/admin/activitats/view/${activitats[dataIndex].slug}`
		// 								)
		// 							}
		// 						>
		// 							<RemoveRedEye />
		// 						</IconButton>
		// 					</Tooltip>
		// 					{/* <Tooltip title="Editar">
		// 						<IconButton
		// 							onClick={() =>
		// 								navigate(
		// 									`/admin/activitats/${activitats[dataIndex].slug}`
		// 								)
		// 							}
		// 						>
		// 							<Edit />
		// 						</IconButton>
		// 					</Tooltip> */}
		// 				</Stack>
		// 			);
		// 		},
		// 	},
		// },
	];
	return columns;
};

export default ActivitatsColumns;
