import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Page from "../../../components/layouts/Page";
import { makeStyles } from "@mui/styles";
import TitlePublic from "../../../components/elements/TitlePublic";

function AvisLegal() {
	const classes = useStyles();
	return (
		<Page title="Aviso Legal">
			<Box className={classes.portada}>
				<Container>
					<TitlePublic title="Aviso Legal" />
					<Box>
						<Typography color="secondary">
							Los datos identificativos y de contacto del titular y responsable de la Web son los que figuran a continuación:
						</Typography>
						<Typography color="secondary">Club Esquí Berguedà</Typography>
						<Typography color="secondary">NIF: -</Typography>
						<Typography color="secondary">Pio Baroja, 6 Baixos - 08600 Berga</Typography>
						<Typography color="secondary">E-mail: ceb.berga@gmail.com</Typography>
						<Typography color="secondary">Telèfon: +34 938 211 674</Typography>

						{/* --------- */}
						<Typography mt={3} color="secondary" variant="h2">
							Propiedad Intelectual
						</Typography>
						<Typography color="secondary">El propietario de esta web y su contenido es Club Esquí Berguedà con NIF: -.</Typography>
						<Typography color="secondary">
							Queda prohibida la transmisión, cesión, venta, alquiler y/o exposición pública de esta web sin la correspondiente autorización de su
							dueño. La información y contenidos presentados en esta web son de carácter orientativos quedando el propietario de la web exento de
							cualquier responsabilidad derivada de la falta de exactitud en dicha información y contenidos.
						</Typography>

						{/* --------- */}
						<Typography mt={3} color="secondary" variant="h2">
							CONDICIONES DE UTILIZACIÓN DE LA WEB
						</Typography>
						<Typography color="secondary">
							El propietario ofrece en su web información relacionada con el tipo de actividad que realiza, en los términos y condiciones
							siguientes.
						</Typography>
						<Typography color="secondary">
							El propietario se reserva el derecho a actualizar, modificar o eliminar la información contenida en clubesquibergueda.cat/ .
							Especialmente el propietario se reserva el derecho a limitar o impedir el acceso a su web cuando surjan dificultades técnicas por
							hechos o circunstancias ajenas, y que a su criterio, disminuyan o anulen los niveles de seguridad adoptados para el adecuado
							funcionamiento de la web.
						</Typography>
						<Typography color="secondary">
							El usuario se compromete a utilizar la Web de conformidad con la legislación vigente, con lo dispuesto en el Aviso Legal, así como
							con la moral, la buena fe y el orden público. El usuario se obliga a abstenerse de utilizar la Web con fines ilícitos, contrarios a
							lo establecido en el Aviso Legal, o de forma lesiva para los derechos e intereses del propietario de la Web o de terceros.
						</Typography>
						<Typography color="secondary">
							El propietario se reserva el derecho a suspender, interrumpir, denegar o retirar el acceso y/o utilización de su Web, en cualquier
							momento y sin necesidad de preaviso, a todo Usuario que incumpla con el presente Aviso Legal.
						</Typography>
						<Typography color="secondary">
							Todos los contenidos de la Web (incluyendo bases de datos, marcas, logotipos, nombres comerciales, imágenes, dibujos, gráficos,
							archivos de texto, audio, vídeo y software) son propiedad del Titular de la Web o ha sido debidamente autorizado para su uso, y
							están protegidos por los correspondientes derechos de propiedad intelectual.
						</Typography>

						{/* --------- */}
						<Typography mt={3} color="secondary" variant="h2">
							POLÍTICA DE PROTECCIÓN DE DATOS
						</Typography>
						<Typography color="secondary">
							Algunos servicios prestados en la web pueden contener condiciones particulares con previsiones específicas en materia de protección
							de Datos Personales.
						</Typography>
						<Typography color="secondary">
							Los datos personales recogidos serán objeto de tratamiento automatizado e incorporados a los correspondientes ficheros
							automatizados.
						</Typography>
						<Typography color="secondary">
							El usuario garantiza que los datos personales facilitados a clubesquibergueda.cat/ son veraces y se hace responsable de comunicar
							cualquier modificación.
						</Typography>
						<Typography color="secondary">
							La recogida y tratamiento automatizado de los datos personales tiene como finalidad la comunicación de clubesquibergueda.cat/ con
							sus clientes, acerca de novedades, ampliación y mejora de los servicios que presta.
						</Typography>
						<Typography color="secondary">
							El propietario de la web ha adoptado los niveles de seguridad de protección de los datos personales legalmente requeridos, y procura
							instalar aquellos otros medios y medidas técnicas adicionales a su alcance para evitar la pérdida, mal uso, alteración, acceso no
							autorizado y robo de los datos personales facilitados. No obstante, el usuario debe ser consciente de que las medidas de seguridad
							en Internet no son infalibles.
						</Typography>
						<Typography color="secondary">
							Los usuarios tienen derechos reconocidos y podrán ejercitar los derechos de acceso, cancelación, rectificación y oposición, así como
							a ser informados de las cesiones realizadas contactando con clubesquibergueda.cat.
						</Typography>
					</Box>
				</Container>
			</Box>
		</Page>
	);
}

export default AvisLegal;

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 200,
		height: "100%",
		backgroundAttachment: "fixed",
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
		paddingBottom: 200,
	},
	"@global": {
		html: {
			height: "100%",
		},
	},
}));
