import { CheckCircle } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { Header } from "../../../components/layouts/Header";
import Page from "../../../components/layouts/Page";

const Success = () => {
	const classes = Styles();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Page title="Club Esquí Berguedà - Inscripció" className={classes.main}>
			<Header title={"Inscripció"} />
			<Box className={classes.root}>
				<Container>
					<Box mt={4}>
						<Typography variant="h3" textAlign={"center"}>
							Moltes gràcies
						</Typography>
						<Box className={classes.titol}>
							<CheckCircle style={{ width: 50, height: 50 }} />
							<Typography variant="h2" style={{ color: "green" }} mt={2} textAlign="center">
								Pagament efectuat amb èxit
							</Typography>
						</Box>
						<Box textAlign="center" mt={2}>
							<Typography variant="h4">S'ha rebut l'inscripció correctament</Typography>
						</Box>
					</Box>
				</Container>
			</Box>
		</Page>
	);
};

const Styles = makeStyles((theme) => ({
	root: {
		paddingTop: 200,
		paddingBottom: 300,
		overflow: "auto",
		justifyContent: "center",
		backgroundColor: "white",
	},

	titol: {
		marginTop: 20,
		color: "green",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
}));

export default Success;
