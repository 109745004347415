import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AppBar, Box, Drawer, Hidden, IconButton, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router";
import NavBarItemMobile from "./NavBarItemMobile";
import { Close, Menu } from "@mui/icons-material";
import NavBarItem from "./NavBarItem";
// import { ReactComponent as Logo } from "../../assets/img/logo-min.svg";

const useStyles = makeStyles((theme) => ({
	main: {
		width: "100%",
		zIndex: 100,
		transition: "0.2s",
		position: "fixed",
	},
	nav: {
		transition: "0.2s",
		borderBottom: "1px solid white",
	},
	navBar: {
		backgroundColor: theme.palette.background.main,
		boxShadow: "none",
	},
	navBarDown: {
		backgroundSize: "contain",
		backgroundAttachment: "fixed",
		paddingTop: 0,
		boxShadow: "none",
		maskSize: "cover",
		maskRepeat: "no-repeat",
		maskPosition: "center",
	},
	logo: {
		fill: "white",
	},
	maskTop: {
		width: "100%",
	},
}));

const TopBarPublic = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const [scrollY, setScrollY] = useState(window.scrollY);
	const navigate = useNavigate();
	const [menu, setMenu] = useState(null);
	const location = useLocation();

	const openMenu = (event) => {
		setMenu(event.currentTarget);
	};

	const closeMenu = () => {
		setMenu(null);
	};

	const items = [
		{
			title: "Inici",
			to: "/",
		},
		{
			title: "Seccions",
			to: "seccions",
			dropdown: "true",
		},
		{
			title: "Activitats",
			to: "activitats",
		},
		{
			title: "Qui som",
			to: "qui-som",
		},
		{
			title: "Galeria",
			to: "galeries",
		},
		{
			title: "Contacte",
			to: "contacte",
		},
	];

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	return (
		<Box className={classes.main}>
			<AppBar elevation={0} className={classes.navBar}>
				<Toolbar className={clsx(classes.nav)}>
					<Hidden mdDown>
						<Box
							style={{
								flex: 1,
								display: "flex",
								justifyContent: "flex-start",
								cursor: "pointer",
							}}
							onClick={() => navigate("/")}
						>
							<Typography variant="h3" color="white">
								CEB
							</Typography>
						</Box>
					</Hidden>
					<Hidden mdDown>
						<Box
							style={{
								flex: 2,
								display: "flex",
								justifyContent: "center",
								height: 64,
							}}
						>
							{items?.map((item) => (
								<NavBarItem to={item.to} key={item.title} title={item.title} dropdown={item.dropdown} />
							))}
						</Box>
					</Hidden>

					<Hidden mdUp>
						<Box onClick={() => navigate("/")}>
							<Typography variant="h3" color="white">
								CEB
							</Typography>
						</Box>
						<IconButton
							style={{ zIndex: 10 }}
							color="primary"
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={openMenu}
							className={classes.buttonDrawer}
						>
							<Menu color="secondary" />
						</IconButton>
						<Drawer open={menu} onClose={closeMenu}>
							<IconButton onClick={closeMenu}>
								<Close />
							</IconButton>
							{items?.map((item) => (
								<NavBarItemMobile to={item.to} key={item.title} title={item.title} closeMenu={closeMenu} dropdown={item.dropdown} />
							))}
						</Drawer>
					</Hidden>
					<Box
						style={{
							flex: 1,
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					></Box>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

TopBarPublic.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBarPublic;
