import FullCalendar from "@fullcalendar/react";
import { Box, Grid, Hidden, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { getElements } from "../../../API/API";
import moment from "moment";
import { useNavigate } from "react-router";
import Loading from "../../../components/layouts/Loading";
import { Container } from "@mui/system";
import { Header } from "../../../components/layouts/Header";
import renderEvent from "./components/renderEvent";
import Page from "../../../components/layouts/Page";
import img from "../../../assets/img/portada-activitats.jpg";
import { isDark } from "../../../components/Utils";
import ActivitatItem from "../home/components/ActivitatItem";

export default function ActivitatsCalendari() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [activitats, setActivitats] = useState([]);
	const [act, setAct] = useState([]);
	const [seccions, setSeccions] = useState([]);
	const [filteredActivitats, setFilteredActivitats] = useState([]);
	const [update, setUpdate] = useState(0);
	const [filter, setFilter] = useState([]);
	const [isAwaiting, setIsAwaiting] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			let events = [];
			const { result } = await getElements("activitats");
			const seccions = await getElements("seccions");
			setSeccions(seccions.result);
			setFilter(seccions.result.map((i) => i.id));

			await result.map((activitat) =>
				JSON.parse(activitat?.dates).map((item) =>
					events.push({
						id: activitat.slug,
						title: activitat?.nom,
						start: moment(item, "DD/MM/YYYY").format("YYYY-MM-DD"),
						color: activitat.seccio.color,
						textColor: activitat.seccio.nom,
						idSeccio: activitat.seccio.id,
					})
				)
			);

			setAct(result);

			setActivitats(events);
			setFilteredActivitats(events);
			setLoading(false);
		};
		obtenir();
	}, [update]);

	useEffect(() => {
		if (filter.length > 0) {
			console.log("filter");
			setFilteredActivitats(
				activitats.filter((item) =>
					filter.some((i) => i === item.idSeccio)
				)
			);
		} else {
			console.log("TTO");
			setFilteredActivitats(activitats);
		}
	}, [activitats, filter]);

	function onClickEvent(e) {
		navigate("/activitats/" + e.event.id);
	}

	const addFilter = async (item) => {
		setIsAwaiting(true);
		if (filter?.some((i) => i === item)) {
			setFilter(filter?.filter((i) => i !== item));
		} else {
			setFilter([...filter, item]);
		}
		setTimeout(() => setIsAwaiting(false), 100);
	};

	useEffect(() => {
		console.log(filteredActivitats);
	}, [filteredActivitats]);

	return (
		<Page title="Calendari">
			<Header title={"Activitats"} img={img} overlay />
			<Box backgroundColor="white" py={15}>
				<Container maxWidth="lg">
					<Loading loading={loading} height="50vh">
						<Hidden smDown>
							<Grid container mb={10}>
								{seccions?.map((item) => (
									<SeccioFilter
										seccio={item}
										addFilter={addFilter}
										filter={filter}
									/>
								))}
							</Grid>
						</Hidden>
						<Hidden smUp>
							<Grid spacing={4} container mb={5}>
								{act?.map((activitat) => (
									<ActivitatItem
										activitat={activitat}
										key={activitat?.id}
									/>
								))}
							</Grid>
						</Hidden>

						<div className={classes.calendar}>
							<Loading loading={isAwaiting} height="50vh">
								<FullCalendar
									plugins={[dayGridPlugin]}
									headerToolbar={{
										left: "prev",
										right: "next",
										center: "title",
									}}
									initialView={"dayGridMonth"}
									events={filteredActivitats}
									locale={"ca"}
									firstDay={1}
									allDayText="Tot el dia"
									eventContent={renderEvent}
									slotMinTime={"14:00"}
									height={window.innerHeight - 200}
									eventTimeFormat={{
										hour: "numeric",
										minute: "2-digit",
										hour12: false,
									}}
									eventClick={onClickEvent}
									dayMaxEvents={5}
									themeSystem="bootstrap"
								/>
							</Loading>
						</div>
						<Hidden smDown>
							<Typography variant="h3" mt={4} mb={3}>
								Llistat d'activitats
							</Typography>
							<Grid spacing={4} container mt={2}>
								{act?.map((activitat) => (
									<ActivitatItem
										activitat={activitat}
										key={activitat?.id}
									/>
								))}
							</Grid>
						</Hidden>
					</Loading>
				</Container>
			</Box>
		</Page>
	);
}

const SeccioFilter = ({ seccio, addFilter, filter }) => {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles(seccio);
	const navigate = useNavigate();

	return (
		<Grid item md={2} xs={12}>
			<Box
				className={
					filter?.some((i) => i === seccio.id)
						? classes.seccio
						: classes.seccioSel
				}
				onClick={() => addFilter(seccio.id)}
			>
				<Typography
					fontSize={15}
					textTransform="uppercase"
					color={isDark(seccio.color) ? "white" : "black"}
					className={classes.text}
				>
					{seccio.nom}
				</Typography>
			</Box>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	calendar: {
		fontFamily: "Rubik",
		color: theme.palette.text.primary,
		"& .fc-toolbar-title": {
			fontSize: 40,
			marginBottom: 10,
		},

		"& .fc-button-primary": {
			backgroundColor: theme.palette.primary.main,
			borderColor: theme.palette.primary.main,
			"&:hover": {
				backgroundColor: theme.palette.primary.hover,
			},
			"&:focus": {
				boxShadow: "none",
			},
		},
		"& .fc-button-active": {
			backgroundColor: "#A13633 !important",
			borderColor: "#A13633 !important",
			"&:hover": {
				backgroundColor: theme.palette.primary.hover,
				borderColor: "#A13633",
			},
		},
		"& td": {
			border: "none",
			padding: 10,
		},
		"& .fc-scrollgrid": {
			border: "none",
		},
		"& th": {
			border: "none",
		},
		"& a": {
			color: theme.palette.background.main,
		},
		"& .fc-daygrid-dot-event": {
			padding: 0,
			margin: 0,
			height: 17,
		},
		"& .fc-daygrid-event": {
			padding: 0,
			margin: 0,
			height: 17,
		},
		"& .fc-timegrid-event": {
			boxShadow: "none",
		},
		"& .fc-daygrid-day-frame": {
			borderTop: "1px solid",
		},
		"& .fc-daygrid-day.fc-day-today": {
			backgroundColor: "transparent",
			"& .fc-daygrid-day-frame": {
				backgroundColor: "#3764a416",
				borderTop: "1px solid red",
			},
		},
		"& .fc .fc-daygrid-day-top": {
			flexDirection: "row",
		},
		"& .fc-daygrid-day-number": {
			fontSize: 35,
			color: "black",
			paddingTop: 10,
		},
		"& .fc-event": {
			borderRadius: 0,
			paddingLeft: 5,
		},
		"& .fc-button": {
			backgroundColor: "transparent",
			color: "black",
			border: "none",
			transition: "0.2s",
		},
		"& .fc-scrollgrid-sync-inner": {
			fontWeight: 200,
		},
		[theme.breakpoints.down("sm")]: {
			position: "relative",
			paddingTop: 40,
			"& .fc-toolbar-title": {
				fontSize: 25,
				position: "absolute",
				top: -5,
				left: 0,
			},
			"& .fc-daygrid-day-number": {
				fontSize: 12,
			},
		},
	},
	iconCalendar: {
		position: "absolute",
		top: 2,
		right: 20,
		width: 20,
		height: 20,
	},
	iconCalendarRes: {
		position: "absolute",
		top: 0,
		right: 45,
	},
	seccio: {
		backgroundColor: (seccio) => seccio?.color,
		cursor: "pointer",
		transition: "0.2s",
		padding: 20,
		display: "flex",
		alignItems: "center",
		border: (seccio) => "1px solid " + seccio?.color,
		"&:hover": {
			"& .MuiTypography-root": {
				color: (seccio) => seccio?.color,
			},
			backgroundColor: "transparent",
		},
	},
	seccioSel: {
		cursor: "pointer",
		transition: "0.2s",
		padding: 20,
		display: "flex",
		alignItems: "center",
		border: (seccio) => "1px solid " + seccio?.color + "40",
		"& .MuiTypography-root": {
			color: (seccio) => seccio?.color + "40",
		},
		backgroundColor: "transparent",
	},
	text: {
		transition: "0.2s",
	},
}));
