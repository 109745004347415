import { Delete, Edit } from "@mui/icons-material";
import { Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

export function CampCheck({ camp, setValue, fixe, watch, edit, setOpen, setCamp }) {
	const classes = useStyles();

	const addCamp = () => {
		if (watch("camps")?.some((i) => i.id === camp.id)) {
			setValue(
				"camps",
				watch("camps")?.filter((i) => i.id !== camp.id)
			);
		} else {
			setValue("camps", [...watch("camps"), camp]);
		}
	};

	const open = () => {
		setOpen(true);
		setCamp(camp);
	};

	return (
		<Grid item md={3}>
			<Box className={classes.camp} onClick={() => !fixe && addCamp()}>
				<Box>
					<Typography>
						<small>{camp.obligatori === 1 && "*"}</small>
						{camp.nom} <small>({camp.tipus})</small>
					</Typography>
					{camp.text && <Typography variant="caption">{camp.text}</Typography>}
				</Box>
				{edit && (
					<Stack direction={"row"}>
						<IconButton onClick={open}>
							<Edit />
						</IconButton>
					</Stack>
				)}
				{!fixe && (
					<Box display={"flex"} alignItems={"center"}>
						<Typography variant="caption">
							{watch("camps")?.map((i, index) => {
								if (i?.id === camp?.id) return index + 1;
							})}
						</Typography>
						<Checkbox checked={watch("camps")?.some((i) => i?.id === camp?.id)} />
					</Box>
				)}
			</Box>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	camp: {
		padding: "10px 25px",
		borderRadius: 40,
		backgroundColor: theme.palette.background.light,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		cursor: "pointer",
	},
}));
