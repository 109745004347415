function constructFormSeccio(values) {
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("color", values.color ?? "");
	data.append("descripcio", values.descripcio ?? "");
	data.append("que_fem", values.que_fem ?? "");
	data.append("activitats_text", values.activitats_text ?? "");
	data.append("nom_contacte", values.nom_contacte ?? "");
	data.append("email_contacte", values.email_contacte ?? "");
	data.append("telefon_contacte", values.telefon_contacte ?? "");
	data.append("instagram", values.instagram ?? "");

	if (values.imatge?.[0]?.name) {
		data.append("imatge", values.imatge[0], values.imatge[0].name);
	}

	return data;
}

function constructFormActivitat(values) {
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("publicada", values.publicada ? 1 : 0);
	data.append("descripcio", values.descripcio);
	data.append("camps", JSON.stringify(values.camps) ?? "");
	data.append("dates", JSON.stringify(values.dates));
	data.append("inscripcio", values.inscripcio ?? "");
	data.append("intern", values.intern ? 1 : 0);
	data.append("on", values.on ?? "");
	data.append("portar", values.portar ?? "");
	data.append("quan", values.quan ?? "");
	data.append("preu_soci", values.preu_soci ?? "");
	data.append("preu_no_soci", values.preu_no_soci ?? "");
	data.append("max_inscrits", values.max_inscrits ?? "");
	data.append("seccio_id", values.seccio_id);
	data.append("inscripcio_inici", values.inscripcio_inici ?? "");
	data.append("inscripcio_final", values.inscripcio_final ?? "");
	data.append("edat_min", values.edat_min ?? "");
	data.append("edat_max", values.edat_max ?? "");
	data.append("multi", values.multi ? 1 : 0);
	data.append("modificable", values.modificable ? 1 : 0);

	values.variacions.map((item, index) => {
		data.append(`variacions[${index}][id]`, item?.id);
		data.append(`variacions[${index}][nom]`, item?.nom);
		data.append(`variacions[${index}][preu_soci]`, item?.preu_soci ?? "");
		data.append(`variacions[${index}][preu_no_soci]`, item?.preu_no_soci ?? "");
		data.append(`variacions[${index}][limit]`, item?.limit ?? "");
	});

	if (values.imatge?.[0]?.name) {
		data.append("imatge", values.imatge[0], values.imatge[0].name);
	}

	return data;
}

function constructFormGaleria(values) {
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("activitat_id", values.activitat_id);
	data.append("url", values.url ?? "");
	data.append("descripcio", values.descripcio ?? "");
	data.append("extern", values.extern ? 1 : 0);

	console.log(values.imatges);

	if (values.imatges) {
		for (const file of values.imatges) {
			data.append("imatges[]", file);
		}
	}

	return data;
}

function constructFormInscrit(values) {
	const data = new FormData();

	Object.entries(values).map((val) => {
		if (val[1]?.[0]?.name) {
			data.append(val[0], val[1][0], val[1][0].name);
		} else {
			data.append(val[0], val[1]);
		}
	});

	for (var pair of data.entries()) {
		console.log(pair);
	}

	return data;
}

export { constructFormSeccio, constructFormActivitat, constructFormGaleria, constructFormInscrit };
