import { createTheme } from "@mui/material";

const getMuiTheme = () =>
	createTheme({
		components: {
			MUIDataTable: {
				styleOverrides: {
					root: {
						boxShadow: "none",
						borderRadius: 0,
						paddingTop: 20,
						zIndex: 0,
						marginBottom: 80,
						overflow: "hidden",
						position: "relative",
						fontFamily: "Rubik",
					},
					paper: {
						boxShadow: "none",
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: "none",
						borderRadius: 0,
						paddingTop: 20,
						zIndex: 0,
						marginBottom: 80,
						overflow: "hidden",
						position: "relative",
						fontFamily: "Rubik",
					},
					paper: {
						boxShadow: "none",
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						fontFamily: "Rubik",
						fontSize: 14,
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						fontFamily: "Rubik",
						fontSize: 12,
					},
				},
			},

			MUIDataTableSelectCell: {
				styleOverrides: {
					headerCell: {
						fontFamily: "Rubik",
						fontSize: 12,
					},
				},
			},
			MuiTablePagination: {
				styleOverrides: {
					selectLabel: {
						fontFamily: "Rubik",
					},
					displayedRows: {
						fontFamily: "Rubik",
					},
				},
			},
			MuiSelect: {
				styleOverrides: {
					select: {
						fontFamily: "Rubik",
						fontSize: 14,
					},
				},
			},
		},
	});

export default getMuiTheme;
