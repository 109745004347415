import { Circle } from "@mui/icons-material";
import { Chip, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import Dada from "../../../../components/elements/Dada";

export default function ActivitatDades({ activitat }) {
	const [dates, setDates] = useState([]);

	useEffect(() => {
		setDates(activitat?.dates && JSON.parse(activitat?.dates));
	}, [activitat]);

	return (
		<Box>
			<Dada nom="Nom" valor={activitat?.nom} />
			<Dada
				nom="Secció"
				valor={
					<Chip
						icon={
							<Circle
								style={{
									fill: activitat?.seccio?.color,
								}}
							/>
						}
						label={activitat?.seccio?.nom}
						variant="outlined"
					/>
				}
			/>
			<Dada nom="Inscripció" valor={Number(activitat?.intern) ? "Interna CEB" : "Externa"} />
			{Number(activitat?.intern) === 1 && (
				<>
					{activitat?.variacions?.length > 0 && (
						<Dada
							nom="Variacions preu"
							valor={
								<Stack spacing={1}>
									{activitat?.variacions?.map((c) => (
										<Chip
											sx={{ paddingTop: 2.7, paddingBottom: 2.7, borderRadius: 2, justifyContent: "flex-end" }}
											label={
												<Box>
													<Typography mt={1} variant="caption">
														{c?.nom}
													</Typography>
													<br />
													{c?.preu_soci && "Soci: " + c?.preu_soci + "€ | "}
													{c?.preu_no_soci && "No soci: " + c?.preu_no_soci + "€ | "}
													{c?.limit && c?.inscrits_count + "/" + c?.limit}
												</Box>
											}
										/>
									))}
								</Stack>
							}
						/>
					)}
					{activitat?.preu_soci !== null && <Dada nom="Preu soci" valor={activitat?.preu_soci + " €"} />}
					{activitat?.preu_no_soci !== null && <Dada nom="Preu no soci" valor={activitat?.preu_no_soci + " €"} />}
					{activitat?.inscrits && <Dada nom="Inscrits" valor={activitat?.inscrits?.length ?? "-"} />}
					<Dada nom="Màxim inscrits" valor={activitat?.max_inscrits ?? "-"} />
				</>
			)}

			{activitat?.edat_min && <Dada nom="Edat mínima" valor={activitat?.edat_min + " anys" ?? "-"} />}
			{activitat?.edat_max && <Dada nom="Edat mínima" valor={activitat?.edat_max + " anys" ?? "-"} />}
			<Dada
				nom="Dates"
				valor={
					dates?.length === 1
						? moment(dates?.[0], "DD/MM/YYYY").format("LL")
						: dates?.length === 2
						? moment(dates?.[0], "DD/MM/YYYY").format("LL") + " i " + moment(dates?.[1], "DD/MM/YYYY").format("LL")
						: "Del " + moment(dates?.[0], "DD/MM/YYYY").format("LL") + " al " + moment(dates?.[dates?.length - 1], "DD/MM/YYYY").format("LL")
				}
			/>
			{activitat?.inscripcio_inici && <Dada nom="Obertura inscripcions" valor={moment(activitat?.inscripcio_inici).format("LL")} />}
			{activitat?.inscripcio_final && <Dada nom="Tancament inscripcions" valor={moment(activitat?.inscripcio_final).format("LL")} />}
		</Box>
	);
}
