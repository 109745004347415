import React, { useEffect, useState } from "react";
import PageAdmin from "../../../components/layouts/PageAdmin";
import { CalendarMonth, CopyAll, Delete } from "@mui/icons-material";
import { Collapse, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import Loading from "../../../components/layouts/Loading";
import { addElement, getElement, getElements, updateElement } from "../../../API/API";
import { useNavigate, useParams } from "react-router";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CustomTextField from "../../../components/elements/CustomTextField";
import CustomRichTextTiny from "../../../components/elements/CustomRichTextTiny";
import CustomCard from "../../../components/layouts/CustomCard";
import Thumb from "../../../components/elements/Thumb";
import ImageInput from "../../../components/elements/ImageInput";
import { useSnackbar } from "notistack";
import { constructFormActivitat } from "../../../API/ConstructForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaActivitat } from "../../../components/elements/Schemas";
import CustomSelect from "../../../components/elements/CustomSelect";
import DatePicker, { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../../components/elements/CustomButton";
import { DialogAddCamp } from "../camps/DialogAddCamp";
import { CampCheck } from "../camps/CampCheck";
import CustomCheckbox from "../../../components/elements/CustomCheckbox";
import moment from "moment";

const months = [
	["gener", "g"],
	["febrer", "f"],
	["març", "m"],
	["abril", "a"],
	["maig", "m"],
	["juny", "j"],
	["juliol", "j"],
	["agost", "a"],
	["setembre", "s"],
	["octubre", "o"],
	["novembre", "n"],
	["decembre", "d"],
];
const weekDays = [
	["dg", "dg"],
	["dl", "dl"],
	["dm", "dm"],
	["dc", "dc"],
	["dj", "dj"],
	["dv", "dv"],
	["ds", "ds"],
];

export default function ActivitatAddEdit() {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { slug } = useParams();
	const [loading, setLoading] = useState(false);
	const [activitat, setActivitat] = useState([]);
	const [seccions, setSeccions] = useState([]);
	const [camps, setCamps] = useState([]);
	const [open, setOpen] = useState(false);
	const [update, setUpdate] = useState(0);

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		trigger,
		getValues,
		setValue,
		reset,
		watch,
	} = useForm({
		defaultValues: {
			camps: [],
			variacions: [],
			...activitat,
		},
		resolver: yupResolver(schemaActivitat),
		mode: "onTouched",
	});

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control,
		name: "variacions",
	});

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("camps");
			setCamps(result);
			setLoading(false);
		};
		obtenir();
	}, [update]);

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const secc = await getElements("seccions");
			setSeccions(secc.result);
			if (slug) {
				const { result } = await getElement("activitats", slug);
				setActivitat(result.activitat);
				reset(result.activitat);
				result.activitat.inscripcio_inici && setValue("inscripcio_inici", moment(result.activitat.inscripcio_inici, "YYYY-MM-DD").format("YYYY-MM-DD"));
				result.activitat.inscripcio_final && setValue("inscripcio_final", moment(result.activitat.inscripcio_final, "YYYY-MM-DD").format("YYYY-MM-DD"));

				setValue("descripcio", result.activitat.descripcio);

				setValue("publicada", Number(result.activitat.publicada));
				setValue("intern", Number(result.activitat.intern));

				setValue("dates", JSON.parse(result.activitat.dates));
				setValue("variacions", result.activitat.variacions);
				setValue("variacio", result.activitat.variacions.length > 0);
			}
			setLoading(false);
		};
		obtenir();
	}, [slug]);

	useEffect(() => {
		slug &&
			setValue("seccio_id", activitat.seccio_id, {
				shouldValidate: true,
			});
	}, [activitat]);

	const enviar = async (values) => {
		setLoading(true);
		values.dates = values?.dates?.map((i) => {
			if (typeof i === "string" || i instanceof String) {
				return i;
			} else {
				return i.format("DD/MM/YYYY");
			}
		});

		let m = "Error";
		if (slug) {
			const { message, data, type } = await updateElement("activitats", slug, constructFormActivitat(values));
			m = message;
			type === "success" && navigate("/admin/activitats/view/" + data?.slug);
			enqueueSnackbar(m, {
				variant: type,
			});
		} else {
			const { message, type } = await addElement("activitats", constructFormActivitat(values));
			m = message;
			type === "success" && navigate("/admin/activitats");
			enqueueSnackbar(m, {
				variant: type,
			});
		}
		setLoading(false);
	};

	return (
		<>
			<form onSubmit={handleSubmit(enviar)}>
				<PageAdmin
					title={slug ? "Editar activitat" : "Afegir activitat"}
					Icon={CalendarMonth}
					button={
						<Stack direction={"row"} spacing={2}>
							{slug && (
								<>
									<CustomButton onClick={() => navigate("/admin/activitats/view/" + activitat?.slug)} dark title={"Tornar"} />
									<CustomButton
										dark
										onClick={() => {
											const newWindow = window.open("/activitats/" + activitat.slug, "_blank", "noopener,noreferrer");
											if (newWindow) newWindow.opener = null;
										}}
										title={"Veure pàgina pública"}
									/>
								</>
							)}

							<CustomButton type="submit" success title={slug ? "Guardar" : "Crear"} loading={loading} />
						</Stack>
					}
				>
					<Loading loading={loading}>
						<Grid spacing={3} container>
							<Grid item md={7} xl={8}>
								<CustomCard title={"Dades generals"}>
									<Grid container spacing={3}>
										<Grid item md={6}>
											<CustomTextField
												register={register}
												name="nom"
												label="Títol activitat"
												errors={errors.nom}
												InputLabelProps={{
													shrink: watch("nom") ? true : false,
												}}
											/>
										</Grid>
										<Grid item md={6}>
											<CustomSelect
												list={seccions}
												setValue={setValue}
												errors={errors.seccio_id}
												label="Secció"
												register={register}
												name="seccio_id"
												control={control}
												defaultValue={slug ? Number(activitat?.seccio_id) : ""}
											/>
										</Grid>
									</Grid>

									<CustomCheckbox control={control} label="Publicada" name={"publicada"} setValue={setValue} />

									<CustomRichTextTiny
										watch={watch}
										register={register}
										getValues={getValues}
										label={"Descripció"}
										name="descripcio"
										setValue={setValue}
										errors={errors.descripcio}
									/>
								</CustomCard>
								<CustomCard title={"Altres dades"}>
									<Grid spacing={2} container>
										<Grid item md={6}>
											<CustomRichTextTiny
												watch={watch}
												register={register}
												getValues={getValues}
												label={"Horaris en format text"}
												name="quan"
												setValue={setValue}
											/>
										</Grid>
										<Grid item md={6}>
											<CustomRichTextTiny
												watch={watch}
												register={register}
												getValues={getValues}
												label={"On es fa?"}
												name="on"
												setValue={setValue}
											/>
										</Grid>
									</Grid>
									<Grid spacing={2} container>
										<Grid item md={6} xs={12}>
											<CustomRichTextTiny
												watch={watch}
												register={register}
												getValues={getValues}
												label={"Com inscriure's"}
												name="inscripcio"
												setValue={setValue}
											/>
										</Grid>
										<Grid item md={6} xs={12}>
											<CustomRichTextTiny
												watch={watch}
												register={register}
												getValues={getValues}
												label={"Què cal portar?"}
												name="portar"
												setValue={setValue}
											/>
										</Grid>
									</Grid>
								</CustomCard>
							</Grid>
							<Grid item md={5} xl={4}>
								<CustomCard title={"Dates"}>
									<Controller
										control={control}
										name="dates"
										render={({ field: { onChange, value, ref } }) => (
											<Box className={classes.calendari}>
												<Calendar
													multiple
													value={value}
													onChange={onChange}
													months={months}
													weekDays={weekDays}
													// minDate={new Date()}
													format="DD/MM/YYYY"
													weekStartDayIndex={1}
													sort
													formattingIgnoreList={["week", "day", "name", "month", "of"]}
													plugins={[<DatePanel />]}
													containerStyle={{
														width: "100%",
													}}
													inputRef={ref}
												/>
											</Box>
										)}
									/>
									<Typography variant="error">{errors?.dates?.message}</Typography>
								</CustomCard>
								<CustomCard title={"Imatge"}>
									<Typography variant="h6">Imatge principal</Typography>
									<Thumb file={getValues("imatge")} />

									<ImageInput
										name="imatge"
										register={register}
										trigger={trigger}
										getValues={getValues}
										text={"Afegir imatge principal"}
										watch={watch}
										setValue={setValue}
									/>
								</CustomCard>
								<CustomCard title={"Inscripcions"}>
									<CustomCheckbox control={control} label="Inscripció i pagament des del web de CEB" name={"intern"} setValue={setValue} />
									<Collapse in={watch("intern")}>
										<Typography variant="h6" mt={4}>
											Preus
										</Typography>
										<Divider />
										<Collapse in={!watch("variacio")}>
											<CustomTextField
												register={register}
												name="preu_soci"
												label="Preu socis"
												type="number"
												mt={2}
												InputLabelProps={{
													shrink: watch("preu_soci") ? true : false,
												}}
											/>
											<Typography variant="caption">
												Si no s'omple, l'activitat estarà disponible per a tothom i no es tindrà en compte si és soci.
											</Typography>

											<CustomTextField
												register={register}
												name="preu_no_soci"
												label="Preu no socis"
												type="number"
												InputLabelProps={{
													shrink: watch("preu_no_soci") ? true : false,
												}}
												mt={3}
											/>
											<Typography variant="caption">Si no s'omple el valor, l'activitat no estarà disponible per a no socis.</Typography>
										</Collapse>
										<CustomCheckbox control={control} label="Variacions de preu" name={"variacio"} setValue={setValue} />
										<Collapse in={watch("variacio")}>
											{fields.map((field, index) => (
												<Box p={1} border="1px solid lightgray" borderRadius={2} mb={1} key={field.id}>
													<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
														<Typography fontWeight={800} mb={1}>
															Variació {index + 1}
														</Typography>
														<Box>
															<IconButton size="small" onClick={() => append(field)}>
																<CopyAll />
															</IconButton>
															<IconButton size="small" onClick={() => remove(index)}>
																<Delete />
															</IconButton>
														</Box>
													</Box>
													<Grid container spacing={0.5}>
														<Grid item lg={5} md={12} sm={12} xs={12}>
															<CustomTextField
																register={register}
																name={`variacions.${index}.nom`}
																label={"Nom"}
																required
																size="small"
															/>
														</Grid>
														<Grid item lg={2.5} md={4} sm={12} xs={12}>
															<CustomTextField
																register={register}
																name={`variacions.${index}.preu_soci`}
																label={"Soci"}
																type="number"
																step={0.01}
																endAdornment={"€"}
																size="small"
															/>
														</Grid>
														<Grid item lg={2.5} md={4} sm={12} xs={12}>
															<CustomTextField
																register={register}
																name={`variacions.${index}.preu_no_soci`}
																label={"No soci"}
																type="number"
																step={0.01}
																endAdornment={"€"}
																size="small"
															/>
														</Grid>
														<Grid item lg={2} md={4} sm={12} xs={12}>
															<CustomTextField
																register={register}
																name={`variacions.${index}.limit`}
																label={"Limit"}
																type="number"
																size="small"
															/>
														</Grid>
													</Grid>
													<input {...register(`variacions.${index}.id`)} hidden />
												</Box>
											))}
											<CustomButton dark title={"Afegir variació"} onClick={() => append()} />
											<Box>
												<CustomCheckbox control={control} label="Múltiples seleccions" name={"multi"} setValue={setValue} />
												<br />
												<Typography variant="caption">
													Activa-ho si el client pot activar vàries variacions i el preu es suma al total
												</Typography>
											</Box>
											<Box>
												<CustomCheckbox control={control} label="Inscripció modificable" name={"modificable"} setValue={setValue} />
												<br />
												<Typography variant="caption">
													Activa-ho si el client pot modificar la inscripció afegint més variacions si entra un DNI existent
												</Typography>
											</Box>
										</Collapse>

										<Typography variant="h6" mt={4}>
											Control inscripcions
										</Typography>
										<Divider />

										<CustomTextField
											register={register}
											name="max_inscrits"
											label="Màxim inscrits"
											type="number"
											InputLabelProps={{
												shrink: watch("max_inscrits") ? true : false,
											}}
											mt={3}
										/>
										<Typography variant="caption">Si no s'omple el valor, l'activitat no tindrà limit d'inscrits.</Typography>
										<Grid container spacing={3} mt={1}>
											<Grid item md={6}>
												<CustomTextField
													register={register}
													name={"edat_min"}
													label="Edat mínima"
													type="number"
													InputLabelProps={{
														shrink: watch("edat_min") ? true : false,
													}}
												/>
											</Grid>
											<Grid item md={6}>
												<CustomTextField
													register={register}
													name={"edat_max"}
													label="Edat màxima"
													type="number"
													InputLabelProps={{
														shrink: watch("edat_max") ? true : false,
													}}
												/>
											</Grid>
										</Grid>
										<Typography variant="caption">Deixa els camps buits si no hi ha restricció d'edat</Typography>
										<Typography variant="h6" mt={4}>
											Obertura/tancament inscripcions
										</Typography>
										<Divider />
										<Grid container spacing={3} mt={1}>
											<Grid item md={6}>
												<CustomTextField
													register={register}
													name={"inscripcio_inici"}
													label="Obertura inscripcions"
													type="date"
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
											<Grid item md={6}>
												<CustomTextField
													register={register}
													name={"inscripcio_final"}
													label="Tancament inscripcions"
													type="date"
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
										</Grid>
										<Typography variant="caption">Si es deixen buides, es podrà realitzar una inscripció en qualsevol moment.</Typography>
									</Collapse>
								</CustomCard>
							</Grid>
						</Grid>
						<Collapse in={watch("intern")}>
							<CustomCard title={"Camps al formulari d'inscripció"} button onClick={() => setOpen(true)}>
								<Typography variant="h5" mb={1}>
									Fixes
								</Typography>
								<Grid spacing={3} container mb={4}>
									<CampCheck camp={{ nom: "Nom", tipus: "text" }} fixe />
									<CampCheck camp={{ nom: "Cognoms", tipus: "text" }} fixe />
									<CampCheck camp={{ nom: "E-mail", tipus: "email" }} fixe />
									<CampCheck camp={{ nom: "DNI", tipus: "text" }} fixe />
								</Grid>
								<Typography variant="h5" mb={1}>
									Variables
								</Typography>
								<Grid spacing={3} container>
									{camps?.map((camp) => (
										<CampCheck key={camp?.id} camp={camp} setValue={setValue} watch={watch} />
									))}
								</Grid>
							</CustomCard>
						</Collapse>
					</Loading>
				</PageAdmin>
			</form>
			<DialogAddCamp open={open} setOpen={setOpen} setUpdate={setUpdate} />
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	calendari: {
		"& .rmdp-wrapper": {
			width: "100% !important",
		},
		"& span": {
			fontFamily: "Rubik",
		},
		"& div": {
			fontFamily: "Rubik",
		},
		"& .rmdp-panel": {
			width: "100% !important",
		},
	},
}));
