import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "../../theme";

function TitlePublic({ title }) {
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems="center"
			mb={10}
		>
			<Typography variant="h1" color="secondary" mb={1}>
				{title}
			</Typography>
			<Divider
				color={theme.palette.secondary.main}
				sx={{ width: "80%", textAlign: "center" }}
			/>
		</Box>
	);
}

export default TitlePublic;
