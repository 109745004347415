import { Link } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";

export function GaleriaItemAdmin({ galeria }) {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Box className={classes.camp} onClick={() => navigate("/admin/galeries/add/" + galeria.id)} my={2}>
			<Typography>
				{galeria?.url ? <small>Enllaç extern</small> : <small>Imatges: {galeria?.imatges_count}</small>}
				<br />
				{galeria?.nom}
			</Typography>
			<IconButton>
				<Link />
			</IconButton>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	camp: {
		padding: "10px 25px",
		borderRadius: 40,
		backgroundColor: theme.palette.background.light,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		cursor: "pointer",
	},
}));
