import React from "react";
import Page from "../../../components/layouts/Page";
import Activitats from "./Activitats";
import Portada from "./Portada";
import Presentacio from "./Presentacio";
import Seccions from "./Seccions";

function Home() {
	return (
		<Page title="Club Esquí Berguedà">
			<Portada />
			<Presentacio />
			<Seccions />
			<Activitats limit={4} />
		</Page>
	);
}

export default Home;
