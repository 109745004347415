import React, { useEffect, useState } from "react";
import PageAdmin from "../../../components/layouts/PageAdmin";
import { CalendarMonth, Delete } from "@mui/icons-material";
import { Autocomplete, Avatar, Box, Chip, Collapse, Grid, IconButton, ImageList, ImageListItem, Stack, TextField, Typography } from "@mui/material";
import Loading from "../../../components/layouts/Loading";
import { addElement, deleteElement, getElement, getElements, updateElement } from "../../../API/API";
import { useNavigate, useParams } from "react-router";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../../components/elements/CustomTextField";
import CustomRichTextTiny from "../../../components/elements/CustomRichTextTiny";
import CustomCard from "../../../components/layouts/CustomCard";
import { useSnackbar } from "notistack";
import { constructFormGaleria } from "../../../API/ConstructForm";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../../components/elements/CustomButton";
import CustomCheckbox from "../../../components/elements/CustomCheckbox";
import ImageInput from "../../../components/elements/ImageInput";
import ThumbMulti from "../../../components/elements/ThumbMulti";
import { DialogEliminar } from "../../../components/layouts/DialogEliminar";

export default function GaleriaAddEdit() {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [activitats, setActivitats] = useState([]);
	const [galeria, setGaleria] = useState();
	const [open, setOpen] = useState(false);
	const [imatge, setImatge] = useState("");
	const [update, setUpdate] = useState(0);

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		trigger,
		getValues,
		setValue,
		reset,
		watch,
	} = useForm();

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElements("activitatsAdmin");
			const activitatsNewe = result.map((item) => {
				return { id: item.id, label: item.nom };
			});
			setActivitats(activitatsNewe);

			if (id) {
				const { result } = await getElement("galeries", id);
				setGaleria(result);
				reset(result);
				setValue("activitat", result.activitat_id + " - " + result.activitat.nom);
				setValue("imatges", []);
				setValue("extern", result.url ? true : false);
			}
			setLoading(false);
		};
		obtenir();
	}, [id, update]);

	const enviar = async (values) => {
		setLoading(true);
		values.activitat_id = values.activitat.split(" - ")[0];
		console.log(values);
		let m = "Error";
		if (id) {
			const { message, data, type } = await updateElement("galeries", id, constructFormGaleria(values));
			m = message;
			enqueueSnackbar(m, {
				variant: type,
			});
			setUpdate((prev) => prev + 1);
		} else {
			const { message, type } = await addElement("galeries", constructFormGaleria(values));
			m = message;
			console.log(message);
			console.log(type);
			type === "success" && navigate("/admin/galeries");
			enqueueSnackbar(m, {
				variant: type,
			});
		}
		setLoading(false);
	};

	const eliminarImatge = async () => {
		console.log("Eliminar " + imatge);
		if (id) {
			const { message, type } = await deleteElement("imatges", imatge);
			enqueueSnackbar(message, {
				variant: type,
			});
			setOpen(false);
			setUpdate((prev) => prev + 1);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(enviar)}>
				<PageAdmin
					title={id ? "Editar galeria" : "Afegir galeria"}
					Icon={CalendarMonth}
					button={
						<Stack direction={"row"} spacing={2}>
							{id && (
								<>
									<CustomButton
										dark
										onClick={() => {
											const newWindow = window.open("/galeries/" + galeria.id, "_blank", "noopener,noreferrer");
											if (newWindow) newWindow.opener = null;
										}}
										title={"Veure pàgina pública"}
									/>
								</>
							)}

							<CustomButton type="submit" success title={id ? "Guardar" : "Crear"} loading={loading} />
						</Stack>
					}
				>
					<Loading loading={loading}>
						<Grid spacing={3} container>
							<Grid item md={6} xl={6}>
								<CustomCard title={"Dades generals"}>
									<CustomTextField
										register={register}
										name="nom"
										label="Títol de la galeria"
										errors={errors.nom}
										InputLabelProps={{
											shrink: watch("nom") ? true : false,
										}}
										required
									/>

									<CustomCheckbox control={control} label="URL externa" name={"extern"} setValue={setValue} />
									<Typography variant="body1">
										<small>En cas de ser una galeria de imatges externa, enganxa l'enllaç aquí.</small>
									</Typography>
									<Collapse in={watch("extern")}>
										<CustomTextField
											register={register}
											name="url"
											label="URL de la galeria externa"
											mt={3}
											errors={errors.nom}
											InputLabelProps={{
												shrink: watch("url") ? true : false,
											}}
										/>
									</Collapse>
									<Box mt={3}>
										<Controller
											control={control}
											name={"activitat"}
											render={({ field: { name } }) => (
												<Autocomplete
													value={watch(name)}
													onChange={(event, values) => {
														setValue(name, values);
													}}
													id={"Activitat relacionada"}
													options={activitats?.map((option) => option.id + " - " + option.label)}
													freeSolo
													renderTags={(value, getTagProps) =>
														value?.map((option, index) => (
															<Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
														))
													}
													renderInput={(params) => (
														<TextField {...params} variant="outlined" label={"Activitat relacionada"} placeholder="Cercar" />
													)}
												/>
											)}
										/>
									</Box>

									<CustomRichTextTiny
										watch={watch}
										register={register}
										getValues={getValues}
										label={"Descripció"}
										name="descripcio"
										setValue={setValue}
										errors={errors.descripcio}
									/>
								</CustomCard>
							</Grid>
							<Grid item md={6} xs={12}>
								<CustomCard title={"Afegir Imatges"}>
									<ThumbMulti file={watch("imatges")} />
									<ImageInput
										errors={errors.imatges}
										multiple
										name={"imatges"}
										register={register}
										trigger={trigger}
										text={"Afegeix imatges"}
										watch={watch}
										setValue={setValue}
									/>
								</CustomCard>
								{id && (
									<CustomCard title={"Imatges entrades"}>
										<ImageList sx={{ width: "100%" }} cols={2} rowHeight={200}>
											{galeria?.imatges?.map((item) => (
												<ImageListItem key={item}>
													<Avatar
														variant="rounded"
														src={process.env.REACT_APP_STORAGE + item.foto}
														alt={item.title}
														style={{ width: "100%", height: "100%" }}
														loading="lazy"
													/>
													<Box position={"absolute"}>
														<IconButton
															color="danger"
															onClick={() => {
																setOpen(true);
																setImatge(item.id);
															}}
														>
															<Delete />
														</IconButton>
													</Box>
												</ImageListItem>
											))}
										</ImageList>
									</CustomCard>
								)}
							</Grid>
						</Grid>
					</Loading>
				</PageAdmin>
				<DialogEliminar element={"imatge"} onClick={eliminarImatge} open={open} setOpen={setOpen} />
			</form>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	calendari: {
		"& .rmdp-wrapper": {
			width: "100% !important",
		},
		"& span": {
			fontFamily: "Rubik",
		},
		"& div": {
			fontFamily: "Rubik",
		},
		"& .rmdp-panel": {
			width: "100% !important",
		},
	},
}));
