import { Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addElement, deleteElement, updateElement } from "../../../API/API";
import CustomButton from "../../../components/elements/CustomButton";
import CustomCheckbox from "../../../components/elements/CustomCheckbox";
import CustomSelect from "../../../components/elements/CustomSelect";
import CustomTextField from "../../../components/elements/CustomTextField";

const tipus = [
	{ id: "text", nom: "Text" },
	{ id: "checkbox", nom: "Checkbox" },
	{ id: "number", nom: "Número" },
	{ id: "date", nom: "Data" },
	{ id: "select", nom: "Selecció elements" },
	{ id: "textarea", nom: "Àrea de text" },
	{ id: "persones", nom: "Persones (llistat nom, data naixement, DNI i talla)" },
	{ id: "radio", nom: "Elecció d'elements" },
	{ id: "document", nom: "Penjar un document" },
];

export function DialogAddCamp({ setOpen, open, setUpdate, camp }) {
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, control, setValue, reset, watch } = useForm();

	useEffect(() => {
		camp ? reset(camp) : reset({});
	}, [camp]);

	const addCamp = async (values) => {
		console.log(values);
		setLoading(true);
		const { message, type } = await addElement("camps", values);
		enqueueSnackbar(message, {
			variant: type,
		});
		if (type === "error") {
			setLoading(false);
		} else {
			setLoading(false);
			reset();
			setUpdate((prev) => prev + 1);
			setOpen(false);
		}
	};

	const updateCamp = async (values) => {
		setLoading(true);
		const { message, type } = await updateElement("camps", camp.slug, values);
		enqueueSnackbar(message, {
			variant: type,
		});
		if (type === "error") {
			setLoading(false);
		} else {
			setLoading(false);
			reset();
			setUpdate((prev) => prev + 1);
			setOpen(false);
		}
	};

	const eliminarCamp = async () => {
		setLoading(true);
		const { message, type } = await deleteElement("camps", camp.id);
		enqueueSnackbar(message, {
			variant: type,
		});
		if (type === "error") {
			setLoading(false);
		} else {
			setLoading(false);
			reset();
			setUpdate((prev) => prev + 1);
			setOpen(false);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableScrollLock
			maxWidth="md"
			fullWidth
		>
			<form onSubmit={handleSubmit(camp ? updateCamp : addCamp)}>
				<DialogTitle>{camp ? "Editar camp" : "Afegir camp"}</DialogTitle>
				<DialogContent>
					<Grid spacing={3} container>
						<Grid item md={6}>
							<CustomTextField register={register} name="nom" label={"Nom camp"} mt={1} required />
						</Grid>
						<Grid item md={6}>
							<CustomSelect
								list={tipus}
								mt={1}
								label="Tipus de camp"
								name="tipus"
								register={register}
								defaultValue=""
								required
								control={control}
							/>
						</Grid>
					</Grid>
					<Collapse in={watch("tipus") === "select" || watch("tipus") === "radio"}>
						<Box>
							<CustomTextField register={register} name="elements" label={"Elements"} mt={3} multiline rows={4} />
							<Typography variant="caption">Introdueix les opcions separades amb una coma (,) sense espais entre opcions.</Typography>
						</Box>
					</Collapse>
					<CustomTextField register={register} name="text" label={"Text (opcional)"} mt={3} multiline rows={4} />

					<CustomCheckbox control={control} label="Obligatòri" name={"obligatori"} setValue={setValue} />
				</DialogContent>
				<DialogActions>
					<CustomButton onClick={() => setOpen(false)} title="Tancar" fullWidth dark />
					{camp && <CustomButton onClick={eliminarCamp} title="Eliminar" fullWidth danger />}
					<CustomButton title={camp ? "Guardar" : "Afegir"} type="submit" success fullWidth loading={loading} />
				</DialogActions>
			</form>
		</Dialog>
	);
}
