import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import NavBarItem from "./NavBarItem";
import { Box, Divider, Drawer, Hidden, List, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
	Apps,
	CalendarMonth,
	Dashboard,
	EditAttributes,
	Extension,
	Group,
	House,
	Inventory,
	Newspaper,
	Note,
	Person,
	Photo,
	PrecisionManufacturing,
} from "@mui/icons-material";
import theme from "../../theme";
// import { ReactComponent as Logo } from "../../assets/img/logo-icon.svg";

const useStyles = makeStyles((theme) => ({
	mobileDrawer: {
		width: 200,
	},
	desktopDrawer: {
		width: 200,
		height: "calc(100%)",
		border: 0 + " !important",
	},
	menu: {
		background: theme.palette.primary.main,
		textAlign: "center",
	},
}));

const NavBar = ({ onMobileClose, openMobile }) => {
	const classes = useStyles();

	const items = [
		{
			href: "/admin/dash",
			icon: Dashboard,
			title: "Dashboard",
		},
		{
			href: "/admin/activitats",
			icon: CalendarMonth,
			title: "Activitats",
		},

		{
			href: "/admin/camps",
			icon: EditAttributes,
			title: "Camps",
		},
		{
			href: "/admin/seccions",
			icon: Apps,
			title: "Seccions",
		},
		{
			href: "/admin/inscrits",
			icon: Group,
			title: "Inscrits",
		},
		{
			href: "/admin/galeries",
			icon: Photo,
			title: "Galeries",
		},
	];

	const content = (
		<Box
			height="100%"
			display="flex"
			flexDirection="column"
			className={classes.menu}
		>
			<Box pt={2}>
				<RouterLink to="/" style={{ textDecoration: "none" }}>
					<Typography variant="h4" color="white">
						CEB | ADMIN
					</Typography>
				</RouterLink>
				<Box mt={8} />

				<List>
					{items?.map((item, index) =>
						item.href ? (
							<NavBarItem
								href={item.href}
								key={index}
								title={item.title}
								icon={item.icon}
								onClose={onMobileClose}
							/>
						) : (
							<Divider
								key={index}
								style={{
									margin: 17,
									borderColor:
										theme.palette.background.color + "40",
								}}
							/>
						)
					)}
				</List>
			</Box>
			<Box flexGrow={1} />
		</Box>
	);

	return (
		<div>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden lgDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</div>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
};

export default NavBar;
