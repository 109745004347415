import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Login } from "../../../API/API";
import Page from "../../../components/layouts/Page";
import CustomButton from "../../../components/elements/CustomButton";
import Loading from "../../../components/layouts/Loading";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "80vh",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	pill: {
		padding: 50,
		backgroundColor: "white",
		borderRadius: 20,
	},
}));

const LoginView = () => {
	const classes = useStyles();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	let navigate = useNavigate();

	const schema = Yup.object().shape({
		email: Yup.string()
			.email("Ha de ser un email vàlid")
			.max(255)
			.required("L'email és obligatòri"),
		password: Yup.string()
			.max(255)
			.required("La contrasenya és obligatòria"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schema),
	});

	const logInEmail = async (values) => {
		setLoading(true);
		const { email, password } = values;
		const token = await Login(email, password);
		if (token === "error") {
			setError("Usuari o contrasenya incorrectes");
			setLoading(false);
		} else {
			setTimeout(() => navigate("/admin/"), 1000);
		}
	};

	return (
		<Page title={"Login"}>
			{/* <Header img={img} small /> */}
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				className={classes.root}
			>
				<Container maxWidth="sm">
					<Box className={classes.pill}>
						<Loading loading={loading}>
							<form onSubmit={handleSubmit(logInEmail)}>
								<Box mb={3}>
									<Typography
										color="textPrimary"
										variant="h2"
									>
										{"Entrar"}
									</Typography>
									<Typography variant="body1">
										{"Entra a la pàgina d'administració"}
									</Typography>
								</Box>
								<TextField
									error={Boolean(errors && errors.email)}
									fullWidth
									helperText={
										errors && errors?.email?.message
									}
									label="E-mail"
									margin="normal"
									name="email"
									type="email"
									variant="outlined"
									color="primary"
									{...register("email")}
								/>
								<TextField
									error={Boolean(errors && errors.password)}
									fullWidth
									helperText={
										errors && errors?.password?.message
									}
									label={"Contrasenya"}
									margin="normal"
									name="password"
									type="password"
									variant="outlined"
									{...register("password")}
								/>
								<Typography
									variant="body1"
									style={{ color: "red" }}
								>
									{error}
								</Typography>

								<Box my={2}>
									<CustomButton
										fullWidth
										type="submit"
										title="Entrar"
										dark
									/>
								</Box>
							</form>
						</Loading>
					</Box>
				</Container>
			</Box>
		</Page>
	);
};

export default LoginView;
