import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar, Avatar, Box, Hidden, IconButton, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { Input, Menu, Settings } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import { logout } from "../../API/API";
import { useSnackbar } from "notistack";
// import { ReactComponent as Logo } from "../../assets/img/logo-icon.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiToolbar-root": {
			boxShadow: "#00000020 1px 3px 20px 1px ",
			// backgroundColor: "white",
			backgroundColor: theme.palette.secondary.main,
		},
	},
	white: {
		"& .MuiToolbar-root": {
			backgroundColor: theme.palette.primary.main,
		},
	},
	color: {
		"& .MuiToolbar-root": {
			backgroundColor: theme.palette.background.main,
		},
	},
	logo: {
		height: "70px !important",
		"& .cls-1": {
			fill: theme.palette.background.color,
		},
	},
}));

const TopBarAdmin = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	let navigate = useNavigate();
	const [user, setUser] = useState();
	const matches = useMediaQuery("(min-width:960px)");
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const u = localStorage.getItem("user");
		setUser(JSON.parse(u));
	}, []);

	const logOut = async () => {
		const { message } = await logout();
		enqueueSnackbar(message, {
			variant: "success",
		});
		localStorage.removeItem("user");
		localStorage.removeItem("isLoggedIn");
		navigate("/");
	};

	return (
		<AppBar className={clsx(classes.root, matches ? classes.white : classes.color)} elevation={0} {...rest}>
			<Toolbar>
				<RouterLink to="/" style={{ textDecoration: "none" }}>
					<Typography variant="h4" color="white">
						CEB | ADMIN
					</Typography>
				</RouterLink>
				<Box flexGrow={1} />
				<Box display="flex" alignItems={"center"} justifyContent="space-between">
					<Hidden mdDown>
						<Typography color="white" variant="body1" mr={2}>
							{user?.name}
						</Typography>
					</Hidden>
					<IconButton onClick={() => logOut()}>
						<Input color="secondary" />
					</IconButton>
				</Box>
				<Hidden lgUp>
					<IconButton color="inherit" onClick={() => onMobileNavOpen()}>
						<Menu style={{ fill: theme.palette.text.secondary }} />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

TopBarAdmin.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBarAdmin;
