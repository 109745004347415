import { Fade, Grid, Hidden, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, Container } from "@mui/system";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getElement } from "../../../API/API";
import { Header } from "../../../components/layouts/Header";
import Page from "../../../components/layouts/Page";
import mask from "../../../assets/img/mask.png";
import useScrollY from "../../../components/elements/useScrollY";
import { Sticky, StickyContainer } from "react-sticky";
import Activitats from "../home/Activitats";
import Loading from "../../../components/layouts/Loading";
import { GaleriaItem } from "../galeries/GaleriaItem";
import CustomButtonPublic from "../../../components/elements/CustomButtonPublic";
import { Instagram } from "@mui/icons-material";

export default function SeccioSingle() {
	const classes = useStyles();
	const [seccio, setSeccio] = useState([]);
	const [loading, setLoading] = useState(true);
	const [galeries, setGaleries] = useState([]);
	const [image, setImage] = useState(true);
	const { slug } = useParams();
	const scrollY = useScrollY();
	const col = useRef();

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElement("seccionsPublic", slug);
			setSeccio(result.seccio);
			setGaleries(result.galeries);
			setLoading(false);
		};
		slug && obtenir();
	}, [slug]);

	useLayoutEffect(() => {
		const height = col?.current?.getBoundingClientRect().height;
		const onScroll = () => {
			if (height < scrollY) setImage(false);
			else setImage(true);
		};
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [scrollY]);

	return (
		<Page title={"Club Esquí Berguedà - " + seccio?.nom}>
			<Header title={seccio?.nom} color={seccio?.color} escut loading={loading} />
			<StickyContainer>
				<Box style={{ backgroundColor: "white" }} py={10}>
					<Loading loading={loading}>
						<Container maxWidth={"xl"}>
							<Grid spacing={5} container>
								<Grid item md={7} style={{ position: "relative" }}>
									<Hidden mdDown>
										<Fade in={image}>
											<div>
												<Sticky topOffset={80} isActive={!image}>
													{({ style }) => (
														<Box style={style}>
															<Box
																className={classes.mask}
																style={{
																	"-webkit-mask-image": `url(${mask})`,
																	"mask-image": `url(${mask})`,
																	marginTop: 50,
																}}
															>
																<img
																	src={process.env.REACT_APP_STORAGE + seccio?.imatge}
																	style={{
																		width: "100%",
																	}}
																	alt={seccio?.nom}
																/>
															</Box>
														</Box>
													)}
												</Sticky>
											</div>
										</Fade>
									</Hidden>
									<Hidden mdUp>
										<Box
											className={classes.mask}
											style={{
												"-webkit-mask-image": `url(${mask})`,
												"mask-image": `url(${mask})`,
												marginTop: 50,
											}}
										>
											<img
												src={process.env.REACT_APP_STORAGE + seccio?.imatge}
												style={{
													width: "100%",
												}}
												alt={seccio?.nom}
											/>
										</Box>
									</Hidden>
								</Grid>
								<Grid item md={5} mt={8} ref={col}>
									<Typography variant="h2" mb={5}>
										{seccio?.nom}
									</Typography>
									<Typography variant="body1" mb={8}>
										<div
											dangerouslySetInnerHTML={{
												__html: seccio?.descripcio,
											}}
										/>
									</Typography>
									{seccio?.que_fem && (
										<Box>
											<Typography variant="h4" mb={2} color={seccio?.color}>
												Què fem?
											</Typography>
											<Typography variant="body1" mb={8}>
												<div
													dangerouslySetInnerHTML={{
														__html: seccio?.que_fem,
													}}
												/>
											</Typography>
										</Box>
									)}
									{seccio?.activitats_text && (
										<Box>
											<Typography variant="h4" mb={2} color={seccio?.color}>
												Activitats
											</Typography>
											<Typography variant="body1" mb={8}>
												<div
													dangerouslySetInnerHTML={{
														__html: seccio?.activitats_text,
													}}
												/>
											</Typography>
										</Box>
									)}
									<Typography variant="h4" mb={2}>
										Contacta
									</Typography>

									<Typography variant="body1" mb={3}>
										Nom: <strong>{seccio?.nom_contacte}</strong>
									</Typography>
									<Typography variant="body1" mb={3}>
										E-mail: <strong>{seccio?.email_contacte}</strong>
									</Typography>
									<Typography variant="body1" mb={3}>
										Telèfon: <strong>{seccio?.telefon_contacte}</strong>
									</Typography>
									{seccio?.instagram && (
										<CustomButtonPublic Icon={Instagram} title={"Instagram"} onClick={() => window.open(seccio?.instagram)} />
									)}
								</Grid>
							</Grid>
							<Box>
								<Activitats seccio_id={seccio?.id} />
							</Box>
							{galeries?.length > 0 && (
								<Container>
									<Typography variant="h3" mb={8}>
										Galeries d'imatges
									</Typography>
									<Grid spacing={4} container>
										{galeries?.map((galeria) => (
											<Grid item md={3} key={galeria?.id}>
												<GaleriaItem galeria={galeria} seccio={seccio} />
											</Grid>
										))}
									</Grid>
								</Container>
							)}
						</Container>
					</Loading>
				</Box>
			</StickyContainer>
		</Page>
	);
}

const useStyles = makeStyles((theme) => ({
	mask: {
		"-webkit-mask-repeat": "no-repeat",
		"mask-repeat": "no-repeat",
		"mask-position": "50% 50%",
		"-webkit-mask-size": "contain",
	},

	card: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "25%",
	},

	parallax: {
		marginRight: "15%",
	},

	sticky: {
		marginLeft: "15%",
	},
}));
