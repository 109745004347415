import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Page from "../../../components/layouts/Page";
import { getElements } from "../../../API/API";
import { Header } from "../../../components/layouts/Header";
import { Container } from "@mui/system";
import theme from "../../../theme";
import Loading from "../../../components/layouts/Loading";
import { GaleriaItem } from "./GaleriaItem";
import img from "../../../assets/img/portada-activitats.jpg";

export function Galeries() {
	const [loading, setLoading] = useState(true);
	const [galeries, setGaleries] = useState([]);

	useEffect(() => {
		const obtenir = async () => {
			const galeries = await getElements("galeries");
			setGaleries(galeries.result);
			setLoading(false);
		};
		obtenir();
	}, []);

	return (
		<Page title={"Galeria"}>
			<Header overlay title={"Galeria"} img={img} />
			<Loading loading={loading}>
				<Box
					style={{
						backgroundColor: "white",
						borderBottom: "1px solid white",
					}}
					py={15}
					px={3}
				>
					<Container>
						<Grid spacing={4} container display={"flex"}>
							{galeries?.map((galeria, index) => (
								<Grid item md={4} sx={12}>
									<GaleriaItem galeria={galeria} seccio={galeria?.activitat?.seccio} />
								</Grid>
							))}
						</Grid>
					</Container>
				</Box>
			</Loading>
		</Page>
	);
}
