import { Circle } from "@mui/icons-material";
import { Box, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { isDark } from "../../../../components/Utils";

const renderEvent = (event) => {
	return (
		<Tooltip title={event.event.textColor} style={{ cursor: "pointer" }}>
			<Box
				overflow={"hidden"}
				display="flex"
				alignItems={"center"}
				cursor="pointer"
				borderRadius={0}
			>
				<Typography
					variant="caption"
					color={isDark(event.backgroundColor) ? "white" : "black"}
					fontSize={13}
					letterSpacing={-0.4}
					lineHeight={"inherit"}
				>
					{event.event.title}
				</Typography>
			</Box>
		</Tooltip>
	);
};

export default renderEvent;
