import {
	Alert,
	Button,
	Container,
	Grid,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { getElements } from "../../../API/API";
import SeccioItem from "./components/SeccioItem";
import ActivitatItem from "./components/ActivitatItem";
import CustomButton from "../../../components/elements/CustomButton";

// const activitats = [
// 	{
// 		id: 1,
// 		nom: "Lorem Ipsum Dolor",
// 		data: "2023-03-19",
// 		hora: "18:30 - 20:00 h",
// 		lloc: "Lorem ipsum",
// 		descripcio:
// 			"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt. ",
// 		seccio: {
// 			id: 11,
// 			nom: "Vòlei",
// 			color: "#d35f48",
// 		},
// 	},
// 	{
// 		id: 2,
// 		nom: "Lorem Ipsum Dolor",
// 		data: "2023-03-22",
// 		hora: "18:30 - 20:00 h",
// 		lloc: "Lorem ipsum",
// 		descripcio:
// 			"Lorem ipsum dolor sit amet, consectetuer adipiscing elit. ",
// 		seccio: {
// 			id: 5,
// 			nom: "Esquí Alpí",
// 			color: "#5f8fc1",
// 		},
// 	},
// 	{
// 		id: 3,
// 		nom: "Lorem Ipsum Dolor",
// 		data: "2023-03-23",
// 		hora: "18:30 - 20:00 h",
// 		lloc: "Lorem ipsum",
// 		descripcio:
// 			"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt. ",
// 		seccio: {
// 			id: 2,
// 			nom: "Cultura",
// 			color: "#e0b534",
// 		},
// 	},
// 	{
// 		id: 4,
// 		nom: "Lorem Ipsum Dolor",
// 		data: "2023-04-12",
// 		hora: "18:30 - 20:00 h",
// 		lloc: "Lorem ipsum",
// 		descripcio:
// 			"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt. ",
// 		seccio: {
// 			id: 4,
// 			nom: "Espeleologia",
// 			color: "#70685b",
// 		},
// 	},
// ];

export default function Activitats({ seccio_id, limit }) {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const [activitats, setActivitats] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements(
				"activitats?seccio_id=" + seccio_id + "&limit=" + limit
			);
			console.log(result);
			setActivitats(result);
			setLoading(false);
		};
		obtenir();
	}, [seccio_id]);

	return (
		<Box className={classes.portada}>
			<AnimationOnScroll animateIn="animate__fadeIn">
				<Container>
					<Box className={classes.main} py={18}>
						<Box
							display={"flex"}
							justifyContent="space-between"
							mb={4}
						>
							<Typography variant="h3">Activitats</Typography>
							<CustomButton
								dark="true"
								title={"Totes les activitats"}
								onClick={() => navigate("/activitats")}
							/>
						</Box>
						<Grid spacing={4} container mt={2}>
							{activitats?.length === 0 ? (
								<Box
									display="flex"
									justifyContent={"center"}
									flex={1}
								>
									<Alert severity="warning">
										No hi ha activitats programades
									</Alert>
								</Box>
							) : (
								activitats?.map((activitat) => (
									<ActivitatItem
										activitat={activitat}
										key={activitat?.id}
									/>
								))
							)}
						</Grid>
					</Box>
				</Container>
			</AnimationOnScroll>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	portada: {
		backgroundColor: "white",
	},
}));
