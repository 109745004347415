import React from "react";
import { Navigate } from "react-router-dom";
import AdminLayout from "./layouts/admin";
import PublicLayout from "./layouts/public";
import ActivitatAddEdit from "./views/admin/activitats/ActivitatAddEdit";
import ActivitatsAdmin from "./views/admin/activitats/ActivitatsAdmin";
import ActivitatView from "./views/admin/activitats/ActivitatView";
import CampsAdmin from "./views/admin/camps/CampsAdmin";
import HomeAdmin from "./views/admin/home/HomeAdmin";
import InscritsAdmin from "./views/admin/inscrits/InscritsAdmin";
import InscritView from "./views/admin/inscrits/InscritView";
import LoginView from "./views/admin/login/LoginView";
import SeccioAddEdit from "./views/admin/seccions/SeccioAddEdit";
import SeccionsAdmin from "./views/admin/seccions/SeccionsAdmin";
import ActivitatsCalendari from "./views/public/activitats/ActivitatsCalendari";
import ActivitatSingle from "./views/public/activitats/ActivitatSingle";
import Home from "./views/public/home/Home";
import Error from "./views/public/pagament/Error";
import Success from "./views/public/pagament/Success";
import QuiSom from "./views/public/qui-som/QuiSom";
import SeccioSingle from "./views/public/seccions/SeccioSingle";
import GaleriesAdmin from "./views/admin/galeries/GaleriesAdmin";
import GaleriaAddEdit from "./views/admin/galeries/GaleriaAddEdit";
import Contacte from "./views/public/contacte/Contacte";
import { Galeries } from "./views/public/galeries/Galeries";
import GaleriaSingle from "./views/public/galeries/GaleriaSingle";
import AvisLegal from "./views/public/legal/AvisLegal";
import PoliticaPrivacidad from "./views/public/legal/PoliticaPrivacitat";

const routes = (logged) => [
	{
		path: "/admin",
		element: logged ? <AdminLayout /> : <Navigate to="/login" />,
		children: [
			{
				path: "/admin/dash",
				element: <HomeAdmin />,
			},
			{
				path: "/admin/seccions",
				element: <SeccionsAdmin />,
			},
			{
				path: "/admin/seccions/add",
				element: <SeccioAddEdit />,
			},
			{
				path: "/admin/seccions/:slug",
				element: <SeccioAddEdit />,
			},
			{
				path: "/admin/activitats",
				element: <ActivitatsAdmin />,
			},
			{
				path: "/admin/activitats/add",
				element: <ActivitatAddEdit />,
			},
			{
				path: "/admin/activitats/:slug",
				element: <ActivitatAddEdit />,
			},
			{
				path: "/admin/activitats/view/:slug",
				element: <ActivitatView />,
			},
			{
				path: "/admin/camps",
				element: <CampsAdmin />,
			},
			{
				path: "/admin/inscrits",
				element: <InscritsAdmin />,
			},
			{
				path: "/admin/inscrits/:id",
				element: <InscritView />,
			},
			{
				path: "/admin/galeries",
				element: <GaleriesAdmin />,
			},
			{
				path: "/admin/galeries/add",
				element: <GaleriaAddEdit />,
			},
			{
				path: "/admin/galeries/add/:id",
				element: <GaleriaAddEdit />,
			},
		],
	},
	{
		path: "/",
		element: <PublicLayout />,
		children: [
			{
				path: "/login",
				element: !logged ? <LoginView /> : <Navigate to="/admin/dash" />,
			},
			{ path: "/", element: <Home /> },
			{ path: "/seccions/:slug", element: <SeccioSingle /> },
			{ path: "/activitats", element: <ActivitatsCalendari /> },
			{ path: "/activitats/:slug", element: <ActivitatSingle /> },
			{ path: "/qui-som", element: <QuiSom /> },
			{ path: "/contacte", element: <Contacte /> },
			{ path: "/galeries", element: <Galeries /> },
			{ path: "/galeries/:id", element: <GaleriaSingle /> },
			{ path: "/exit/:unique_id", element: <Success /> },
			{ path: "/error/:unique_id", element: <Error /> },
			{ path: "/avis-legal", element: <AvisLegal /> },
			{ path: "/politica-privacitat", element: <PoliticaPrivacidad /> },
		],
	},
];

export default routes;
