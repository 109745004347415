import { Box, Divider } from "@mui/material";
import { Bold, Strikethrough, Italic, List, ListOrdered, Heading2, Heading3, Heading4, Link2Off, Undo, Redo, Link2 } from "lucide-react";
import clsx from "clsx";
import { useCallback } from "react";
import theme from "../../../theme";

export const Toolbar = ({ editor, min }) => {
	const setLink = useCallback(() => {
		const previousUrl = editor.getAttributes("link").href;
		const url = window.prompt("URL", previousUrl);

		if (url === null) {
			return;
		}

		if (url === "") {
			editor.chain().focus().extendMarkRange("link").unsetLink().run();

			return;
		}

		editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
	}, [editor]);

	if (!editor) {
		return null;
	}

	return (
		<Box className="toolbar">
			<button
				onClick={(e) => {
					e.preventDefault();
					editor.chain().focus().toggleBold().run();
				}}
				className={clsx("button-rich", editor.isActive("bold") && "active")}
			>
				<Bold size={18} />
			</button>
			<button
				onClick={(e) => {
					e.preventDefault();
					editor.chain().focus().toggleItalic().run();
				}}
				className={clsx("button-rich", editor.isActive("italic") && "active")}
			>
				<Italic size={18} />
			</button>

			<button
				onClick={(e) => {
					e.preventDefault();
					editor.chain().focus().toggleStrike().run();
				}}
				className={clsx("button-rich", editor.isActive("strike") && "active")}
			>
				<Strikethrough size={18} />
			</button>

			<button
				onClick={(e) => {
					e.preventDefault();
					editor.chain().focus().toggleHeading({ level: 2 }).run();
				}}
				className={clsx("button-rich", editor.isActive("heading", { level: 2 }) && "active")}
			>
				<Heading2 size={18} />
			</button>
			{!min && (
				<button
					onClick={(e) => {
						e.preventDefault();
						editor.chain().focus().toggleHeading({ level: 3 }).run();
					}}
					className={clsx("button-rich", editor.isActive("heading", { level: 3 }) && "active")}
				>
					<Heading3 size={18} />
				</button>
			)}
			{!min && (
				<button
					onClick={(e) => {
						e.preventDefault();
						editor.chain().focus().toggleHeading({ level: 4 }).run();
					}}
					className={clsx("button-rich", editor.isActive("heading", { level: 4 }) && "active")}
				>
					<Heading4 size={18} />
				</button>
			)}

			<button
				onClick={(e) => {
					e.preventDefault();
					editor.chain().focus().toggleBulletList().run();
				}}
				className={clsx("button-rich", editor.isActive("bulletList") && "active")}
			>
				<List size={18} />
			</button>

			<button
				onClick={(e) => {
					e.preventDefault();
					editor.chain().focus().toggleOrderedList().run();
				}}
				className={clsx("button-rich", editor.isActive("orderedList") && "active")}
			>
				<ListOrdered size={18} />
			</button>

			<button onClick={setLink} className={clsx("button-rich", editor.isActive("orderedList") && "active")}>
				<Link2 size={18} />
			</button>
			<button onClick={() => editor.chain().focus().unsetLink().run()} disabled={!editor.isActive("link")} className={clsx("button-rich")}>
				<Link2Off size={18} />
			</button>
			<Divider orientation="vertical" style={{ borderColor: theme.palette.primary.main, margin: 5 }} />
			{!min && (
				<button
					onClick={(e) => {
						e.preventDefault();
						editor.chain().focus().undo().run();
					}}
					className={clsx("button-rich")}
				>
					<Undo size={18} />
				</button>
			)}
			{!min && (
				<button
					onClick={(e) => {
						e.preventDefault();
						editor.chain().focus().redo().run();
					}}
					className={clsx("button-rich")}
				>
					<Redo size={18} />
				</button>
			)}
		</Box>
	);
};
