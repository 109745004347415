import {
	Alert,
	AlertTitle,
	Collapse,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { addElement, esSoci, esSociNou, inscriure, inscriureUpdate } from "../../../../API/API";
import CustomButton from "../../../../components/elements/CustomButton";
import CustomCheckbox from "../../../../components/elements/CustomCheckbox";
import CustomSelect from "../../../../components/elements/CustomSelect";
import CustomTextField from "../../../../components/elements/CustomTextField";
import parse from "html-react-parser";
import LoadingOverlay from "../../../../components/layouts/LoadingOverlay";
import { Add, Delete } from "@mui/icons-material";
import ImageInput from "../../../../components/elements/ImageInput";
import { VariacioCheck } from "./VariacioCheck";
import { VariacioDisabled } from "./VariacioDisabled";

export default function FormulariInscripcio({ activitat, oberta }) {
	const { handleSubmit, register, control, setValue, getValues, trigger, watch } = useForm({ defaultValues: { variacions: [] } });
	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: "jugadors", // unique name for your Field Array
	});
	const [soci, setSoci] = useState(false);
	const [disabledVariacions, setDisabledVariacions] = useState([]);
	const [jaInscrit, setJaInscrit] = useState(null);
	const { enqueueSnackbar } = useSnackbar();
	const [formCompra, setForm] = useState();
	const [loading, setLoading] = useState(false);
	const [dadesSoci, setDadesSoci] = useState([]);

	useEffect(() => {
		setValue("preu", activitat.preu_no_soci);
	}, [activitat]);

	useEffect(() => {
		if (activitat?.camps?.some((i) => i.tipus === "persones")) {
			setValue("preu", soci ? activitat.preu_soci * watch("jugadors")?.length : activitat.preu_no_soci * watch("jugadors")?.length);
		} else if (activitat?.variacions?.length > 0) {
			if (Number(activitat?.multi)) {
				console.log(watch("variacions"));
				let preuAct = 0;
				if (watch("variacions")?.length > 0) {
					watch("variacions")?.map((v) => {
						if (soci) preuAct += v.preu_soci;
						else preuAct += v.preu_no_soci;
					});
				} else {
					preuAct = "";
				}
				setValue("preu", preuAct);
			} else {
				setValue(
					"preu",
					soci
						? activitat.variacions?.filter((i) => i.id === watch("variacio_id"))[0]?.preu_soci
						: activitat.variacions?.filter((i) => i.id === watch("variacio_id"))[0]?.preu_no_soci
				);
			}
		} else {
			setValue("preu", soci ? activitat.preu_soci : activitat.preu_no_soci);
		}
	}, [soci]);

	useEffect(() => {
		console.log(jaInscrit);
		if (jaInscrit) {
			setValue("nom", jaInscrit?.nom);
			setValue("cognoms", jaInscrit?.cognoms);
			setValue("email", jaInscrit?.email);
			setValue("id", jaInscrit?.id);
			activitat?.camps?.map((camp) => setValue(camp.slug, jaInscrit.camps?.find((i) => i.slug === camp.slug)?.pivot?.valor));
			setDisabledVariacions(jaInscrit?.variacions);
		}
	}, [jaInscrit]);

	useEffect(() => {
		const getSoci = async () => {
			if (watch("dni")?.length >= 9) {
				setLoading(true);
				const { resultat, success, inscrit } = await esSociNou(watch("dni"), activitat.id);
				Number(activitat.modificable) && setJaInscrit(inscrit);
				setDadesSoci(resultat);
				setSoci(success);
				setLoading(false);
			}
		};

		const subscription = watch((value, { name, type }) => {
			if (name === "dni") {
				if (activitat?.preu_soci || activitat?.variacions?.length > 0) getSoci();
			} else if (name === "jugadors") {
				setValue("preu", soci ? activitat.preu_soci * watch("jugadors")?.length : activitat.preu_no_soci * watch("jugadors")?.length);
			} else if (name === "variacio_id") {
				setValue(
					"preu",
					soci
						? activitat.variacions?.filter((i) => i.id === value.variacio_id)[0]?.preu_soci
						: activitat.variacions?.filter((i) => i.id === value.variacio_id)[0]?.preu_no_soci
				);
			} else if (name === "variacions") {
				let preuAct = 0;
				if (value?.variacions.length > 0) {
					value.variacions.map((v) => {
						if (soci) preuAct += Number(v.preu_soci);
						else preuAct += Number(v.preu_no_soci);
					});
				} else {
					preuAct = 0;
				}
				setValue("preu", preuAct);
				console.log(preuAct);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, soci]);

	const autocompletar = () => {
		setValue("nom", dadesSoci?.[0]?.persona?.nom);
		setValue("cognoms", dadesSoci?.[0]?.persona?.cognoms);
		setValue("email", dadesSoci?.[0]?.persona?.adreces?.[0]?.email);
	};

	const isButtonDisabled = () => {
		if (activitat.preu_soci !== null && soci) {
			return false;
		}
		if (activitat.preu_no_soci !== null) {
			return false;
		}
		if (activitat?.variacions?.length > 0 && (watch("variacio_id") || watch("variacions")) && watch("preu")) {
			return false;
		}
		return true;
	};

	const enviar = async (values) => {
		setLoading(true);
		// values.preu = soci ? activitat.preu_soci : activitat.preu_no_soci;
		// if (activitat?.camps?.some((i) => i.tipus === "persones")) {
		// 	values.preu = values.preu * watch("jugadors").length;
		// }
		values.activitat_id = activitat.id;

		const { form, message, type } = await (jaInscrit ? inscriureUpdate(values) : inscriure(values));

		if (form !== "true") {
			setForm(form);
			setTimeout(() => document.forms["redsys_form"].submit(), 500);
		} else {
			setLoading(false);
		}
		enqueueSnackbar(message, {
			variant: type,
		});
	};

	return (
		<Box my={10}>
			<Typography variant="h3" mb={5}>
				Formulari d'inscripció
			</Typography>
			{parse(formCompra ? formCompra : "")}
			<LoadingOverlay loading={loading}>
				{oberta ? (
					activitat?.max_inscrits === null || Number(activitat?.max_inscrits) > Number(activitat?.inscrits_count) ? (
						<form onSubmit={handleSubmit(enviar)}>
							<Collapse in={soci}>
								<CustomButton dark title={"Autocompletar dades soci"} onClick={autocompletar} />
							</Collapse>
							<Collapse in={jaInscrit}>
								<Alert>
									S'ha detectat una inscripció amb aquest DNI. Per aquesta activitat, pots afegir opcions i procedir al pagament de les
									seleccionades.
								</Alert>
							</Collapse>
							<Grid container spacing={3} mt={1}>
								<Grid item md={3} xs={12}>
									<CustomTextField register={register} name={"dni"} type={"text"} label="DNI" required inputProps={{ minLength: 9 }} />
									<Typography variant="caption">Omple per comprovar si ets soci</Typography>
								</Grid>
								<Grid item md={3} xs={12}>
									<CustomTextField
										register={register}
										name={"nom"}
										type={"text"}
										label="Nom"
										InputLabelProps={{
											shrink: watch("nom") ? true : false,
										}}
										required
										disabled={jaInscrit}
									/>
								</Grid>
								<Grid item md={3} xs={12}>
									<CustomTextField
										register={register}
										name={"cognoms"}
										type={"text"}
										label="Cognoms"
										InputLabelProps={{
											shrink: watch("cognoms") ? true : false,
										}}
										required
										disabled={jaInscrit}
									/>
								</Grid>
								<Grid item md={3} xs={12}>
									<CustomTextField
										register={register}
										name={"email"}
										type={"text"}
										label="E-mail"
										InputLabelProps={{
											shrink: watch("email") ? true : false,
										}}
										required
										disabled={jaInscrit}
									/>
								</Grid>

								<Divider />
								{activitat?.camps?.map((camp) => (
									<Grid item md={camp.tipus === "persones" ? 12 : 6} xs={12} mb={3}>
										{camp.tipus === "checkbox" ? (
											<Box display="flex" flexDirection={"column"}>
												<CustomCheckbox control={control} label={camp.nom} name={camp.slug} setValue={setValue} />
												<Typography variant="caption">{camp.text}</Typography>
											</Box>
										) : camp.tipus === "select" ? (
											<Box>
												<CustomSelect
													control={control}
													defaultValue=""
													register={register}
													name={camp.slug}
													label={camp.nom}
													list={camp.elements.split(",").map((i) => {
														return {
															id: i,
															nom: i,
														};
													})}
													required={camp.obligatori === 1 ? true : false}
													disabled={jaInscrit}
												/>
												<Typography variant="caption">{camp.text}</Typography>
											</Box>
										) : camp.tipus === "document" ? (
											<Box>
												<Typography>{watch(camp.slug)?.[0]?.name ?? "Selecciona un document"}</Typography>
												<ImageInput
													name={camp.slug}
													register={register}
													trigger={trigger}
													getValues={getValues}
													text={camp.nom}
													watch={watch}
													setValue={setValue}
													disabled={jaInscrit}
												/>
												<Typography variant="caption">{camp.text}</Typography>
											</Box>
										) : camp.tipus === "radio" ? (
											<Box>
												<Typography>{camp.nom}</Typography>
												<FormControl component="fieldset" fullWidth>
													<Controller
														control={control}
														name={camp.slug}
														disabled={jaInscrit}
														render={({ field }) => (
															<RadioGroup {...field} required={camp.obligatori === 1 ? true : false}>
																{camp.elements.split(",").map((i) => (
																	<FormControlLabel
																		value={i}
																		control={<Radio />}
																		label={i}
																		required={camp.obligatori === 1 ? true : false}
																	/>
																))}
															</RadioGroup>
														)}
													/>
												</FormControl>
												<Typography variant="caption">{camp.text}</Typography>
											</Box>
										) : camp.tipus === "persones" ? (
											<Box>
												<Box mb={2}>
													<Typography>{camp.nom}</Typography>
													<Typography variant="caption">{camp.text}</Typography>
												</Box>

												{fields.map((field, index) => (
													<Box p={2} border="1px solid lightgray" borderRadius={2} mb={3} key={field.id}>
														<Box display={"flex"} justifyContent={"space-between"}>
															<Typography mb={1}>Persona {index + 1}</Typography>
															<IconButton size="small" onClick={() => remove(index)}>
																<Delete />
															</IconButton>
														</Box>
														<Grid container spacing={3}>
															<Grid item lg={4} md={4} sm={12} xs={12}>
																<CustomTextField
																	register={register}
																	name={`jugadors.${index}.nom`}
																	label={"Nom i cognoms"}
																	required
																/>
															</Grid>
															<Grid item lg={4} md={4} sm={12} xs={12}>
																<CustomTextField register={register} name={`jugadors.${index}.dni`} label={"DNI"} required />
															</Grid>
															<Grid item lg={4} md={4} sm={12} xs={12}>
																<CustomTextField
																	register={register}
																	name={`jugadors.${index}.email`}
																	label={"E-mail"}
																	required
																/>
															</Grid>
															<Grid item lg={6} md={6} sm={12} xs={12}>
																<CustomTextField
																	register={register}
																	type="date"
																	name={`jugadors.${index}.datan`}
																	label={"Data naixement"}
																	InputLabelProps={{
																		shrink: true,
																	}}
																	required
																/>
															</Grid>
															<Grid item lg={6} md={6} sm={12} xs={12}>
																<CustomTextField
																	register={register}
																	name={`jugadors.${index}.talla`}
																	label={"Talla samarreta (12, 16, S, M, L, XL, 2XL)"}
																	required
																/>
															</Grid>
														</Grid>
													</Box>
												))}
												<CustomButton dark title={"Afegir persona"} onClick={() => append()} />
											</Box>
										) : (
											<Box>
												<CustomTextField
													register={register}
													name={camp.slug}
													type={camp.tipus}
													label={camp.nom}
													InputLabelProps={{
														shrink: true,
													}}
													disabled={jaInscrit}
													multiline={camp.tipus === "textarea"}
													rows={7}
													required={camp.obligatori === 1 ? true : false}
												/>
												<Typography variant="caption">{camp.text}</Typography>
											</Box>
										)}
									</Grid>
								))}
							</Grid>
							{activitat?.variacions?.length > 0 && (
								<Box>
									{Number(activitat?.multi) ? (
										<Box>
											<Typography mb={2} mt={2}>
												Selecciona les opcions que desitgi inscriure's
											</Typography>
											<Grid spacing={3} container mb={3}>
												{activitat?.variacions?.map((variacio) =>
													disabledVariacions?.some((d) => variacio?.id === d.id) ? (
														<VariacioDisabled key={variacio?.id} variacio={variacio} watch={watch} />
													) : (
														<VariacioCheck key={variacio?.id} variacio={variacio} setValue={setValue} watch={watch} />
													)
												)}
											</Grid>
										</Box>
									) : (
										<FormControl fullWidth>
											<InputLabel htmlFor="tipus">Selecciona</InputLabel>
											<Controller
												name={"variacio_id"}
												control={control}
												render={({ field: { onChange, value, ref } }) => (
													<Select onChange={onChange} name={"variacio_id"} label={"Selecciona"} value={value} ref={ref}>
														{activitat?.variacions?.map((item) => (
															<MenuItem
																key={item.id}
																value={item.id}
																disabled={item.limit ? Number(item.limit) <= Number(item.inscrits_count) : false}
															>
																{item.nom}{" "}
																{item.limit ? (Number(item.limit) <= Number(item.inscrits_count) ? "(Ple)" : "") : ""}
															</MenuItem>
														))}
													</Select>
												)}
											/>
										</FormControl>
									)}
								</Box>
							)}
							<CustomCheckbox
								control={control}
								label="He llegit i accepto la Política de Privacitat"
								name={"privacitat"}
								setValue={setValue}
								required
							/>
							<Box mt={3} display="flex" justifyContent={"space-between"}>
								<CustomButton
									title={"Enviar i pagar"}
									dark
									type="submit"
									loading={loading}
									disabled={isButtonDisabled() || (activitat?.camps?.some((i) => i.tipus === "persones") && watch("jugadors")?.length === 0)}
								/>
								<Box>
									<Typography variant="h4">Preu: {watch("preu")} €</Typography>
									{soci && <Typography variant="caption">Soci trobat. Preu soci</Typography>}
								</Box>
							</Box>
						</form>
					) : (
						<Alert severity="warning">
							<AlertTitle>Inscripcions plenes</AlertTitle>
							Les inscripcions per aquesta activitat estan plenes
						</Alert>
					)
				) : (
					<Alert severity="warning">
						<AlertTitle>Inscripcions tancades</AlertTitle>
						{activitat.inscripcio_inici && "Dates inscripcions: " + moment(activitat.inscripcio_inici).format("LL")}
						{activitat.inscripcio_final && " - " + moment(activitat.inscripcio_final).format("LL")}
					</Alert>
				)}
			</LoadingOverlay>
		</Box>
	);
}
