import { Check, Close, RemoveRedEye } from "@mui/icons-material";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";
import Estat from "../elements/Estat";

const InscritsMinColumns = (inscrits) => {
	console.log(inscrits);
	const columns = [
		{
			name: "unique_id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
				sortOrder: "desc",
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "cognoms",
			label: "Cognoms",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "dni",
			label: "DNI",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},

		{
			name: "soci",
			label: "Soci",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => (value ? <Check /> : <Close />),
			},
		},
		{
			name: "created_at",
			label: "Data inscripció",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY H:mm"),
			},
		},
		{
			name: "id",
			label: "Preu",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<Box>
							{inscrits?.[dataIndex]?.preu} € {inscrits?.[dataIndex]?.variacio ? <Chip label={inscrits?.[dataIndex]?.variacio?.nom} /> : ""}
						</Box>
					);
				},
			},
		},
	];
	return columns;
};

export default InscritsMinColumns;
