import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { getElements } from "../../../API/API";
import SeccioItem from "./components/SeccioItem";
import Loading from "../../../components/layouts/Loading";

function Seccions() {
	const classes = useStyles();
	const [seccions, setSeccions] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("seccions");
			setSeccions(result);
			setLoading(false);
		};
		obtenir();
	}, []);

	return (
		<Box className={classes.portada}>
			<Container>
				<AnimationOnScroll animateIn="animate__fadeIn">
					<Box className={classes.main} py={18}>
						<Loading loading={loading}>
							<Grid spacing={1} container>
								{seccions?.map((seccio) => (
									<SeccioItem
										seccio={seccio}
										key={seccio?.id}
									/>
								))}
							</Grid>
						</Loading>
					</Box>
				</AnimationOnScroll>
			</Container>
		</Box>
	);
}

export default Seccions;

const useStyles = makeStyles((theme) => ({
	portada: {
		borderTop: "1px solid white",
		backgroundColor: "white",
	},
	main: {
		borderBottom: "3px solid " + theme.palette.primary.main,
	},
}));
