import { RemoveRedEye } from "@mui/icons-material";
import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";
import Estat from "../elements/Estat";

const InscritsColumns = (inscrits) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "unique_id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
				sortOrder: "desc",
			},
		},
		{
			name: "estat",
			label: "Estat",
			options: {
				filter: true,
				sort: false,
				sortOrder: "desc",
				customBodyRender: (value) => <Estat estat={value} />,
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "cognoms",
			label: "Cognoms",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "email",
			label: "E-mail",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "dni",
			label: "DNI",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},

		{
			name: "activitat",
			label: "Activitat",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => value?.nom,
			},
		},
		{
			name: "id",
			label: "Preu",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<Box>
							{inscrits[dataIndex].preu} € {inscrits[dataIndex].variacio ? <Chip label={inscrits[dataIndex].variacio.nom} /> : ""}{" "}
							{inscrits[dataIndex].variacions ? inscrits[dataIndex].variacions.map((v) => <Chip label={v.nom} />) : ""}
						</Box>
					);
				},
			},
		},
		{
			name: "created_at",
			label: "Data inscripció",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY H:mm"),
			},
		},
	];
	return columns;
};

export default InscritsColumns;
