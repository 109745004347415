import { Box, Grid, Typography } from "@mui/material";
import InnerHTML from "dangerously-set-html-content";

export default function ActivitatInfo({ activitat }) {
	return (
		<Grid spacing={5} container mb={5}>
			<Grid item md={5} style={{ position: "relative" }}>
				<Box mt={2}>
					<img
						src={process.env.REACT_APP_STORAGE + activitat?.imatge}
						style={{
							width: "100%",
						}}
						alt={activitat?.nom}
					/>
				</Box>
			</Grid>
			<Grid item md={7}>
				<Typography variant="body1" mb={8}>
					<InnerHTML html={activitat?.descripcio} />
				</Typography>
				{activitat?.que_fem && (
					<Box>
						<Typography variant="h4" mb={2} color={activitat?.color}>
							Què fem?
						</Typography>
						<Typography variant="body1" mb={8}>
							<div
								dangerouslySetInnerHTML={{
									__html: activitat?.que_fem,
								}}
							/>
						</Typography>
					</Box>
				)}
				{activitat?.activitats_text && (
					<Box>
						<Typography variant="h4" mb={2} color={activitat?.color}>
							Activitats
						</Typography>
						<Typography variant="body1" mb={8}>
							<div
								dangerouslySetInnerHTML={{
									__html: activitat?.activitats_text,
								}}
							/>
						</Typography>
					</Box>
				)}
			</Grid>
		</Grid>
	);
}
