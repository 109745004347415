import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getElement } from "../../../API/API";
import { Header } from "../../../components/layouts/Header";
import Page from "../../../components/layouts/Page";
import FormulariInscripcio from "./components/FormulariInscripcio";
import ActivitatTaula from "./components/ActivitatTaula";
import ActivitatInfo from "./components/ActivitatInfo";
import Loading from "../../../components/layouts/Loading";
import { GaleriaItem } from "../galeries/GaleriaItem";

export default function ActivitatSingle() {
	const matches = useMediaQuery("(min-width:960px)");
	const [activitat, setActivitat] = useState("");
	const [oberta, setOberta] = useState(false);
	const [loading, setLoading] = useState(true);
	const { slug } = useParams();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElement("activitats", slug);
			setActivitat(result.activitat);
			setOberta(result.inscripcio_oberta);
			setLoading(false);
		};
		slug && obtenir();
	}, [slug]);

	return (
		<Page title={"Club Esquí Berguedà - " + activitat?.nom}>
			<Header title={activitat?.nom} color={activitat?.seccio?.color} activitat={activitat} loading={loading} />
			<Box style={{ backgroundColor: "white" }} py={10}>
				<Loading loading={loading} height="50vh">
					<Container maxWidth={"md"}>
						<ActivitatInfo activitat={activitat} />
						<ActivitatTaula activitat={activitat} />
						{Number(activitat.intern) === 1 && <FormulariInscripcio activitat={activitat} oberta={oberta} />}
					</Container>
					{activitat?.galeries?.length > 0 && (
						<Container maxWidth={"md"}>
							<Typography variant="h3" mb={8}>
								Galeries d'imatges
							</Typography>
							<Grid spacing={4} container>
								{activitat?.galeries?.map((galeria) => (
									<Grid item md={4} key={galeria?.id}>
										<GaleriaItem galeria={galeria} seccio={activitat?.seccio} />
									</Grid>
								))}
							</Grid>
						</Container>
					)}
				</Loading>
			</Box>
		</Page>
	);
}
