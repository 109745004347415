import {
	Button,
	Container,
	Grid,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { isDark } from "../../../../components/Utils";

export default function SeccioItem({ seccio }) {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles(seccio);
	const navigate = useNavigate();

	return (
		<Grid item md={3} xs={12}>
			<Box
				className={classes.seccio}
				onClick={() => navigate("/seccions/" + seccio?.slug)}
			>
				<Typography
					fontSize={25}
					fontWeight={700}
					mr={1}
					color={isDark(seccio.color) ? "white" : "black"}
					className={classes.text}
				>
					CEB
				</Typography>
				<Typography
					fontSize={19}
					textTransform="uppercase"
					color={isDark(seccio.color) ? "white" : "black"}
					className={classes.text}
				>
					{" "}
					| {seccio.nom}
				</Typography>
			</Box>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	seccio: {
		backgroundColor: (seccio) => seccio?.color,
		cursor: "pointer",
		transition: "0.2s",
		padding: 20,
		display: "flex",
		alignItems: "center",
		border: (seccio) => "1px solid " + seccio?.color,
		"&:hover": {
			"& .MuiTypography-root": {
				color: (seccio) => seccio?.color,
			},
			backgroundColor: "transparent",
		},
	},
	text: {
		transition: "0.2s",
	},
}));
