import { Chip } from "@mui/material";
import moment from "moment";

const GaleriesColumns = () => {
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
				sortOrder: "desc",
			},
		},

		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},

		{
			name: "activitat",
			label: "Activitat",
			options: {
				filter: false,
				sort: false,
				sortOrder: "desc",
				customBodyRender: (value) => <Chip label={value?.nom} variant="outlined" />,
			},
		},

		{
			name: "imatges_count",
			label: "Imatges",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},

		{
			name: "created_at",
			label: "Data creació",
			options: {
				filter: false,
				sort: true,
				width: 100,
				sortOrder: "desc",
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY"),
			},
		},
	];
	return columns;
};

export default GaleriesColumns;
