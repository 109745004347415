import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { updateElement } from "../../../../API/API";
import CustomButton from "../../../../components/elements/CustomButton";
import CustomCheckbox from "../../../../components/elements/CustomCheckbox";
import CustomSelect from "../../../../components/elements/CustomSelect";
import CustomTextField from "../../../../components/elements/CustomTextField";

export function DialogInscritInfo({ setOpen, open, inscrit, setUpdate }) {
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, control, setValue, reset, watch } =
		useForm();

	useEffect(() => {
		reset(inscrit);
		inscrit?.camps?.map((c) => {
			setValue(c.slug, c.pivot.valor);
		});
	}, [inscrit]);

	const enviar = async (values) => {
		console.log(values);
		setLoading(true);
		const { message, type } = await updateElement(
			"inscrit",
			inscrit.id,
			values
		);
		enqueueSnackbar(message, {
			variant: type,
		});
		setLoading(false);
		setOpen(false);
		setUpdate((prev) => prev + 1);
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableScrollLock
			maxWidth="md"
			fullWidth
		>
			<form onSubmit={handleSubmit(enviar)}>
				<DialogTitle>
					Dades inscrit <br />
					{inscrit?.nom + " " + inscrit?.cognoms}
					<small>{" ID: " + inscrit?.unique_id}</small>
				</DialogTitle>
				<DialogContent>
					<Grid spacing={3} container mt={1}>
						<Grid item md={4}>
							<CustomTextField
								register={register}
								name="nom"
								label="Nom"
							/>
						</Grid>
						<Grid item md={4}>
							<CustomTextField
								register={register}
								name="cognoms"
								label="Cognoms"
							/>
						</Grid>
						<Grid item md={4}>
							<CustomTextField
								register={register}
								name="dni"
								label="DNI"
							/>
						</Grid>
					</Grid>
					<Grid spacing={3} container mt={2}>
						{inscrit?.camps?.map((camp) => (
							<Grid item md={6} xs={12}>
								{camp.tipus === "checkbox" ? (
									<Box
										display="flex"
										flexDirection={"column"}
									>
										<CustomCheckbox
											control={control}
											label={camp.nom}
											name={camp.slug}
											setValue={setValue}
										/>
										<Typography variant="caption">
											{camp.text}
										</Typography>
									</Box>
								) : camp.tipus === "select" ? (
									<Box>
										<CustomSelect
											control={control}
											defaultValue=""
											register={register}
											name={camp.slug}
											label={camp.nom}
											list={camp.elements
												.split(",")
												.map((i) => {
													return { id: i, nom: i };
												})}
											required={
												camp.obligatori === 1
													? true
													: false
											}
										/>
										<Typography variant="caption">
											{camp.text}
										</Typography>
									</Box>
								) : (
									<Box>
										<CustomTextField
											register={register}
											name={camp.slug}
											type={camp.tipus}
											label={camp.nom}
											InputLabelProps={
												camp.tipus === "date" && {
													shrink: true,
												}
											}
											required={
												camp.obligatori === 1
													? true
													: false
											}
										/>
										<Typography variant="caption">
											{camp.text}
										</Typography>
									</Box>
								)}
							</Grid>
						))}
					</Grid>
				</DialogContent>
				<DialogActions>
					<CustomButton
						onClick={() => setOpen(false)}
						title="Tancar"
						fullWidth
						dark
					/>
					<CustomButton
						type="submit"
						title="Guardar"
						fullWidth
						success
						loading={loading}
					/>
				</DialogActions>
			</form>
		</Dialog>
	);
}
