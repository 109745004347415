import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1400,
			xl: 1920,
		},
	},
	palette: {
		background: {
			main: "#1b3b5f",
			secondary: "#1b3b5f",
			third: "#121314",
			light: "#dce1e4",
		},
		primary: {
			main: "#1b3b5f",
			light: "#1b3b5f40",
			hover: "#1b3b5f",
		},
		secondary: {
			main: "#FFF",
			hover: "#cacaca",
		},
		danger: {
			main: colors.red[500],
			light: colors.red[300],
		},
		success: {
			main: colors.green[500],
		},
		info: {
			main: "#FFF",
			hover: "#e32420",
		},
		error: {
			main: "#e32420",
		},
		text: {
			primary: "#192229",
			secondary: "#cacaca",
			third: "#0E3449",
		},
		typography: {
			fontFamily: "Rubik",
		},
	},
	typography: {
		h1: {
			fontSize: "4.5rem",
			fontFamily: "Rubik",
			color: "#192229",
			fontWeight: 700,
			lineHeight: 0.9,
			[breakpoints.down("lg")]: {
				fontSize: "3rem",
			},
			[breakpoints.down("sm")]: {
				fontSize: "2rem",
			},
			textTransform: "uppercase",
		},
		h2: {
			fontSize: "3rem",
			fontFamily: "Rubik",
			color: "#192229",
			fontWeight: 700,
			lineHeight: 0.9,
			[breakpoints.down("sm")]: {
				fontSize: "2rem",
			},
			[breakpoints.down("lg")]: {
				fontSize: "2rem",
			},
			textTransform: "uppercase",
		},
		h3: {
			fontSize: "2rem",
			fontFamily: "Rubik",
			textTransform: "uppercase",
			fontWeight: 700,
			color: "#192229",
			[breakpoints.down("sm")]: {
				fontSize: "1.2rem",
			},
		},
		h4: {
			fontSize: "1.4rem",
			fontFamily: "Rubik",
			textTransform: "uppercase",
			fontWeight: 700,
			color: "#192229",
			[breakpoints.down("sm")]: {
				fontSize: "1.2rem",
			},
		},
		h5: {
			fontSize: "1.2rem",
			textTransform: "uppercase",
			fontFamily: "Rubik",
			fontWeight: 800,
			color: "#0E3449",
		},
		h6: {
			fontSize: "1rem",
			textTransform: "uppercase",
			fontFamily: "Rubik",
			fontWeight: 800,
			color: "#0E3449",
			[breakpoints.down("sm")]: {
				fontSize: "0.8rem",
			},
		},
		body1: {
			fontSize: "0.9rem",
			fontFamily: "Rubik",
			lineHeight: 1.3,
			color: "#192229",
			fontWeight: 300,
			[breakpoints.down("lg")]: {
				fontSize: "0.9rem",
			},
			[breakpoints.down("sm")]: {
				fontSize: "0.8rem",
			},
		},
		body2: {
			fontFamily: "Rubik",
			fontSize: "0.9rem",
			fontWeight: 300,
			lineHeight: 1.3,
			paddingBottom: 20,
		},
		caption: {
			fontFamily: "Rubik",
			color: "#192229",
			fontSize: 12,
		},
		root: {
			"& a": {
				color: "#192229",
				textDecoration: "none",
				transition: "0.5s",
				"&:hover": {
					color: "black",
				},
			},
		},
		error: {
			fontFamily: "Rubik",
			color: "#d32f2f",
			fontSize: "0.9rem",
		},
		title: {
			fontFamily: "Rubik",
			fontSize: "3.5rem",
			color: "#192229",
		},
		link: {
			transition: "0.2s",
			fontSize: "0.8rem",
			fontFamily: "Rubik",
			color: "#FFF",
			textTransform: "uppercase",
		},
		footer: {
			fontSize: "0.9rem",
			fontFamily: "Rubik",
			color: "white",
		},
		menu: {
			fontFamily: "Rubik",
			fontSize: 18,
			textDecoration: "none",
		},
	},
	"& a": {
		color: "black",
	},
});

export default theme;
