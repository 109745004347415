import React from "react";
import { Avatar, Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Facebook, Instagram, LinkedIn, YouTube } from "@mui/icons-material";
import { Container } from "@mui/system";
import { useNavigate } from "react-router";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";

const Footer = () => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const navigate = useNavigate();

	return (
		<Box className={classes.root} displayPrint="none">
			<Container maxWidth="lg">
				<Grid container spacing={3} className={classes.main}>
					<Grid item md={4} xs={12} textAlign={matches ? "left" : "center"}>
						<Typography variant="h3" color="white">
							CEB
						</Typography>
						<Typography color="white">
							El Club Esquí Berguedà (CEB) és una entitat berguedana, fundada el 1928, sense ànim de lucre, amb seu a Berga, que compta amb més de
							1.000 socis i sòcies provinents de tota la comarca.
						</Typography>
					</Grid>
					<Grid item md={4} xs={12} textAlign={"center"}>
						<Logo style={{ width: 150 }} />
					</Grid>
					<Grid item md={4} xs={12} textAlign={matches ? "right" : "center"}>
						<Box mt={2} display="flex" flexDirection={"column"}>
							<Box
								onClick={() => navigate("/avis-legal")}
								style={{
									textDecoration: "none",
									cursor: "pointer",
								}}
							>
								<Typography variant="footer">Avís Legal</Typography>
							</Box>

							<Box
								onClick={() => navigate("/politica-privacitat")}
								style={{
									textDecoration: "none",
									cursor: "pointer",
								}}
							>
								<Typography variant="footer">Política de Privacitat</Typography>
							</Box>
							<Stack direction={"row"} spacing={2} justifyContent={matches ? "flex-end" : "center"} mt={1}>
								<a href="https://www.facebook.com/" target={"_blank"} rel="noreferrer">
									<Facebook color="info" />
								</a>
								<a href="https://www.instagram.com/ceb_berga/" target={"_blank"} rel="noreferrer">
									<Instagram color="info" />
								</a>

								<a href="https://www.youtube.com/@clubesquibergueda" target={"_blank"} rel="noreferrer">
									<YouTube color="info" />
								</a>
							</Stack>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;

const useStyles = makeStyles((theme) => ({
	root: {
		borderTop: "1px solid " + theme.palette.secondary.main,
		backgroundSize: "contain",
		backgroundAttachment: "fixed",
	},
	main: {
		paddingTop: 50,
		paddingBottom: 50,
		display: "flex",
		alignItems: "center",
	},
	header: {
		textAlign: "center",
	},
	logo: {
		fill: theme.palette.background.main,
	},
	icon: {
		width: 60,
		height: 60,
		border: "1px solid " + theme.palette.background.main,
		borderRadius: 50,
		padding: 10,
		color: theme.palette.background.main,
	},
	iconSVG: {
		width: 25,
		height: 25,
		border: "1px solid " + theme.palette.background.main,
		borderRadius: 50,
		padding: 10,
		fill: theme.palette.background.main,
		fontSize: 20,
	},
	iconWrap: {
		"&.MuiButtonBase-root": {
			borderRadius: 50,
		},
	},
}));
