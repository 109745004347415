import { useEffect, useState } from "react";

export default function useScrollY() {
	const [scrollY, setScrollY] = useState(window.scrollY);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	return scrollY;
}
