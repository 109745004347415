import React, { useEffect, useState } from "react";
import {
	NavLink as RouterLink,
	useLocation,
	useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import navBarStyle from "./NavBarStyle";
import { Typography, Link as LinkTo, Box, Menu, MenuItem } from "@mui/material";
import { getElements } from "../../API/API";
import { styled } from "@mui/styles";

const NavBarItemMobile = ({
	className,
	to,
	title,
	closeMenu,
	dropdown,
	...rest
}) => {
	const classes = navBarStyle();
	const [anchorEl, setAnchorEl] = useState(null);
	const [seccions, setSeccions] = useState([]);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const location = useLocation();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("seccions");
			setSeccions(result);
		};
		dropdown && obtenir();
	}, []);
	const onClick = () => {
		closeMenu();
	};
	return !dropdown ? (
		<LinkTo
			className={classes.buttonMobile}
			to={{
				pathname: to,
			}}
			underline="none"
			component={RouterLink}
			onClick={onClick}
		>
			<Typography variant="body1">{title}</Typography>
		</LinkTo>
	) : (
		<div>
			<Box
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				className={classes.buttonMobile}
			>
				<Typography variant="body1">{title}</Typography>
			</Box>
			<StyledMenu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				transformOrigin={{ vertical: "top", horizontal: "center" }}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{seccions?.map((item) => (
					<MenuItem
						onClick={() => {
							navigate("/seccions/" + item.slug);
							handleClose();
							onClick();
						}}
						style={
							location.pathname === "/seccions/" + item.slug
								? {
										border: "1px solid " + item.color,
										backgroundColor: item.color,
										color: "white",
								  }
								: {
										border: "1px solid " + item.color,
										color: item.color,
								  }
						}
					>
						{item.nom}
					</MenuItem>
				))}
			</StyledMenu>
		</div>
	);
};

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(
	({ theme }) => ({
		"& .MuiPaper-root": {
			borderRadius: 0,
			color:
				theme.palette.mode === "light"
					? "rgb(55, 65, 81)"
					: theme.palette.grey[300],
			boxShadow:
				"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
			"& .MuiMenu-list": {
				padding: 0,
			},
			"& .MuiMenuItem-root": {
				padding: 5,
				fontSize: "0.7rem",
				textTransform: "uppercase",
			},
		},
	})
);

NavBarItemMobile.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string,
	icon: PropTypes.elementType,
	title: PropTypes.string,
};

export default NavBarItemMobile;
