import {
	Button,
	Container,
	Grid,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { isDark } from "../../../../components/Utils";

export default function ActivitatItem({ activitat }) {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles(activitat);
	const navigate = useNavigate();

	return (
		<Grid
			item
			md={3}
			xs={12}
			onClick={() => navigate("/activitats/" + activitat?.slug)}
		>
			<Box className={classes.activitat}>
				<Typography
					fontSize={25}
					fontWeight={700}
					mb={1}
					className={classes.text}
				>
					{activitat.nom}
				</Typography>
				<Typography
					fontSize={15}
					mb={4}
					className={classes.text}
					textTransform="uppercase"
				>
					{activitat?.seccio?.nom}
				</Typography>
				<Typography
					fontSize={19}
					mb={2}
					fontWeight={400}
					textTransform="uppercase"
					className={classes.text2}
				>
					{moment(
						activitat.dates && JSON.parse(activitat.dates)[0],
						"DD/MM/YYYY"
					).format("LL")}
				</Typography>
				<Typography fontSize={15} className={classes.text2}>
					<div
						dangerouslySetInnerHTML={{
							__html:
								activitat?.descripcio.substring(0, 200) + "...",
						}}
					/>
				</Typography>
				{/* <Typography variant="body1" mb={8}>
					Quan:{" "}
					<div
						dangerouslySetInnerHTML={{
							__html: activitat.quan,
						}}
					/>
				</Typography>
				<Typography
					fontSize={15}
					color={isDark(activitat?.seccio.color) ? "white" : "black"}
					className={classes.text}
				>
					Quan: {activitat.quan}
				</Typography>
				<Typography
					fontSize={15}
					color={isDark(activitat?.seccio.color) ? "white" : "black"}
					className={classes.text}
				>
					Lloc: {activitat?.on}
				</Typography> */}
				{/* <Typography
					mt={3}
					fontSize={15}
					color={isDark(activitat?.seccio.color) ? "white" : "black"}
					className={classes.text}
				>
					{activitat?.descripcio}
				</Typography> */}
			</Box>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	activitat: {
		backgroundColor: "transparent",
		cursor: "pointer",
		transition: "0.2s",
		padding: 20,
		border: (activitat) => "1px solid " + activitat?.seccio?.color,
		"& .MuiTypography-root": {
			color: (activitat) => activitat?.seccio?.color,
		},
		"&:hover": {
			"& .MuiTypography-root": {
				color: (activitat) =>
					isDark(activitat?.seccio?.color) ? "white" : "black",
			},
			backgroundColor: (activitat) => activitat?.seccio?.color,
		},
	},
	text: {
		transition: "0.2s",
	},
}));
