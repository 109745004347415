import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import img from "../../../assets/img/portada.jpg";
import useScrollY from "../../../components/elements/useScrollY";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

function Portada() {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const navigate = useNavigate();
	const scrollY = useScrollY();

	return (
		<Box
			className={classes.portada}
			style={{
				backgroundImage: `url(${img})`,
			}}
		>
			<AnimationOnScroll animateIn="animate__fadeIn">
				<Logo
					style={{
						width: matches ? 300 : 200,
						marginTop: scrollY / 2,
					}}
				/>
			</AnimationOnScroll>
		</Box>
	);
}

export default Portada;

const useStyles = makeStyles((theme) => ({
	portada: {
		height: "100vh",
		backgroundColor: theme.palette.background.main,
		marginTop: 40,
		backgroundPosition: "center",
		backgroundSize: "cover",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	logo: {
		position: "absolute",
		zIndex: 100,
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	overlay: {
		position: "absolute",
		height: "100vh",
		width: "100%",
		backgroundColor: theme.palette.background.main,
		zIndex: 10,
	},
	element: {
		display: "flex",
		cursor: "pointer",
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		backgroundSize: "cover",
		backgroundPosition: "center",
		position: "relative",
		"&:hover": {
			"& .MuiBox-root": {
				opacity: 0,
			},
			"& .MuiTypography-root": {
				transform: "scale(1.1)",
			},
		},
	},
	overlayImg: {
		transition: "0.2s",
		position: "absolute",
		width: "100%",
		height: "100%",
		backgroundColor: "black",
		top: 0,
		left: 0,
		opacity: 0.3,
		zIndex: 0,
	},
}));
