import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "./CustomButton";

const Dada = ({ nom, valor, v, tipus }) => {
	return (
		<Box display={!v && "flex"} justifyContent="space-between" alignItems="center" mb={3}>
			<Typography fontWeight={800}>{nom}</Typography>
			<Typography textAlign={!v && "right"}>
				{tipus === "persones" ? (
					JSON.parse(valor).map((i) => (
						<Box border="1px solid lightgray" p={1} my={2} borderRadius={2}>
							<Typography fontWeight={700}>{i.nom}</Typography>
							<Typography>{i.email}</Typography>
							<Typography>{i.dni}</Typography>
							<Typography>{i.datan}</Typography>
							<Typography>Talla: {i.talla}</Typography>
						</Box>
					))
				) : tipus === "document" ? (
					<Box>
						<CustomButton dark small onClick={() => window.open(process.env.REACT_APP_STORAGE + valor)} title="Veure document" />
					</Box>
				) : (
					valor
				)}
			</Typography>
		</Box>
	);
};

export default Dada;
