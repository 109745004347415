import axios from "axios";

const url = process.env.REACT_APP_URL;
// const url = "https://api.clubesquibergueda.cat/public/api/";

const Login = async (email, password) => {
	let message;
	await axios
		.post(url + "login", {
			email: email,
			password: password,
			remember_me: true,
		})
		.then((response) => {
			if (response.status === 200) {
				localStorage.setItem("isLoggedIn", true);
				localStorage.setItem("user", JSON.stringify(response.data));
				message = "Login amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "error";
			}
		})
		.catch((error) => {
			message = "error";
			console.log(error);
		});

	return message;
};

const getUser = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "user", config)
		.then((response) => {
			if (response.status === 200) {
				message = response.status;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
			}
		});
	return { message };
};

const logout = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let get_user;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "logout", config)
		.then((response) => {
			if (response.status === 200) {
				get_user = response.data;
				message = "400";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.setItem("isLoggedIn", false);
			}
		});
	return { get_user, message };
};

const getElements = async (element) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getElement = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const updateElement = async (element, key, values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let data;
	let type;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + element + "/" + key, values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				data = response.data.data;
				type = "success";
			} else {
				message = response.data.message;
				type = "error";
			}
		})
		.catch((error) => {
			console.log(error);
			message = error.response.data.message;
			type = "error";
			if (error.response.status === 413) {
				message = "Imatge massa gran. Màxim 2Mb";
			}
		});
	return { message, data, type };
};

const addElement = async (element, values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let type;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + element, values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				type = response.data.success ? "success" : "error";
				console.log(response);
			}
		})
		.catch((error) => {
			console.log(error);
			message = error.response.data.message;
			type = "error";
		});
	return { message, type };
};

const inscriure = async (values) => {
	let message;
	let form;
	let type;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.post(url + "inscrit", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				form = response.data.data;
				type = "success";
			}
		})
		.catch((error) => {
			message = error.response.data.message;
			form = "true";
			type = "error";
		});
	return { form, message, type };
};

const inscriureUpdate = async (values) => {
	let message;
	let form;
	let type;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.post(url + "inscritUpdate", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				form = response.data.data;
				type = "success";
			}
		})
		.catch((error) => {
			message = error.response.data.message;
			form = "true";
			type = "error";
		});
	return { form, message, type };
};

const inscriureAdmin = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let form;
	let type;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "inscritAdmin", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				form = response.data.data;
				type = "success";
			}
		})
		.catch((error) => {
			message = error.response.data.message;
			form = "true";
			type = "error";
		});
	return { form, message, type };
};

const deleteElement = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let type;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.delete(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				type = "success";
			}
		})
		.catch((error) => {
			message = error.response.data.message;
			type = "error";
		});
	return { message, type };
};

const enviarContacte = async (values) => {
	let message;
	let code;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.post(url + "contacte", values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.success;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message, code };
};

const changeState = async (id, value) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "changeState/" + id, { estat_id: value }, config)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				message = response.data.message;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const devolucio = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let resultat;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "retornar/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				resultat = response.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { resultat, message };
};

const publishActivitat = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let type;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "activitats/publish/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
				type = "success";
			}
		})
		.catch((error) => {
			console.log(error);
			message = error.response.data.message;
			type = "error";
		});
	return { message, type };
};

const download = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data;
				message = "Document creat";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const esSoci = async (dni) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let success;
	let resultat;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "soci/" + dni, config)
		.then((response) => {
			if (response.status === 200) {
				resultat = response.data.data;
				success = response.data.success;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				success = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { resultat, success };
};

const esSociNou = async (dni, activitat) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let success;
	let resultat;
	let inscrit;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "sociNou/" + dni + "/" + activitat, config)
		.then((response) => {
			if (response.status === 200) {
				resultat = response.data.data;
				inscrit = response.data.inscrit;
				success = response.data.success;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				success = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { resultat, success, inscrit };
};

export {
	Login,
	getUser,
	logout,
	getElements,
	getElement,
	updateElement,
	addElement,
	deleteElement,
	enviarContacte,
	inscriure,
	changeState,
	devolucio,
	publishActivitat,
	download,
	esSoci,
	esSociNou,
	inscriureAdmin,
	inscriureUpdate,
};
