import { Add, Delete, PanTool } from "@mui/icons-material";
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/system";
import React from "react";

function CustomCard({ title, children, button, onClick, addOn, edit, sel }) {
	const classes = useStyles();

	return (
		<Box className={classes.card}>
			{title && (
				<>
					<Box
						p={3}
						display="flex"
						justifyContent={"space-between"}
						alignItems="center"
					>
						<Typography variant="h5">{title}</Typography>
						{button && (
							<IconButton onClick={onClick}>
								<Add />
							</IconButton>
						)}
						{addOn ?? ""}
					</Box>
					<Divider />
				</>
			)}
			<Box p={3}>{children}</Box>
			{edit && (
				<Box className={classes.overlay}>
					<Select size="small" value={sel}>
						<MenuItem value={1} selected>
							Estat Tasques
						</MenuItem>
						<MenuItem value={2}>Estats per mes</MenuItem>
						<MenuItem value={3}>Productes actius</MenuItem>
						<MenuItem value={4}>Tipus de producte</MenuItem>
						<MenuItem value={5}>Top clients</MenuItem>
						<MenuItem value={6}>Número clients</MenuItem>
					</Select>
				</Box>
			)}
			{edit && (
				<Stack className={classes.stack} direction={"row"} spacing={3}>
					<Box className={classes.moveIcon}>
						<IconButton>
							<Delete />
						</IconButton>
					</Box>
					<Box className={classes.moveIcon}>
						<IconButton>
							<PanTool />
						</IconButton>
					</Box>
				</Stack>
			)}
		</Box>
	);
}

export default CustomCard;

const useStyles = makeStyles((theme) => ({
	card: {
		boxShadow: "#00000020 1px 3px 20px 1px ",
		padding: 0,
		borderRadius: 30,
		marginBottom: 40,
		position: "relative",
	},
	overlay: {
		top: 0,
		width: "100%",
		height: "100%",
		position: "absolute",
		borderRadius: 10,
		backgroundColor: "#FFFFFFE9",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	moveIcon: {
		backgroundColor: "#FFFFFF",
		borderRadius: 40,
		boxShadow: "0 0 20px 0px #00000090",
	},
	stack: {
		position: "absolute",
		top: -20,
		right: -20,
	},
}));
