import React, { useEffect, useState } from "react";
import PageAdmin from "../../../components/layouts/PageAdmin";
import { Add, CalendarMonth, Circle } from "@mui/icons-material";
import { Chip, Fab, Grid, ThemeProvider, Tooltip } from "@mui/material";
import getMuiTheme from "../../../theme/TableStyle";
import MUIDataTable from "mui-datatables";
import Loading from "../../../components/layouts/Loading";
import { getElements } from "../../../API/API";
import TableOptions from "../../../components/columns/TableOptions";
import { useNavigate } from "react-router";
import ActivitatsColumns from "../../../components/columns/ActivitatsColumns";
import CustomCard from "../../../components/layouts/CustomCard";

export default function ActivitatsAdmin() {
	const [loading, setLoading] = useState(true);
	const [activitats, setActivitats] = useState([]);
	const [seccions, setSeccions] = useState([]);
	const [filter, setFilter] = useState(
		JSON.parse(localStorage.getItem("filterAdmin")) ?? []
	);
	const [filteredActivitats, setFilteredActivitats] = useState([]);
	const columns = ActivitatsColumns(activitats);
	const options = TableOptions("activitats/view");
	const navigate = useNavigate();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("activitatsAdmin");
			const seccions = await getElements("seccions");
			setSeccions(seccions.result);
			setFilteredActivitats(result);
			setActivitats(result);
			setLoading(false);
		};
		obtenir();
	}, []);

	useEffect(() => {
		localStorage.setItem("filterAdmin", JSON.stringify(filter));
		if (filter.length > 0) {
			setFilteredActivitats(
				activitats.filter((item) =>
					filter.some((i) => i === item.seccio.id)
				)
			);
		} else {
			setFilteredActivitats(activitats);
		}
	}, [activitats, filter]);

	const addFilter = async (item) => {
		if (filter?.some((i) => i === item)) {
			setFilter(filter?.filter((i) => i !== item));
		} else {
			setFilter([...filter, item]);
		}
	};

	return (
		<PageAdmin
			title="Activitats"
			Icon={CalendarMonth}
			button={
				<Tooltip title="Afegir activitat">
					<Fab
						size="small"
						onClick={() => navigate("/admin/activitats/add")}
					>
						<Add />
					</Fab>
				</Tooltip>
			}
		>
			<Loading loading={loading}>
				<CustomCard>
					<Grid container>
						{seccions?.map((item) => (
							<SeccioFilter
								seccio={item}
								addFilter={addFilter}
								filter={filter}
							/>
						))}
					</Grid>
					<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							data={filteredActivitats}
							columns={columns}
							options={options}
						/>
					</ThemeProvider>
				</CustomCard>
			</Loading>
		</PageAdmin>
	);
}

const SeccioFilter = ({ seccio, addFilter, filter }) => {
	return (
		<Chip
			onClick={() => addFilter(seccio.id)}
			icon={
				<Circle
					style={{
						fill: seccio?.color,
					}}
				/>
			}
			label={seccio?.nom}
			variant="outlined"
			style={{
				marginRight: 5,
				backgroundColor: filter?.some((i) => i === seccio.id)
					? seccio?.color
					: "transparent",
				color: filter?.some((i) => i === seccio.id)
					? "white"
					: seccio?.color,
			}}
		/>
	);
};
