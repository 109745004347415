import { Checkbox, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

export function VariacioCheck({ variacio, setValue, watch }) {
	const classes = useStyles();

	const addVariacio = () => {
		if (watch("variacions")?.some((i) => i.id === variacio.id)) {
			setValue(
				"variacions",
				watch("variacions")?.filter((i) => i.id !== variacio.id)
			);
		} else {
			setValue("variacions", [...watch("variacions"), variacio]);
		}
	};

	return (
		<Grid item md={4}>
			<Box className={classes.variacio} onClick={() => addVariacio()}>
				<Box>
					<Typography>{variacio.nom}</Typography>
					<Typography variant="caption">
						{variacio?.preu_soci && variacio?.preu_soci + " € (soci) "}
						<br />
						{variacio?.preu_no_soci && variacio?.preu_no_soci + " € (no soci)"}
					</Typography>
				</Box>
				<Box display={"flex"} alignItems={"center"}>
					<Typography variant="caption">
						{watch("camps")?.map((i, index) => {
							if (i?.id === variacio?.id) return index + 1;
						})}
					</Typography>
					<Checkbox checked={watch("variacions")?.some((i) => i?.id === variacio?.id)} />
				</Box>
			</Box>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	variacio: {
		padding: "10px 25px",
		borderRadius: 40,
		backgroundColor: theme.palette.background.light,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		cursor: "pointer",
	},
}));
