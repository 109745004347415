import React, { useEffect, useState } from "react";
import PageAdmin from "../../../components/layouts/PageAdmin";
import { Add, Apps } from "@mui/icons-material";
import { Fab, ThemeProvider, Tooltip } from "@mui/material";
import getMuiTheme from "../../../theme/TableStyle";
import MUIDataTable from "mui-datatables";
import Loading from "../../../components/layouts/Loading";
import { getElements } from "../../../API/API";
import TableOptions from "../../../components/columns/TableOptions";
import { useNavigate } from "react-router";
import SeccionsColumns from "../../../components/columns/SeccionsColumns";
import CustomCard from "../../../components/layouts/CustomCard";

export default function SeccionsAdmin() {
	const [loading, setLoading] = useState(true);
	const [seccions, setSeccions] = useState([]);
	const columns = SeccionsColumns(seccions);
	const options = TableOptions("seccions");
	const navigate = useNavigate();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("seccions");
			setSeccions(result);
			setLoading(false);
		};
		obtenir();
	}, []);

	return (
		<PageAdmin
			title="Seccions"
			Icon={Apps}
			button={
				<Tooltip title="Afegir secció">
					<Fab
						size="small"
						onClick={() => navigate("/admin/seccions/add")}
					>
						<Add />
					</Fab>
				</Tooltip>
			}
		>
			<CustomCard>
				<Loading loading={loading}>
					<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							data={seccions}
							columns={columns}
							options={options}
						/>
					</ThemeProvider>
				</Loading>
			</CustomCard>
		</PageAdmin>
	);
}
