const isDark = (c) => {
	var c1 = c?.substring(1); // strip #
	var rgb = parseInt(c1, 16); // convert rrggbb to decimal
	var r = (rgb >> 16) & 0xff; // extract red
	var g = (rgb >> 8) & 0xff; // extract green
	var b = (rgb >> 0) & 0xff; // extract blue

	var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

	return luma < 150;
};

export { isDark };
