import React, { useEffect, useState } from "react";
import PageAdmin from "../../../components/layouts/PageAdmin";
import { Add, EditAttributes } from "@mui/icons-material";
import { Fab, Grid, Tooltip } from "@mui/material";
import Loading from "../../../components/layouts/Loading";
import { getElements } from "../../../API/API";
import { DialogAddCamp } from "./DialogAddCamp";
import { CampCheck } from "./CampCheck";

export default function CampsAdmin() {
	const [loading, setLoading] = useState(true);
	const [camps, setCamps] = useState([]);
	const [open, setOpen] = useState(false);
	const [camp, setCamp] = useState("");
	const [update, setUpdate] = useState(0);

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("camps");
			setCamps(result);
			setLoading(false);
		};
		obtenir();
	}, [update]);

	return (
		<PageAdmin
			title="Camps per el formulari d'inscripcions"
			Icon={EditAttributes}
			button={
				<Tooltip title="Afegir camp">
					<Fab
						size="small"
						onClick={() => {
							setOpen(true);
							setCamp("");
						}}
					>
						<Add />
					</Fab>
				</Tooltip>
			}
		>
			<Loading loading={loading}>
				<Grid spacing={3} container mt={4}>
					{camps?.map((camp) => (
						<CampCheck
							key={camp?.id}
							camp={camp}
							fixe
							edit
							setOpen={setOpen}
							setCamp={setCamp}
						/>
					))}
				</Grid>
			</Loading>
			<DialogAddCamp
				open={open}
				setOpen={setOpen}
				setUpdate={setUpdate}
				camp={camp}
			/>
		</PageAdmin>
	);
}
