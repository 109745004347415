import React, { useEffect, useState } from "react";
import PageAdmin from "../../../components/layouts/PageAdmin";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import Group from "@mui/icons-material/Group";
import { Grid } from "@mui/material";
import Loading from "../../../components/layouts/Loading";
import { deleteElement, devolucio, getElement } from "../../../API/API";
import { useNavigate, useParams } from "react-router";
import CustomCard from "../../../components/layouts/CustomCard";
import Thumb from "../../../components/elements/Thumb";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { DialogInscritInfo } from "../activitats/components/DialogInscritInfo";
import CustomButton from "../../../components/elements/CustomButton";
import ActivitatDades from "../activitats/components/ActivitatDades";
import Estat from "../../../components/elements/Estat";
import Dada from "../../../components/elements/Dada";
import { DialogEliminar } from "../../../components/layouts/DialogEliminar";
import parse from "html-react-parser";

export default function InscritView() {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [inscrit, setInscrit] = useState("");
	const [openDelete, setOpenDelete] = useState(false);
	const [form, setForm] = useState("");
	const [update, setUpdate] = useState(0);

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElement("inscrit", id);
			setInscrit(result);
			setLoading(false);
		};
		obtenir();
	}, [id, update]);

	const eliminar = async () => {
		const { message, type } = await deleteElement("inscrit", id);
		enqueueSnackbar(message, {
			variant: type,
		});
		setOpenDelete(false);
		type === "success" && navigate("/admin/inscrits");
	};

	const dev = async () => {
		const { resultat } = await devolucio(inscrit?.unique_id);

		setForm(resultat);
		setTimeout(() => document.forms["redsys_form"].submit(), 500);
	};

	return (
		<PageAdmin title={"Detall inscrit"} Icon={Group} button={<CustomButton onClick={() => setOpen(true)} title="Editar" dark />}>
			{parse(form ? form.data : "")}
			<Loading loading={loading}>
				<Grid spacing={3} container>
					<Grid item md={8} xl={8}>
						<CustomCard title={"Informació"}>
							<Grid spacing={3} container>
								<Grid item md={6} xl={6}>
									<Dada nom="ID" valor={inscrit?.unique_id} v />
									<Dada nom="Nom" valor={inscrit?.nom + " " + inscrit?.cognoms} v />
									<Dada nom="E-mail" valor={inscrit.email} v />
									<Dada nom="DNI" valor={inscrit.dni} v />
									<Dada nom="Preu" valor={inscrit?.preu + " €"} v />
									<Dada nom="Soci" valor={inscrit.soci ? <Check /> : <Close />} v />
									<Dada nom="Estat" v valor={<Estat change estat={inscrit?.estat} id={inscrit?.unique_id} />} />
								</Grid>
								<Grid item md={6} xl={6}>
									{inscrit?.camps?.map((camp) => (
										<Dada nom={camp?.nom} valor={camp?.pivot?.valor} v tipus={camp?.tipus} />
									))}
									{inscrit?.variacio && <Dada nom="Variació pagament" valor={inscrit?.variacio?.nom} v />}
									{inscrit?.variacions?.length > 0 && (
										<Dada nom="Variació pagament" valor={inscrit?.variacions?.map((v) => v.nom + ", ")} v />
									)}
								</Grid>
							</Grid>
						</CustomCard>
					</Grid>
					<Grid item md={4} xl={4}>
						<CustomCard title={"Activitat"}>
							<Thumb file={inscrit?.activitat?.imatge} />
							<Box my={4} />
							<ActivitatDades activitat={inscrit?.activitat} />
							<CustomButton
								title={"Veure activitat"}
								onClick={() => navigate("/admin/activitats/view/" + inscrit?.activitat?.slug)}
								dark
								fullWidth
							/>
						</CustomCard>

						<CustomCard title="Accions">
							<CustomButton title={"Devolució"} dark fullWidth onClick={dev} />
							<CustomButton title={"Eliminar"} danger fullWidth onClick={() => setOpenDelete(true)} />
						</CustomCard>
					</Grid>
				</Grid>
			</Loading>
			<DialogInscritInfo setOpen={setOpen} open={open} inscrit={inscrit} setUpdate={setUpdate} />
			<DialogEliminar
				element={"inscritr"}
				onClick={eliminar}
				open={openDelete}
				setOpen={setOpenDelete}
				text="Si s'elimina l'inscrit, no es farà la devolució"
			/>
		</PageAdmin>
	);
}

const useStyles = makeStyles((theme) => ({
	calendari: {
		"& .rmdp-wrapper": {
			width: "100% !important",
		},
		"& span": {
			fontFamily: "Rubik",
		},
		"& div": {
			fontFamily: "Rubik",
		},
		"& .rmdp-panel": {
			width: "100% !important",
		},
	},
}));
