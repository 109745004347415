import * as yup from "yup";

const schemaSeccio = yup.object({
	nom: yup.string().required("Nom obligatori"),
	descripcio: yup.string().required("Descripció obligaròria"),
	nom_contacte: yup.string().required("Nom obligatori"),
	email_contacte: yup.string().required("E-mail obligatori"),
	telefon_contacte: yup.string().required("Telèfon de contacte obligatori"),
});

const schemaActivitat = yup.object({
	nom: yup.string().required("Nom obligatori"),
	descripcio: yup.string().required("Descripció obligaròria"),
	dates: yup.array().required("Dates obligatòries").min(1),
	seccio_id: yup.string().required("Secció obligatòria"),
});

export { schemaSeccio, schemaActivitat };
