import {
	Apps,
	Circle,
	PrecisionManufacturing,
	RemoveRedEye,
} from "@mui/icons-material";
import { Avatar, IconButton } from "@mui/material";
import { useNavigate } from "react-router";

const SeccionsColumns = (seccions) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "slug",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
				sortOrder: "desc",
			},
		},
		{
			name: "imatge",
			label: "Imatge",
			options: {
				width: 50,
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => (
					<Avatar
						src={process.env.REACT_APP_STORAGE + value}
						variant="rounded"
						children={<Apps />}
					/>
				),
			},
		},

		{
			name: "color",
			label: "Color",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => <Circle style={{ fill: value }} />,
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "nom_contacte",
			label: "Persona contacte",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "email_contacte",
			label: "E-mail contacte",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},

		// {
		// 	name: "id",
		// 	label: "Accions",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		empty: true,
		// 		customBodyRenderLite: (dataIndex) => {
		// 			return (
		// 				<IconButton
		// 					onClick={() =>
		// 						navigate(
		// 							`/admin/seccions/${seccions[dataIndex].slug}`
		// 						)
		// 					}
		// 				>
		// 					<RemoveRedEye />
		// 				</IconButton>
		// 			);
		// 		},
		// 	},
		// },
	];
	return columns;
};

export default SeccionsColumns;
