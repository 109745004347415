import * as React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const Boto = styled(Box)(({ theme }) => ({
	padding: "8px 20px",
	fontSize: 13,
	borderRadius: 10,
	lineHeight: 1.5,
	color: "white",
	backgroundColor: theme.palette.primary.main,
	border: "2px solid" + theme.palette.primary.main,
	fontFamily: "Open Sans",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	cursor: "pointer",
	"& svg": {
		transition: "0.2s",
	},
	"& .MuiTypography-root": {
		transition: "0.2s",
		color: "white",
		fontSize: 13,
	},
	"&:hover": {
		border: "2px solid" + theme.palette.primary.main,
		backgroundColor: theme.palette.secondary.main,
		"& .MuiTypography-root": {
			color: "black",
		},
		"& svg": {
			color: "black",
		},
	},
}));

export default function ImageInput({ errors, name, register, trigger, multiple, text, setValue, watch }) {
	const [errorMsg, setErrorMsg] = React.useState("");

	const validateSelectedFile = (file) => {
		const MAX_FILE_SIZE = 8192; // 8MB

		file.map((f) => {
			const fileSizeKiloBytes = f?.size / 1024;

			if (fileSizeKiloBytes > MAX_FILE_SIZE) {
				setErrorMsg("El document supera els 8MB. Penja un document de menys de 8MB.");
				setValue(name, watch(name));
				return;
			} else {
				setErrorMsg("");
			}
		});

		trigger(name);
	};

	return (
		<Box my={2.5} width={"100%"}>
			<Box mb={1} display="flex" justifyContent="space-between" width={"100%"}>
				<Box>
					<label htmlFor={name} style={{ width: "100%" }}>
						<input
							id={name}
							name={name}
							type="file"
							multiple={multiple}
							{...register(name, {
								onChange: () => {
									trigger(name);
									validateSelectedFile([...watch(name)]);
								},
							})}
							style={{
								display: "none",
							}}
						/>
						<Boto
							display="flex"
							alignItems={"center"}
							style={{
								cursor: "pointer",
							}}
						>
							<Add size={15} />
							<Typography
								style={{
									fontSize: 15,
								}}
							>
								{" "}
								{text}
							</Typography>
						</Boto>
					</label>
				</Box>
			</Box>

			<Typography variant="error">{errorMsg}</Typography>
			<Typography variant="error">{errors?.message}</Typography>
		</Box>
	);
}
