import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Page from "../../../components/layouts/Page";
import { makeStyles } from "@mui/styles";
import TitlePublic from "../../../components/elements/TitlePublic";

function PoliticaPrivacidad() {
	const classes = useStyles();
	return (
		<Page title="Política de Privacidad">
			<Box className={classes.portada}>
				<Container>
					<TitlePublic title="Política de Privacidad" />
					<Box>
						{/* --------- */}
						<Typography mt={3} color="secondary" variant="h2">
							INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS
						</Typography>
						<Typography color="secondary">
							El presente texto contiene la política de privacidad (en adelante, la “Política de Privacidad”) aplicada a sus datos personales en
							el presente sitio web www.clubesquibergueda.cat (en adelante, la “Web”), en cumplimiento de la normativa sobre protección de la
							privacidad, y en concreto, de conformidad, así como otras normas análogas o complementarias.
						</Typography>
						<Typography color="secondary">
							Esta compañía está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información
							personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este
							documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y
							enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.
						</Typography>

						{/* --------- */}
						<Typography mt={3} color="secondary" variant="h2">
							DATOS IDENTIFICATIVOS
						</Typography>
						<Typography color="secondary">Responsable del Tratamiento de datos: Club Esquí Berguedà</Typography>

						{/* --------- */}
						<Typography mt={3} color="secondary" variant="h2">
							FINALIDAD DEL TRATAMIENTO DE LOS DATOS
						</Typography>
						<Typography color="secondary">
							La Web recoge datos personales del usuario, bien recogidos automáticamente durante el uso de la Web, o proporcionados
							voluntariamente por el propio usuario en los apartados informados de la Web. Todos los datos personales recogidos, los incorporamos
							a ficheros responsabilidad del Titular de la Web. Dichos datos serán tratados con las finalidades siguientes:
						</Typography>
						<Typography color="secondary">
							La base jurídica para el tratamiento de sus datos es el consentimiento que usted nos facilita al ponerse en contacto con nosotros y
							facilitarnos sus datos personales.
						</Typography>
						<Typography color="secondary">
							Antes de que nos facilite datos personales de terceros, debe haber recabado previamente su consentimiento respecto al contenido de
							la Política de Privacidad de clubesquibergueda.cat, Club Esquí Berguedà
						</Typography>

						{/* --------- */}
						<Typography mt={3} color="secondary" variant="h2">
							ENLACES A TERCEROS
						</Typography>
						<Typography color="secondary">
							Este sitio web pudiera contener en laces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y
							abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables de los
							términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias
							políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.
						</Typography>

						{/* --------- */}
						<Typography mt={3} color="secondary" variant="h2">
							SEGURIDAD Y CUMPLIMIENTO
						</Typography>
						<Typography color="secondary">
							Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento.
						</Typography>

						{/* --------- */}
						<Typography mt={3} color="secondary" variant="h2">
							POLÍTICA DE RESERVAS Y PAGOS
						</Typography>
						<Typography color="secondary">Nuestras tarifas, visibles en el apartado de reservas, tiene el IVA incluido.</Typography>
						<Typography color="secondary">
							Para una reserva con éxito el usuario debe efectuar a través de nuestro TPV Virtual el pago de la reserva.
						</Typography>
						<Typography color="secondary">
							Solo serán reservables aquellas sesiones que permanecen disponibles. Cuando una de nuestras sesiones es reservada, esta se bloquea
							automáticamente y no podrá ser reservada por ningún otro equipo.
						</Typography>
						<Typography color="secondary">
							Usted es el único responsable de introducir sus datos correctamente en el formulario de reserva online.
						</Typography>
						<Typography color="secondary">
							La reserva se convierte en nula y la entrada será rechazada si el equipo no se presenta o llega 30 minutos o más tarde. En tal
							situación, debido al carácter de la reserva, el dinero no es reembolsable y la reserva no podrá ser cambiada.
						</Typography>
						<Typography color="secondary">
							En el caso de que el TPV Virtual informara de la denegación de la tarjeta, se cancelará automáticamente el pedido.
						</Typography>
						<Typography color="secondary">
							Si menos personas de las que se indicaron en la reserva asisten al juego no se le reembolsará la diferencia de precio.
						</Typography>
						<Typography color="secondary">
							Si más personas asisten el juego que han reservado (hasta 6 personas una habitación) el extra se deberá pagar en efectivo una vez en
							nuestras instalaciones.
						</Typography>

						{/* --------- */}
						<Typography mt={3} color="secondary" variant="h2">
							POLÍTICA DE CANCELACIONES Y CAMBIOS
						</Typography>
						<Typography color="secondary">
							Se harán cambios hasta 4 días antes de la reserva, a 4 días de la reserva no se admiten cancelaciones, ni devoluciones, ni cambios.
						</Typography>
					</Box>
				</Container>
			</Box>
		</Page>
	);
}

export default PoliticaPrivacidad;

const useStyles = makeStyles((theme) => ({
	portada: {
		paddingTop: 200,
		height: "100%",
		backgroundAttachment: "fixed",
		[theme.breakpoints.down("sm")]: {
			paddingTop: 100,
		},
		paddingBottom: 200,
	},
	"@global": {
		html: {
			height: "100%",
		},
	},
}));
