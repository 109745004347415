import { ThemeProvider } from "@mui/styles";
import React, { useEffect, useState } from "react";
import PageAdmin from "../../../components/layouts/PageAdmin";
import { Add, Apps, Circle } from "@mui/icons-material";
import { Button, Fab, Grid, TextField, Tooltip, Typography } from "@mui/material";
import Loading from "../../../components/layouts/Loading";
import { addElement, getElement, getElements, updateElement } from "../../../API/API";
import { useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import CustomTextField from "../../../components/elements/CustomTextField";
import CustomRichTextTiny from "../../../components/elements/CustomRichTextTiny";
import CustomCard from "../../../components/layouts/CustomCard";
import Thumb from "../../../components/elements/Thumb";
import ImageInput from "../../../components/elements/ImageInput";
import { useSnackbar } from "notistack";
import { SketchPicker, MaterialPicker, ChromePicker } from "react-color";
import CustomButton from "../../../components/elements/CustomButton";
import { Box } from "@mui/system";
import { constructFormSeccio } from "../../../API/ConstructForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaSeccio } from "../../../components/elements/Schemas";

export default function SeccioAddEdit() {
	const [loading, setLoading] = useState(false);
	const [seccio, setSeccio] = useState([]);
	const navigate = useNavigate();
	const { slug } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const [color, setColor] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		trigger,
		getValues,
		setValue,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(schemaSeccio),
		mode: "onChange",
		// defaultValues: seccio,
	});

	useEffect(() => {
		console.log(errors);
	}, [errors]);

	useEffect(() => {
		const obtenir = async () => {
			setLoading(true);
			const { result } = await getElement("seccions", slug);
			setSeccio(result);
			reset(result);
			setLoading(false);
		};
		slug && obtenir();
	}, [slug]);

	const enviar = async (values) => {
		console.log(values);
		if (slug) {
			const { message, data, type } = await updateElement("seccions", slug, constructFormSeccio(values));
			enqueueSnackbar(message, {
				variant: type,
			});

			type === "success" && navigate("/admin/seccions");
		} else {
			const { message, type } = await addElement("seccions", constructFormSeccio(values));
			enqueueSnackbar(message, {
				variant: type,
			});

			type === "success" && navigate("/admin/seccions");
		}
	};

	return (
		<form onSubmit={handleSubmit(enviar)}>
			<PageAdmin
				title={slug ? "Editar secció" : "Afegir secció"}
				Icon={Apps}
				button={
					<Button title="Guardar" dark type="submit" color="success" variant="contained">
						Guardar
					</Button>
				}
			>
				<Loading loading={loading}>
					<Grid spacing={3} container>
						<Grid item md={8}>
							<CustomCard title={"Dades obligatòries"}>
								<Grid container spacing={3}>
									<Grid item md={8}>
										<CustomTextField register={register} name="nom" label="Nom secció" errors={errors.nom} />
									</Grid>
									<Grid item md={4}>
										<Box display="flex" alignItems={"center"} mt={1}>
											<Circle
												style={{
													fill: watch("color"),
													width: 50,
												}}
											/>
											<CustomButton onClick={() => setColor(true)} title="Tria color" dark />
											{color ? (
												<div
													style={{
														position: "absolute",
														zIndex: "2",
													}}
												>
													<div
														style={{
															position: "fixed",
															top: "0px",
															right: "0px",
															bottom: "0px",
															left: "0px",
														}}
														onClick={() => setColor(false)}
													/>
													<ChromePicker color={watch("color")} onChangeComplete={(value) => setValue("color", value.hex)} />
												</div>
											) : null}
										</Box>
									</Grid>
								</Grid>

								<CustomRichTextTiny
									watch={watch}
									register={register}
									getValues={getValues}
									label={"Descripció"}
									name="descripcio"
									setValue={setValue}
									errors={errors.descripcio}
								/>
							</CustomCard>
							<CustomCard title={"Opcional"}>
								<Grid spacing={2} container>
									<Grid item md={6}>
										<CustomRichTextTiny
											watch={watch}
											register={register}
											getValues={getValues}
											label={"Què fem?"}
											name="que_fem"
											setValue={setValue}
										/>
									</Grid>
									<Grid item md={6}>
										<CustomRichTextTiny
											watch={watch}
											register={register}
											getValues={getValues}
											label={"Text activitats"}
											name="activitats_text"
											setValue={setValue}
										/>
									</Grid>
								</Grid>
							</CustomCard>
						</Grid>
						<Grid item md={4}>
							<CustomCard title={"Imatge"}>
								<Typography variant="h6">Imatge principal</Typography>
								<Thumb file={getValues("imatge")} />

								<ImageInput
									name="imatge"
									register={register}
									trigger={trigger}
									getValues={getValues}
									text={"Afegir imatge principal"}
									watch={watch}
									setValue={setValue}
								/>
							</CustomCard>
							<CustomCard title={"Dades de contacte"}>
								<CustomTextField register={register} name="nom_contacte" label="Nom contacte" errors={errors.nom_contacte} />
								<CustomTextField register={register} name="email_contacte" label="E-mail contacte" mt={3} errors={errors.email_contacte} />
								<CustomTextField register={register} name="telefon_contacte" label="Telèfon contacte" mt={3} errors={errors.telefon_contacte} />
								<CustomTextField register={register} name="instagram" label="Instagram de la secció" mt={3} errors={errors.instagram} />
							</CustomCard>
						</Grid>
					</Grid>
				</Loading>
			</PageAdmin>
		</form>
	);
}
