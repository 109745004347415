import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import theme from "../../theme";

const BootstrapButton = styled(Button)({
	boxShadow: "none",
	textTransform: "uppercase",
	fontSize: 15,
	padding: "10px 20px",
	borderRadius: 0,
	lineHeight: 1,
	background: theme.palette.background.secondary,
	border: "1px solid white ",
	"&:hover": {
		backgroundColor: "white",
		color: theme.palette.background.secondary,
	},
	"&:active": {
		boxShadow: "none",
		backgroundColor: theme.palette.background.main,
		borderColor: theme.palette.background.main,
	},
	"&:focus": {
		boxShadow: "0 0 0 0.2rem " + theme.palette.background.main,
	},
});

const CustomButtonPublic = ({ title, danger, loading, fullWidth, success, Icon, ...rest }) => {
	return (
		<BootstrapButton
			style={{
				width: fullWidth ? "100%" : "auto",
			}}
			{...rest}
			variant="contained"
		>
			{Icon && <Icon style={{ marginRight: 10 }} />}
			{loading ? <CircularProgress size={18} color="info" /> : title}
		</BootstrapButton>
	);
};

CustomButtonPublic.propTypes = {
	danger: PropTypes.bool,
	loading: PropTypes.bool,
	title: PropTypes.string,
};

export default CustomButtonPublic;
