import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useNavigate } from "react-router";
import { isDark } from "../../../components/Utils";
import { Link } from "@mui/icons-material";

export function GaleriaItem({ galeria, index, seccio }) {
	const classes = useStyles(seccio);
	const navigate = useNavigate();

	return (
		<Box pb={3} mb={0} onClick={() => (galeria?.url ? window.open(galeria.url) : navigate("/galeries/" + galeria?.id))}>
			<AnimationOnScroll animateIn="animate__fadeIn" delay={(index % 3) * 100} style={{ display: "flex" }}>
				<Box className={classes.cat}>
					<Box className={classes.info} px={3} pt={3} pb={2}>
						<Box>
							<Typography variant="h4">{galeria.nom}</Typography>
							<Typography variant="caption">
								{galeria?.activitat?.nom} ({galeria?.activitat?.seccio?.nom})
							</Typography>
						</Box>
						{galeria?.url && <Link style={{ color: galeria?.activitat?.seccio?.color }} />}
					</Box>
					<Box style={{ width: "100%", height: 250 }}>
						<Avatar
							variant="square"
							src={process.env.REACT_APP_STORAGE + galeria?.imatges?.[0]?.foto}
							style={{ width: "100%", height: "100%", objectFit: "contain" }}
							alt={galeria?.nom}
						/>
					</Box>
				</Box>
			</AnimationOnScroll>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	cat: {
		cursor: "pointer",
		transition: "0.2s",
		display: "flex",
		width: "100%",
		flexDirection: "column",
		backgroundColor: "white",
		justifyContent: "space-between",
		border: (seccio) => "1px solid " + seccio?.color,
		"& .MuiTypography-root": {
			color: (seccio) => seccio?.color,
		},
		"&:hover": {
			"& .MuiTypography-root": {
				color: (seccio) => (isDark(seccio?.color) ? "white" : "black"),
			},
			backgroundColor: (seccio) => seccio?.color,
		},
	},
	info: {
		transition: "0.2s",
		display: "flex",
		justifyContent: "space-between",
	},
}));
