import { Checkbox, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

export function VariacioDisabled({ variacio, watch }) {
	const classes = useStyles();

	return (
		<Grid item md={4}>
			<Box className={classes.variacio}>
				<Box>
					<Typography>{variacio.nom}</Typography>
					<Typography variant="caption">
						<Typography variant="caption">Comprada</Typography>
						<br />
						<Typography variant="caption">Opció ja adquirida prèviament</Typography>
					</Typography>
				</Box>
				<Box display={"flex"} alignItems={"center"}>
					<Typography variant="caption">
						{watch("camps")?.map((i, index) => {
							if (i?.id === variacio?.id) return index + 1;
						})}
					</Typography>
					<Checkbox checked />
				</Box>
			</Box>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	variacio: {
		padding: "10px 25px",
		borderRadius: 40,
		backgroundColor: theme.palette.background.light,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		cursor: "pointer",
	},
}));
