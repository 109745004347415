import { makeStyles, ThemeProvider } from "@mui/styles";
import React, { useEffect, useState } from "react";
import PageAdmin from "../../../components/layouts/PageAdmin";
import "moment/locale/ca";
import "moment/min/moment-with-locales";
import {
	Apps,
	CalendarMonth,
	Dashboard,
	Group,
	Newspaper,
	PrecisionManufacturing,
} from "@mui/icons-material";
import { getElements } from "../../../API/API";
import Loading from "../../../components/layouts/Loading";
import { Box } from "@mui/system";
import { Fab, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";
import getMuiTheme from "../../../theme/TableStyle";
import MUIDataTable from "mui-datatables";
import ActivitatsColumns from "../../../components/columns/ActivitatsColumns";
import TableOptions from "../../../components/columns/TableOptions";
import CustomCard from "../../../components/layouts/CustomCard";
import InscritsMinColumns from "../../../components/columns/InscritsMinColumns";

function HomeAdmin() {
	const classes = useStyles();
	const [activitats, setActivitats] = useState([]);
	const [inscrits, setInscrits] = useState([]);
	const [counts, setCounts] = useState([]);
	const [loading, setLoading] = useState(true);
	const columns = ActivitatsColumns(activitats);
	const columnsInscrits = InscritsMinColumns(inscrits);
	const optionsActivitats = TableOptions("activitats/view");
	const optionsInscrits = TableOptions("inscrits");
	const navigate = useNavigate();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("dashboard");
			console.log(result);
			setActivitats(result.activitats);
			setInscrits(result.inscrits);
			setCounts(result.counts);
			setLoading(false);
		};
		obtenir();
	}, []);

	return (
		<PageAdmin title="Dashboard" Icon={Dashboard}>
			<Loading loading={loading}>
				<Grid container spacing={2} mt={1}>
					<Grid item md={4} xs={12}>
						<Box className={classes.count}>
							<Box>
								<Typography variant="h4">Seccions</Typography>
								<Typography variant="h3" fontWeight={800}>
									{counts?.seccions}
								</Typography>
							</Box>
							<Fab
								color="primary"
								onClick={() => navigate("/admin/seccions")}
							>
								<Apps />
							</Fab>
						</Box>
					</Grid>
					<Grid item md={4} xs={12}>
						<Box className={classes.count}>
							<Box>
								<Typography variant="h4">Activitats</Typography>
								<Typography variant="h3" fontWeight={800}>
									{counts?.activitats}
								</Typography>
							</Box>
							<Fab
								color="warning"
								onClick={() => navigate("/admin/activitats")}
							>
								<CalendarMonth />
							</Fab>
						</Box>
					</Grid>
					<Grid item md={4} xs={12}>
						<Box className={classes.count}>
							<Box>
								<Typography variant="h4">Inscrits</Typography>
								<Typography variant="h3" fontWeight={800}>
									{counts?.inscrits}
								</Typography>
							</Box>
							<Fab
								color="success"
								onClick={() => navigate("/admin/inscrits")}
							>
								<Group />
							</Fab>
						</Box>
					</Grid>
				</Grid>
				<Grid spacing={3} container mt={2}>
					<Grid item md={6}>
						<CustomCard title={"Properes activitats"}>
							<ThemeProvider theme={getMuiTheme()}>
								<MUIDataTable
									data={activitats}
									columns={columns}
									options={optionsActivitats}
								/>
							</ThemeProvider>
						</CustomCard>
					</Grid>
					<Grid item md={6}>
						<CustomCard title={"Últims inscrits"}>
							<ThemeProvider theme={getMuiTheme()}>
								<MUIDataTable
									data={inscrits}
									columns={columnsInscrits}
									options={optionsInscrits}
								/>
							</ThemeProvider>
						</CustomCard>
					</Grid>
				</Grid>
			</Loading>
		</PageAdmin>
	);
}

export default HomeAdmin;

const useStyles = makeStyles((theme) => ({
	count: {
		padding: 20,
		backgroundColor: "white",
		borderRadius: 30,
		boxShadow: "#00000020 1px 3px 20px 1px ",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
}));
