import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import theme from "../../theme";

const BootstrapButton = styled(Button)((prop) => ({
	boxShadow: "none",
	textTransform: "none",
	fontSize: prop.small ? 14 : 16,
	borderRadius: 0,
	lineHeight: 1,
	color: prop.dark ? "white" : prop.success ? "white" : prop.danger ? "white" : theme.palette.primary.main,
	fontFamily: "Rubik",
	fontWeight: 200,
	background: prop.dark ? theme.palette.primary.main : prop.success ? "green" : prop.danger ? "#d32f2f" : "white",
	border: "1px solid white",
	width: 200,
	"&:hover": {
		border: prop.dark ? "1px solid " + theme.palette.primary.main : prop.danger ? "1px solid #d32f2f" : "1px solid white",
		// border: "1px solid white",
		color: prop.dark ? theme.palette.primary.main : prop.success ? "green" : prop.danger ? "#d32f2f" : "white",
		boxShadow: "none",
		background: "transparent",
	},
	"&:active": {
		boxShadow: "none",
		backgroundColor: theme.palette.background.main,
		borderColor: theme.palette.background.main,
	},
	"&:focus": {
		boxShadow: "none",
	},
}));

const CustomButton = ({ title, dark, loading, fullWidth, success, danger, small, ...rest }) => {
	return (
		<BootstrapButton
			style={{
				width: fullWidth ? "100%" : "auto",
				padding: small ? "5px 10px" : "10px 20px",
			}}
			small={small}
			dark={dark}
			success={success}
			danger={danger}
			{...rest}
			variant="contained"
		>
			{loading ? <CircularProgress size={18} color="info" /> : title}
		</BootstrapButton>
	);
};

CustomButton.propTypes = {
	dark: PropTypes.bool,
	loading: PropTypes.bool,
	title: PropTypes.string,
};

export default CustomButton;
