import { Box, CircularProgress, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	loading: {
		position: "absolute",
		top: 40,
		bottom: 0,
		left: 0,
		right: 0,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 0,
	},
}));

export default function LoadingOverlay({ loading, children }) {
	const classes = useStyles();
	return (
		<Box position="relative">
			<div style={{ opacity: loading ? 0.4 : 1, transition: ".2s" }}>
				{children}
			</div>
			{loading && (
				<Box className={classes.loading}>
					<Fade in={loading}>
						<CircularProgress color="primary" />
					</Fade>
				</Box>
			)}
		</Box>
	);
}
