import { Container, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { enviarContacte } from "../../../API/API";
import Page from "../../../components/layouts/Page";
import Loading from "../../../components/layouts/Loading";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import CustomTextField from "../../../components/elements/CustomTextField";
import { AccessTime, Home, Mail, Phone } from "@mui/icons-material";
import { Header } from "../../../components/layouts/Header";
import CustomButtonPublic from "../../../components/elements/CustomButtonPublic";

function Contacte() {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { register, reset, handleSubmit } = useForm();

	const enviar = async (values) => {
		setLoading(true);
		const { message } = await enviarContacte(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		reset();
		setLoading(false);
	};

	return (
		<Page title={"Contacte"}>
			<Header title={"Contacte"} overlay img />
			<Box py={15} style={{ backgroundColor: "white" }}>
				<Container>
					<Loading loading={loading}>
						<Grid spacing={10} container>
							<Grid item md={6} xs={12}>
								<Typography variant="h2">Contacte</Typography>
								<form onSubmit={handleSubmit(enviar)}>
									<CustomTextField register={register} label={"Nom"} name="nom" mt={2} required />
									<CustomTextField register={register} label={"Telèfon"} name="telefon" mt={2} />
									<CustomTextField register={register} label="E-mail" name="email" mt={2} required />
									<CustomTextField register={register} label={"Missatge"} name="missatge" mt={2} required rows={8} multiline />
									<Box my={3} />
									<CustomButtonPublic fullWidth type="submit" title={"Enviar"} />
								</form>
							</Grid>
							<Grid item md={6} xs={12}>
								<Typography variant="h2" mb={3}>
									Club Esquí Berguedà
								</Typography>
								<Divider />
								<Box display="flex" alignItems={"center"} mt={3}>
									<Home width={25} />
									<a
										href="https://www.google.com/maps/place/BERMAQ%C2%AE/@42.0872306,1.8270362,17z/data=!3m1!4b1!4m5!3m4!1s0x12a5a78a41a7e7c3:0x91ad542a3656c9d1!8m2!3d42.0872306!4d1.8292249"
										target="_blank"
										rel="noreferrer"
										style={{ textDecoration: "none" }}
									>
										<Typography ml={3}>Carrer Pío Baroja, 6, baixos - 08600 Berga (Barcelona)</Typography>
									</a>
								</Box>
								<Box display="flex" alignItems={"center"} mt={3}>
									<Phone width={30} />
									<a href="tel:+34938211674">
										<Typography ml={3}>+34 93 821 16 74</Typography>
									</a>
								</Box>
								<Box display="flex" alignItems={"center"} my={3}>
									<Mail width={30} />

									<a href="mailto:ceb.berga@gmail.com">
										<Typography ml={3}>ceb.berga@gmail.com</Typography>
									</a>
								</Box>
								<Box display="flex" alignItems={"center"} my={3}>
									<AccessTime width={30} />

									<Typography ml={3}>Atenció de 18h a 20h de dilluns a divendres</Typography>
								</Box>
								<Divider />
								<Box py={2}>
									<iframe
										src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11841.857949611893!2d1.8438573!3d42.097522!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a509609ca5115d%3A0xfe989d1343dad16!2zQ2x1YiBFc3F1w60gQmVyZ3VlZMOg!5e0!3m2!1ses!2ses!4v1692193013099!5m2!1ses!2ses"
										width="100%"
										height="320"
										style={{ border: 0 }}
										allowfullscreen=""
										loading="lazy"
										referrerpolicy="no-referrer-when-downgrade"
									></iframe>
								</Box>
							</Grid>
						</Grid>
					</Loading>
				</Container>
			</Box>
		</Page>
	);
}

export default Contacte;

const useStyles = makeStyles((theme) => ({
	wrapImg: {
		transform: "rotate(-5deg)",
		transition: "0.2s",
		zIndex: 0,
		"&:hover": {
			transform: "rotate(-5deg)",
		},
		textAlign: "center",
	},
	img: {
		zIndex: 0,
		width: "90%",
		border: "4px solid " + theme.palette.secondary.main,
	},
}));
