import { CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSpring, animated, useTrail } from "react-spring";

const LoadingAnimation = () => {
	const classes = useStyles();
	const [showText, setShowText] = useState(false);
	const config = { mass: 5, tension: 2000, friction: 300 };
	const [toggle, set] = useState(true);

	const { x, opacity, height } = useSpring({
		config,
		opacity: toggle ? 1 : 0,
		x: toggle ? 0 : 150,
		height: toggle ? 80 : 0,
		from: { opacity: 0, x: 20, height: 0 },
		onRest: () => set(!toggle),
	});

	return (
		<Box className={classes.main}>
			<div className={classes.trailsMain}>
				<animated.div
					className={classes.trailsText}
					style={{
						transform: x?.interpolate(
							(x) => `translate3d(${x}px,0,0)`
						),
						opacity: opacity,
					}}
				>
					<animated.div style={{ height }}>
						<Typography variant="h2" color="primary">
							CEB
						</Typography>
					</animated.div>
				</animated.div>
			</div>
			<Typography variant="h2" color="primary" fontWeight={200} mr={3}>
				|
			</Typography>
			<Box>
				<CircularProgress color="primary" />
			</Box>
		</Box>
	);
};

export default LoadingAnimation;

const useStyles = makeStyles((theme) => ({
	main: {
		display: "flex",
	},
	trailsMain: {
		position: "relative",
		width: "100%",
		height: "100%",
		overflow: "hidden",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		paddingRight: 30,
	},

	trailsText: {
		position: "relative",
		width: "100%",
		height: 80,
		lineHeight: 80,
		color: "rgb(66, 61, 63)",
		"will-change": "transform, opacity",
		overflow: "hidden",
	},
}));
