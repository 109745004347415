import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useEffect, useState } from "react";

export default function ActivitatTaula({ activitat }) {
	const classes = useStyles({ color: activitat?.seccio?.color });
	const [dates, setDates] = useState([]);

	useEffect(() => {
		activitat && setDates(JSON.parse(activitat?.dates));
	}, [activitat]);

	return (
		<table className={classes.taula}>
			<tr>
				<td>Quan?</td>
				<td>
					<Typography>
						{dates?.length === 1
							? moment(dates?.[0], "DD/MM/YYYY").format("LL")
							: dates?.length === 2
							? moment(dates?.[0], "DD/MM/YYYY").format("LL") + " i " + moment(dates?.[1], "DD/MM/YYYY").format("LL")
							: "" + moment(dates?.[0], "DD/MM/YYYY").format("LL") + " - " + moment(dates?.[dates?.length - 1], "DD/MM/YYYY").format("LL")}
					</Typography>
					{activitat?.quan && (
						<Typography variant="body1">
							<div
								dangerouslySetInnerHTML={{
									__html: activitat?.quan,
								}}
							/>
						</Typography>
					)}
				</td>
			</tr>
			{activitat?.inscripcio_inici && (
				<tr>
					<td>Dates incripcions</td>
					<td>
						<Typography variant="body1">
							{moment(activitat?.inscripcio_inici).format("LL")}
							{activitat?.inscripcio_final && " - " + moment(activitat?.inscripcio_final).format("LL")}
						</Typography>
					</td>
				</tr>
			)}
			{activitat?.on && (
				<tr>
					<td>On?</td>
					<td>
						<Typography variant="body1">
							<div
								dangerouslySetInnerHTML={{
									__html: activitat?.on,
								}}
							/>
						</Typography>
					</td>
				</tr>
			)}
			{activitat?.inscripcio && (
				<tr>
					<td>Com m'apunto?</td>
					<td>
						<Typography variant="body1">
							<div
								dangerouslySetInnerHTML={{
									__html: activitat?.inscripcio,
								}}
							/>
						</Typography>
					</td>
				</tr>
			)}
			{Number(activitat?.intern) === 1 && (
				<tr>
					<td>Quin cost té?</td>
					{activitat?.variacions?.length > 0 ? (
						<td>
							<Grid spacing={2} container>
								{activitat?.variacions?.map((variacio) => (
									<Grid item md={6}>
										<Typography variant="body1" mb={1}>
											{variacio?.nom}
											<br /> <strong>{variacio?.preu_soci && variacio?.preu_soci + " € (soci) "} </strong>
											<br /> <strong>{variacio?.preu_no_soci && variacio?.preu_no_soci + " € (no soci)"}</strong>
										</Typography>
									</Grid>
								))}
							</Grid>
						</td>
					) : (
						<td>
							<Typography variant="body1">
								{activitat?.preu_soci && activitat?.preu_soci + " € (soci) "}
								{activitat?.preu_no_soci && activitat?.preu_no_soci + " € (no soci)"}
							</Typography>
						</td>
					)}
				</tr>
			)}
			{activitat?.portar && (
				<tr>
					<td>Què cal portar?</td>
					<td>
						<Typography variant="body1">
							<div
								dangerouslySetInnerHTML={{
									__html: activitat?.portar,
								}}
							/>
						</Typography>
					</td>
				</tr>
			)}
			<tr>
				<td>Contacta</td>
				<td>
					<Typography variant="body1" mb={1}>
						{activitat?.seccio?.nom_contacte}
					</Typography>
					<Typography variant="body1" mb={1}>
						{activitat?.seccio?.email_contacte}
					</Typography>
					<Typography variant="body1">{activitat?.seccio?.telefon_contacte}</Typography>
				</td>
			</tr>
		</table>
	);
}

const useStyles = makeStyles((theme) => ({
	taula: {
		marginTop: 100,
		width: "100%",
		fontFamily: "Rubik",
		borderCollapse: "collapse",
		"& td": {
			borderTop: (props) => "1px solid" + props.color,
			padding: 10,
		},
		"& tr:first-child": {
			borderTop: (props) => "2px solid" + props.color,
		},
		"& td:first-child": {
			fontWeight: 800,
			textTransform: "uppercase",
		},
		"& tr:last-child": {
			borderBottom: (props) => "2px solid" + props.color,
		},
	},
}));
