import { Box, Fade, Grid, Typography, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import Page from "../../../components/layouts/Page";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { makeStyles } from "@mui/styles";
import Footer from "../../../layouts/public/Footer";
import useScrollY from "../../../components/elements/useScrollY";
import img from "../../../assets/img/portada.jpg";
import img1 from "../../../assets/img/antigues/antiga1.jpg";
import img2 from "../../../assets/img/antigues/antiga2.bmp";
import img3 from "../../../assets/img/antigues/antiga3.bmp";
import img4 from "../../../assets/img/antigues/antiga4.bmp";
import img5 from "../../../assets/img/antigues/antiga5.jpg";
import mask from "../../../assets/img/mask.png";

const alignCenter = { display: "flex", alignItems: "center" };
export default function QuiSom() {
	const matches = useMediaQuery("(min-width:1360px)");
	const classes = useStyles();

	if (matches) {
		return (
			<Page title="Qui som?">
				<Box style={{ backgroundColor: "white", height: "100vh" }}>
					<ScrollIcon />
					<Parallax pages={3.5}>
						<ParallaxLayer offset={0.9} speed={0.5} style={{ ...alignCenter, justifyContent: "flex-end" }}>
							<div className={`${classes.card} ${classes.parallax} ${classes.purple}`}>
								<Grid spacing={3} container>
									<Grid item md={4}>
										<img alt="CEB Qui som" src={img1} style={{ width: "100%", borderRadius: 10 }} />
									</Grid>
									<Grid item md={4}></Grid>
									<Grid item md={4} textAlign="left">
										<Typography variant="h2" mb={3}>
											Qui som
										</Typography>
										<Typography variant="body2">
											El Club Esquí Berguedà (CEB) és una entitat berguedana sense ànim de lucre, amb seu a Berga, que compta amb més de
											1.000 socis i sòcies provinents de tota la comarca.
										</Typography>
										<Typography variant="body2">
											Ens dediquem principalment a l'organització i promoció de totes aquelles activitats relacionades amb la natura i
											l'esport, des de l'esquí fins al voleibol, del cúrling al ciclisme, passant per passejades nocturnes, travesses de
											muntanya o cursos d'escalada.
										</Typography>
										<Typography variant="body2">
											Sota el lema "CEB – El Club Blanc Obert a tots els esports" volem ser el lloc de trobada dels apassionats i
											apassionades de la muntanya, l'esport i les activitats a l'aire lliure.
										</Typography>
									</Grid>
								</Grid>
							</div>
						</ParallaxLayer>

						<ParallaxLayer offset={1.1} speed={0.5} style={{ ...alignCenter, justifyContent: "flex-end" }}>
							<div className={`${classes.card} ${classes.parallax} ${classes.purple}`}>
								<Grid spacing={3} container>
									<Grid item md={4} textAlign="left">
										<Typography variant="h2" mb={3}>
											HISTÒRIA
										</Typography>
										<Typography variant="body2">
											La fundació del Club Esquí Berguedà va molt lligada a la pràctica de l'excursionisme i de l'esquí a les muntanyes de
											la nostra comarca, per part d'alguns membres del Centre Excursionista de Catalunya a principis del segle XX.
										</Typography>
										<Typography variant="body2">
											La primera referència a aquest Club la trobem en una excursió a la Serra del Cadí durant la Pasqua de 1928.
											L'entitat va néixer en aquell temps com a Centre Excursionista de Berga, nom que conservaria fins que va arribar la
											Guerra Civil de 1936-39. Un cop represes les activitats després del conflicte i per raons polítiques, el nom es va
											haver de canviar, passant a ser el Club Esquí Berguedà (amb les mateixes sigles que el nom antic).
										</Typography>
									</Grid>
									<Grid item md={4}></Grid>
									<Grid item md={4}>
										<img alt="CEB Qui som" src={img2} style={{ width: "100%", borderRadius: 10 }} />
									</Grid>
								</Grid>
							</div>
						</ParallaxLayer>

						<ParallaxLayer offset={1.6} speed={0.5} style={{ ...alignCenter, justifyContent: "flex-end" }}>
							<div className={`${classes.card} ${classes.parallax} ${classes.purple}`}>
								<Grid spacing={3} container>
									<Grid item md={4}>
										<img alt="CEB Qui som" src={img3} style={{ width: "100%", borderRadius: 10 }} />
									</Grid>
									<Grid item md={4}></Grid>
									<Grid item md={4} textAlign="left">
										<Typography variant="body2">
											En aquella època, la jornada d'esquí començava a les 5 de la matinada amb una missa i el posterior desplaçament fins
											als Rasos de Peguera. El recorregut es va haver de fer durant molts anys a peu, unes 3 hores de camí, per poder
											gaudir d'unes pistes meravelloses i verges. Els primers esquiadors del Club es fabricaven ells mateixos els esquís.
										</Typography>
										<Typography variant="body2">
											Des de primers dels anys 40 fins ben entrats els 60, les pistes dels Rasos de Peguera eren el lloc de trobada de la
											joventut del CEB, on cada diumenge d'hivern es reunien gran quantitat d'esquiadors. La construcció d'una carretera
											que comuniques Berga amb els Rasos de Peguera es va allargar molt en el temps. Per això, durant aquells hiverns el
											Club llogava algun camió que portava a la gent fins a Castellar del Riu, des d'on calia continuar a peu. No és fins
											a l'any 1973 que la carretera arriba, i s'asfalta fins a dalt a La Creu.
										</Typography>
									</Grid>
								</Grid>
							</div>
						</ParallaxLayer>

						<ParallaxLayer offset={2} speed={0.5} style={{ ...alignCenter, justifyContent: "flex-end" }}>
							<div className={`${classes.card} ${classes.parallax} ${classes.purple}`}>
								<Grid spacing={3} container>
									<Grid item md={4} textAlign="left">
										<Typography variant="h3" mb={3}>
											Algunes dates de referència
										</Typography>
										<Typography variant="body2">
											L'hivern de 1954 es va celebrar la primera edició de la Jornada d'Esquí amb proves de fons i eslàlom gegant.
										</Typography>
										<Typography variant="body2">
											L'hivern de 1963 es van disputar, als Rasos de Peguera, els campionats de Catalunya d'esquí de fons. El Club va
											aprofitar l'esdeveniment per crear una escola d'esquí.
										</Typography>
										<Typography variant="body2">
											Als anys 70 tenen lloc als Rasos els primers campionats estatals, i el Club arriba a agrupar a finals d'aquella
											dècada més de 1.000 socis.
										</Typography>
										<Typography variant="body2">
											Durant molt temps, el Club Esquí Berguedà va ser el punt de trobada on la gent es reunia per desenvolupar tant les
											seves aficions esportives com culturals, convertint-se així en bressol de gran quantitat d'entitats berguedanes que
											després continuarien el seu propi camí, ja per separat.
										</Typography>
									</Grid>
									<Grid item md={4}></Grid>
									<Grid item md={4}>
										<img alt="CEB Qui som" src={img4} style={{ width: "100%", borderRadius: 10 }} />
									</Grid>
								</Grid>
							</div>
						</ParallaxLayer>

						<ParallaxLayer offset={2.5} speed={0.5} style={{ ...alignCenter, justifyContent: "flex-end" }}>
							<div className={`${classes.card} ${classes.parallax} ${classes.purple}`}>
								<Grid spacing={3} container>
									<Grid item md={4}>
										<img alt="CEB Qui som" src={img5} style={{ width: "100%", borderRadius: 10 }} />
									</Grid>
									<Grid item md={4}></Grid>
									<Grid item md={4} textAlign="left">
										<Typography variant="body2">
											Però el Club no va ser, ni és, tan sols esquí. Aquest sempre ha estat obert a tota mena de seccions i activitats,
											algunes de les quals han arribat a tenir, en certes èpoques, quasi més rellevància que l'esquí. La secció d'Alpí, la
											de Fons, la de Muntanya, la de Fotografia, la de Motociclisme, la de Bicicletes, la de Submarinisme, la
											d'Orientació, la de Teatre, entre d'altres, són algunes de les seccions que formen o han format part d'aquest Club.
										</Typography>
										<Typography variant="body2">
											L'any 2003 el Club Esquí Berguedà va celebrar el seu 75è aniversari, convertint-se en un dels més antics de la
											comarca encara actius.
										</Typography>
										<Typography variant="body2">
											Això ha estat, i continuarà sent possible, gràcies a l'esforç i al suport de tots vosaltres, socis, sòcies,
											col·laboradors i col·laboradores.
										</Typography>
									</Grid>
								</Grid>
							</div>
						</ParallaxLayer>
						<ParallaxLayer offset={2.6} style={{ ...alignCenter, justifyContent: "flex-end" }}>
							<div className={`${classes.card} ${classes.parallax} ${classes.purple}`}>
								<Grid spacing={3} container>
									<Grid item md={4}></Grid>
									<Grid item md={4}>
										<Typography variant="h3" textAlign="center">
											Gràcies
										</Typography>
									</Grid>
									<Grid item md={4}></Grid>
								</Grid>
							</div>
						</ParallaxLayer>

						<ParallaxLayer
							offset={3.2}
							style={{
								backgroundColor: "#1b3b5f",
								position: "relative",
							}}
						/>
						<ParallaxLayer offset={3.2}>
							<Footer />
						</ParallaxLayer>

						<ParallaxLayer sticky={{ start: 0, end: 2 }} offset={0} style={{ ...alignCenter, justifyContent: "flex-start" }}>
							<div className={`${classes.card} ${classes.sticky}`}>
								<Logo width={400} />
							</div>
						</ParallaxLayer>
					</Parallax>
				</Box>
			</Page>
		);
	} else {
		return (
			<Page title="Qui som?">
				<Box style={{ backgroundColor: "white" }} p={2}>
					<Box textAlign={"center"} py={30}>
						<Logo width={200} />
					</Box>
					<Grid spacing={3} container>
						<Grid item md={6}>
							<img alt="CEB Qui som" src={img1} style={{ width: "100%", borderRadius: 10 }} />
						</Grid>
						<Grid item md={6} textAlign="left">
							<Typography variant="h2" mb={3}>
								Qui som
							</Typography>
							<Typography variant="body2">
								El Club Esquí Berguedà (CEB) és una entitat berguedana sense ànim de lucre, amb seu a Berga, que compta amb més de 1.000 socis i
								sòcies provinents de tota la comarca.
							</Typography>
							<Typography variant="body2">
								Ens dediquem principalment a l'organització i promoció de totes aquelles activitats relacionades amb la natura i l'esport, des
								de l'esquí fins al voleibol, del cúrling al ciclisme, passant per passejades nocturnes, travesses de muntanya o cursos
								d'escalada.
							</Typography>
							<Typography variant="body2">
								Sota el lema "CEB – El Club Blanc Obert a tots els esports" volem ser el lloc de trobada dels apassionats i apassionades de la
								muntanya, l'esport i les activitats a l'aire lliure.
							</Typography>
						</Grid>
					</Grid>

					<Grid spacing={3} container>
						<Grid item md={6}>
							<img alt="CEB Qui som" src={img2} style={{ width: "100%", borderRadius: 10 }} />
						</Grid>
						<Grid item md={6} textAlign="left">
							<Typography variant="h2" mb={3}>
								HISTÒRIA
							</Typography>
							<Typography variant="body2">
								La fundació del Club Esquí Berguedà va molt lligada a la pràctica de l'excursionisme i de l'esquí a les muntanyes de la nostra
								comarca, per part d'alguns membres del Centre Excursionista de Catalunya a principis del segle XX.
							</Typography>
							<Typography variant="body2">
								La primera referència a aquest Club la trobem en una excursió a la Serra del Cadí durant la Pasqua de 1928. L'entitat va néixer
								en aquell temps com a Centre Excursionista de Berga, nom que conservaria fins que va arribar la Guerra Civil de 1936-39. Un cop
								represes les activitats després del conflicte i per raons polítiques, el nom es va haver de canviar, passant a ser el Club Esquí
								Berguedà (amb les mateixes sigles que el nom antic).
							</Typography>
						</Grid>
					</Grid>

					<Grid spacing={3} container>
						<Grid item md={6}>
							<img alt="CEB Qui som" src={img3} style={{ width: "100%", borderRadius: 10 }} />
						</Grid>
						<Grid item md={6} textAlign="left">
							<Typography variant="body2">
								En aquella època, la jornada d'esquí començava a les 5 de la matinada amb una missa i el posterior desplaçament fins als Rasos
								de Peguera. El recorregut es va haver de fer durant molts anys a peu, unes 3 hores de camí, per poder gaudir d'unes pistes
								meravelloses i verges. Els primers esquiadors del Club es fabricaven ells mateixos els esquís.
							</Typography>
							<Typography variant="body2">
								Des de primers dels anys 40 fins ben entrats els 60, les pistes dels Rasos de Peguera eren el lloc de trobada de la joventut del
								CEB, on cada diumenge d'hivern es reunien gran quantitat d'esquiadors. La construcció d'una carretera que comuniques Berga amb
								els Rasos de Peguera es va allargar molt en el temps. Per això, durant aquells hiverns el Club llogava algun camió que portava a
								la gent fins a Castellar del Riu, des d'on calia continuar a peu. No és fins a l'any 1973 que la carretera arriba, i s'asfalta
								fins a dalt a La Creu.
							</Typography>
						</Grid>
					</Grid>

					<Grid spacing={3} container>
						<Grid item md={6}>
							<img alt="CEB Qui som" src={img4} style={{ width: "100%", borderRadius: 10 }} />
						</Grid>
						<Grid item md={6} textAlign="left">
							<Typography variant="h3" mb={3}>
								Algunes dates de referència
							</Typography>
							<Typography variant="body2">
								L'hivern de 1954 es va celebrar la primera edició de la Jornada d'Esquí amb proves de fons i eslàlom gegant.
							</Typography>
							<Typography variant="body2">
								L'hivern de 1963 es van disputar, als Rasos de Peguera, els campionats de Catalunya d'esquí de fons. El Club va aprofitar
								l'esdeveniment per crear una escola d'esquí.
							</Typography>
							<Typography variant="body2">
								Als anys 70 tenen lloc als Rasos els primers campionats estatals, i el Club arriba a agrupar a finals d'aquella dècada més de
								1.000 socis.
							</Typography>
							<Typography variant="body2">
								Durant molt temps, el Club Esquí Berguedà va ser el punt de trobada on la gent es reunia per desenvolupar tant les seves
								aficions esportives com culturals, convertint-se així en bressol de gran quantitat d'entitats berguedanes que després
								continuarien el seu propi camí, ja per separat.
							</Typography>
						</Grid>
					</Grid>

					<Grid spacing={3} container>
						<Grid item md={6}>
							<img alt="CEB Qui som" src={img5} style={{ width: "100%", borderRadius: 10 }} />
						</Grid>
						<Grid item md={6} textAlign="left">
							<Typography variant="body2">
								Però el Club no va ser, ni és, tan sols esquí. Aquest sempre ha estat obert a tota mena de seccions i activitats, algunes de les
								quals han arribat a tenir, en certes èpoques, quasi més rellevància que l'esquí. La secció d'Alpí, la de Fons, la de Muntanya,
								la de Fotografia, la de Motociclisme, la de Bicicletes, la de Submarinisme, la d'Orientació, la de Teatre, entre d'altres, són
								algunes de les seccions que formen o han format part d'aquest Club.
							</Typography>
							<Typography variant="body2">
								L'any 2003 el Club Esquí Berguedà va celebrar el seu 75è aniversari, convertint-se en un dels més antics de la comarca encara
								actius.
							</Typography>
							<Typography variant="body2">
								Això ha estat, i continuarà sent possible, gràcies a l'esforç i al suport de tots vosaltres, socis, sòcies, col·laboradors i
								col·laboradores.
							</Typography>
						</Grid>
					</Grid>
					<Grid spacing={3} container>
						<Grid item md={12} textAlign="center" mt={5} pb={5}>
							<Typography variant="h3" textAlign="center">
								Gràcies
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Footer />
			</Page>
		);
	}
}

const ScrollIcon = () => {
	const scrollY = useScrollY();
	return (
		<Fade in={scrollY < 100}>
			<Box position={"absolute"} bottom={10} display="flex" width="100%" justifyContent={"center"}>
				<svg
					width="40px"
					height="100%"
					viewBox="0 0 247 390"
					version="1.1"
					style={{
						"fill-rule": "evenodd",
						"clip-rule": "evenodd",
						"stroke-linecap": "round",
						"stroke-linejoin": "round",
						"stroke-miterlimit": 1.5,
					}}
				>
					<path
						id="wheel"
						d="M123.359,79.775l0,72.843"
						style={{
							fill: "none",
							stroke: "#000",
							"stroke-width": 20,
						}}
					/>
					<path
						id="mouse"
						d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
						style={{
							fill: "none",
							stroke: "#000",
							"stroke-width": 20,
						}}
					/>
				</svg>
			</Box>
		</Fade>
	);
};

const useStyles = makeStyles((theme) => ({
	card: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: 1.25,
		height: 10,
		width: "100%",
		textAlign: "center",
		borderRadius: 10,
	},
	parallax: {
		padding: "10%",
	},

	sticky: {
		justifyContent: "center",
		display: "flex",
	},

	"@global": {
		".footer": {
			display: "none",
		},
	},
}));
