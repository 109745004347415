import { Container, Grid, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import img from "../../../assets/img/inici-img.jpg";
import CustomButton from "../../../components/elements/CustomButton";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

function Presentacio() {
	const classes = useStyles();

	return (
		<Box className={classes.portada} py={20}>
			<Container maxWidth="lg">
				<AnimationOnScroll animateIn="animate__fadeIn">
					<Grid container spacing={6}>
						<Grid item md={6} xs={12}>
							<Typography variant="h1" mb={3} color="white">
								Club
								<br /> Esquí <br /> Berguedà
							</Typography>
							<Typography variant="body1" color="white" mb={3}>
								El Club Esquí Berguedà (CEB) és una entitat berguedana, fundada el 1928, sense ànim de lucre, amb seu a Berga, que compta amb
								més de 1.000 socis i sòcies provinents de tota la comarca.
							</Typography>
							<Typography variant="body1" color="white" mb={3}>
								Ens dediquem principalment a l'organització i promoció de totes aquelles activitats relacionades amb la natura i l'esport, des
								de l'esquí fins al voleibol, del cúrling al ciclisme, passant per passejades nocturnes, travesses de muntanya o cursos
								d'escalada.
							</Typography>
							<Typography variant="body1" color="white" mb={3}>
								Sota el lema "CEB – El Club Blanc Obert a tots els esports" volem ser el lloc de trobada dels apassionats i apassionades de la
								muntanya, l'esport i les activitats a l'aire lliure.
							</Typography>
							<Stack spacing={2} direction={"row"}>
								<CustomButton title={"FES-TE SOCI"} onClick={() => window.open("https://cebergueda.playoffinformatica.com/preinscripcion/")} />
								<CustomButton onClick={() => window.open("https://plataforma.clubesquibergueda.cat/renovacioLlicencies")} title={"FEDERA'T"} />
							</Stack>
						</Grid>
						<Grid item md={6} xs={12}>
							<img src={img} alt="Club Esquí Berguedà" style={{ width: "100%" }} />
						</Grid>
					</Grid>
				</AnimationOnScroll>
			</Container>
		</Box>
	);
}

export default Presentacio;

const useStyles = makeStyles((theme) => ({
	portada: {
		backgroundColor: theme.palette.background.main,
		borderTop: "1px solid white",
	},
}));
