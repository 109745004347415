import React, { forwardRef, useEffect, useState } from "react";
import {
	CircularProgress,
	Container,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { isDark } from "../Utils";
import { useNavigate } from "react-router";

export function Header({
	title,
	color,
	escut,
	activitat,
	img,
	loading,
	overlay,
}) {
	const matches = useMediaQuery("(min-width:960px)");
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Box
			className={classes.header}
			style={
				img
					? {
							backgroundImage: `url(${img})`,
					  }
					: {
							backgroundColor: !loading && color,
					  }
			}
		>
			<Container maxWidth={activitat ? "md" : "lg"}>
				<Box display={matches && "flex"} textAlign="center">
					{escut && <Logo style={{ width: matches ? 200 : 100 }} />}
					<Box
						className={classes.seccio}
						ml={activitat ? 0 : 3}
						justifyContent="center"
						onClick={() =>
							navigate("/seccions/" + activitat.seccio.slug)
						}
						style={{ zIndex: 100 }}
					>
						{!img && (
							<>
								<Typography
									fontWeight={700}
									variant={activitat ? "h4" : "h1"}
									mr={activitat ? 1 : 2}
									color={isDark(color) ? "white" : "black"}
									className={classes.text}
								>
									CEB
								</Typography>
								<Typography
									variant={activitat ? "h4" : "h1"}
									fontWeight={300}
									textTransform="uppercase"
									color={isDark(color) ? "white" : "black"}
									className={classes.text}
								>
									{" "}
									|{" "}
								</Typography>
							</>
						)}
						{loading ? (
							<Box ml={3}>
								<CircularProgress
									color={isDark(color) ? "info" : "primary"}
								/>
							</Box>
						) : (
							<Typography
								variant={activitat ? "h4" : "h1"}
								fontWeight={300}
								ml={activitat ? 1 : 2}
								textTransform="uppercase"
								color={isDark(color) ? "white" : "black"}
								className={classes.text}
							>
								{activitat ? activitat?.seccio?.nom : title}
							</Typography>
						)}
					</Box>
				</Box>
				{activitat && (
					<Box ml={2}>
						<Typography
							color={isDark(color) ? "white" : "black"}
							fontSize={matches ? 70 : 50}
							variant="body2"
							textAlign={matches ? "auto" : "center"}
						>
							{activitat?.nom}
						</Typography>
					</Box>
				)}
			</Container>
			{overlay && <Box className={classes.overlay} />}
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	header: {
		paddingTop: 150,
		paddingBottom: 100,
		transition: "0.2s",
		backgroundSize: "cover",
		position: "relative",
		zIndex: 0,
	},
	seccio: {
		padding: 20,
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		transition: "0.2s",
	},
	overlay: {
		position: "absolute",
		backgroundColor: "#00000070",
		width: "100%",
		height: "100%",
		top: 0,
		bottom: 0,
		justifyContent: "center",
		display: "flex",
		flex: 1,
		alignItems: "center",
	},
}));
